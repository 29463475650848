import React, { useEffect, useRef, useState } from "react";
import StoryService from "../../services/storyService";
import { BsArrowClockwise } from "react-icons/bs";
import ImageWithLoader from "./ImageWithLoader";
import { translations } from "../../constants";
import { useSelector } from "react-redux";
const StoryTextHighlighter = ({
  storyParagraph,
  storyId,
  showRegenerate,
  activeSegmentId,
  isPlaying,
  currLang,
}) => {
  const [status, setStatus] = useState("");
  const [image, setImage] = useState({});
  const user = useSelector((state) => state.user);
  const { subscriptionPlan } = user;
  const { planDetails } = subscriptionPlan;
  const intervalId = useRef(null);

  const scrollRef = useRef(null);

  const handleScroll = () => {
    if (scrollRef.current && isPlaying)
      scrollRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
  };

  useEffect(() => {
    handleScroll();
    // eslint-disable-next-line 
  }, [activeSegmentId, isPlaying]);

  useEffect(() => {
    return () => {
      if (intervalId.current !== null) clearInterval(intervalId.current);
    };
  }, []);

  const onRegenerate = async () => {
    try {
      setStatus("PENDING");

      await new StoryService().reGenerateImage({
        imageId: storyParagraph?.image?.id,
        imageUrl: image?.url || storyParagraph?.image?.url,
        storyId,
      });
      intervalId.current = setInterval(() => {
        (async () => {
          try {
            const response = await new StoryService().getStoryStatus({
              _id: storyId,
            });
            const imageData = response?.story?.images?.find(
              (i) => i.id === storyParagraph?.image?.id
            );
            if (!imageData) return;
            if (imageData?.status === "READY") {
              setStatus(imageData?.status);
              setImage(imageData);
              clearInterval(intervalId.current);
            }
            if (imageData?.status === "ERRORED") {
              setStatus(imageData?.status);
              clearInterval(intervalId.current);
            }
          } catch (error) {
            clearInterval(intervalId.current);
          }
        })();
      }, 5000);
    } catch (error) {
      setStatus("ERRORED");
      console.log("error: ", error);
    }
  };

  const renderRegenerateButton = () => {
    if (currLang in translations) {
      return (
        <span translate="no" className="md:inline-block block">
          {translations[currLang]}
        </span>
      );
    }
    return (
      <span translate="no" className="md:inline-block block">
        {translations.en}
      </span>
    );
  };

  return (
    <div className="flex md:gap-13.5 gap-7.5 flex-col-reverse">
      <p className="text-white md:text-ft26-50 text-ft20-37 font-TimesRegular">
        {storyParagraph?.storyTexts.map(({ text, id }, index) => (
          <span
            key={index}
            ref={isPlaying && id === activeSegmentId ? scrollRef : null}
            className={
              isPlaying && id === activeSegmentId
                ? "text-red-10 mr-2 bg-white"
                : "text-white mr-2"
            }
          >
            {text}
          </span>
        ))}
      </p>

      {storyParagraph?.image?.url && (
        <div
          translate="no"
          className="w-full h-92 md:h-157.5 relative story-image"
        >
          {showRegenerate && planDetails?.planType === "Paid" && (
            <button
              disabled={status === "PENDING"}
              onClick={onRegenerate}
              className={`absolute h-8 px-3 rounded-3xl hidden md:flex bg-red-10 hover:bg-red-30  items-center justify-center gap-3 text-white font-PoppinsMedium text-ft11-18 right-5 top-5 duration-100 ${
                status !== "PENDING" && "story-image-btn"
              }`}
            >
              <BsArrowClockwise
                className={`${
                  status !== "PENDING" && "hidden"
                } w-5 h-5 animate-spin`}
              />
              {renderRegenerateButton()}
            </button>
          )}

          {showRegenerate && planDetails?.planType === "Paid" && (
            <button
              disabled={status === "PENDING"}
              onClick={onRegenerate}
              className={`absolute h-9 rounded-5.5 w-9 md:hidden bg-whiteOpacity-60  flex items-center justify-center gap-3 text-black-0 font-PoppinsMedium text-ft11-18 right-5 bottom-5`}
            >
              <BsArrowClockwise
                className={`w-6 h-6  ${status === "PENDING" && "animate-spin"}`}
              />
            </button>
          )}

          <ImageWithLoader
            className="object-cover w-full h-full"
            src={image?.url || storyParagraph?.image?.url}
            alt=""
          />
        </div>
      )}
    </div>
  );
};

export default StoryTextHighlighter;
