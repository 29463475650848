import React from "react";

const Failed = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30.3"
    height="30.35"
    viewBox="0 0 30.3 30.35"
  >
    <g id="Failed" transform="translate(-818 -179)">
      <path
        id="Path_112"
        data-name="Path 112"
        d="M104.958,15.071a15.232,15.232,0,0,0-8.066-8.079A15,15,0,0,0,91,5.8a15.164,15.164,0,1,0,13.959,9.274ZM91,33.638a12.664,12.664,0,1,1,12.644-12.664A12.669,12.669,0,0,1,91,33.638Z"
        transform="translate(742.151 173.203)"
        fill="#ff2929"
      />
      <g id="Group_183" data-name="Group 183" transform="translate(0.5 -0.5)">
        <line
          id="Line_39"
          data-name="Line 39"
          y2="12"
          transform="translate(836.985 190.5) rotate(45)"
          fill="none"
          stroke="#ff2929"
          stroke-linecap="round"
          stroke-width="2.3"
        />
        <line
          id="Line_40"
          data-name="Line 40"
          y1="12"
          transform="translate(836.985 198.985) rotate(135)"
          fill="none"
          stroke="#ff2929"
          stroke-linecap="round"
          stroke-width="2.3"
        />
      </g>
    </g>
  </svg>
);

export default Failed;
