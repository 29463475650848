import React, { useState, useEffect } from "react";
import { BiLoaderAlt } from "react-icons/bi";
import { useForm } from "react-hook-form";
import { errorMessagesConstants } from "../../constants";
import { useDispatch, useSelector } from "react-redux";
import { Auth0Service, UserService } from "../../services";
import { history } from "../../managers/history";
import Utils from "../../utility";

const {
  PASSWORD_REQUIRED,
  PASSWORD_MUST_8_CHARACTERS,
  PASSWORD_ALPHA_NUMBER_ERROR,
  CONFIRM_PASSWORD_REQUIRED,
  PASSWORD_MUST_SAME,
} = errorMessagesConstants;

function ChangePasswordComponent() {
  const [isLoading, setIsloading] = useState(false);
  const [showError, setShowError] = useState("");
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm();

  const user = useSelector((state) => state.user);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!user.isLoggedIn) history.push("/");
  }, [user.isLoggedIn]);

  const onSubmit = async (data) => {
    setIsloading(true);
    setShowError("");
    try {
      const signInRes = await new Auth0Service().signIn({
        email: user.email,
        password: data.password,
      });
      await new UserService().changePassword(
        { password: data.newPassword },
        signInRes.idToken
      );

      dispatch({ type: "REMOVE_USER" });
      Utils.succesToast("Password changed successfully", {
        className: "font-PoppinsMedium",
        style: {
          borderRadius: "16px",
          height: "68px",
          padding: "23px 22px 22px 18px",
        },
      });
      history.push("/login");
    } catch (error) {
      const loginError =
        error?.code === "invalid_grant"
          ? "Current password is wrong"
          : error?.description;
      setShowError(
        error?.message ||
          loginError ||
          "An error occurred while changing password!"
      );
      setTimeout(() => {
        setShowError("");
      }, 3000);
    } finally {
      setIsloading(false);
    }
  };

  return (
    <>
      <div className="flex flex-col  bg-black-0 pt-15 pb-60 h-screen mx-auto">
        <span className="font-TimesBold text-ft32-50 text-white mx-auto">
          Change Password
        </span>

        <div className="md:w-572px h-92.5  w-90per  mx-auto rounded-xl md:px-13.5 px-3 ">
          <div className="flex flex-col w-auto mx-auto ml-3.5 ">
            <div className="md:w-440px flex justify-between  h-12.5 border border-gray-50 rounded-10 mt-9.5">
              <input
                type="password"
                placeholder="Current Password"
                className=" text-ft4 bg-transparent remove-prefill-autofill change-prefill-text-color rounded-10 py-3 md:px-5 px-3 placeholder-gray-60 text-white font-PoppinsMedium focus:outline-none w-full"
                {...register("password", {
                  minLength: {
                    value: 8,
                    message: PASSWORD_MUST_8_CHARACTERS,
                  },
                  required: PASSWORD_REQUIRED,
                })}
              />
            </div>
            {!!errors.password && (
              <div className="font-PoppinsRegular text-red-20 pl-2 w-1/2 whitespace-nowrap  md:text-ft15-21 text-ft11-18 mt-1">
                {errors.password.message}
              </div>
            )}
            {!!showError && (
              <div className="font-PoppinsRegular text-red-20 flex  text-center md:text-ft15-21 text-ft11-18 mt-1">
                {showError}
              </div>
            )}
            <div className="md:w-440px flex justify-between  h-12.5 border border-gray-50 rounded-10 mt-27px">
              <input
                type="password"
                placeholder="New Password"
                className=" text-ft4 bg-transparent rounded-10 py-3 md:px-5 px-3 placeholder-gray-60 text-white font-PoppinsMedium remove-prefill-autofill change-prefill-text-color focus:outline-none w-full"
                {...register("newPassword", {
                  pattern: {
                    value:
                      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                    message: PASSWORD_ALPHA_NUMBER_ERROR,
                  },
                  minLength: {
                    value: 8,
                    message: PASSWORD_MUST_8_CHARACTERS,
                  },
                  required: PASSWORD_REQUIRED,
                })}
              />
            </div>
            {!!errors.newPassword && (
              <div className="font-PoppinsRegular text-red-20 pl-2 w-1/2 whitespace-nowrap  md:text-ft15-21 text-ft11-18 mt-1">
                {errors.newPassword.message}
              </div>
            )}
            <div className="md:w-440px flex justify-between  h-12.5 border border-gray-50 rounded-10 mt-27px">
              <input
                type="password"
                placeholder="Confirm Password"
                className=" text-ft4 bg-transparent remove-prefill-autofill change-prefill-text-color rounded-10 py-3 md:px-5 px-3 placeholder-gray-60 text-white font-PoppinsMedium focus:outline-none w-full"
                {...register("confirmNewPassword", {
                  required: CONFIRM_PASSWORD_REQUIRED,
                  validate: {
                    passwordEqual: (value) =>
                      value === getValues().newPassword || PASSWORD_MUST_SAME,
                  },
                })}
              />
            </div>
            {!!errors.confirmNewPassword && (
              <div className="font-PoppinsRegular text-red-20 pl-2 w-1/2 whitespace-nowrap  md:text-ft15-21 text-ft11-18 mt-1">
                {errors.confirmNewPassword.message}
              </div>
            )}
          </div>
          <div className=" w-auto mx-auto ml-3.5 ">
            <button
              onClick={handleSubmit(onSubmit)}
              className="h-13 md:w-440px mx-auto w-full rounded-10 mt-10 font-PoppinsMedium bg-red-10 hover:bg-red-30 text-white mt-7.75S flex justify-center md:mr-5  items-center"
            >
              {isLoading ? (
                <BiLoaderAlt className="w-8 h-8 animate-spin" />
              ) : (
                "Update Password"
              )}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default ChangePasswordComponent;
