import { httpService } from "../utility/httpService";
import { httpConstants } from "../constants";
import Utils from "../utility";

const {
  METHOD_TYPE: { POST, GET },
  API_END_POINT: {
    GET_PLANS,
    CREATE_SUBSCRITPION,
    SUBSCRIPTION_PLAN,
    GET_SUBSCRIBED_PLAN,
    CANCEL_SUBSCRITPION,
    CREATE_PAYMENT,
  },
} = httpConstants;

export default class SubscriptionService {
  baseUrl = process.env.REACT_APP_STORY_SERVICE;
  localUrl = "http://localhost:3006";

  getPlans() {
    return new Promise((resolve, reject) => {
      httpService(GET, this.baseUrl + GET_PLANS)
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  }
  getSubscribedPlans() {
    return new Promise((resolve, reject) => {
      httpService(GET, this.baseUrl + GET_SUBSCRIBED_PLAN, null, Utils.getHeader())
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  }

  createSubscription(request) {
    return new Promise((resolve, reject) => {
      httpService(POST, this.baseUrl + CREATE_SUBSCRITPION, request, Utils.getHeader())
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  }

  subscribePlan(request) {
    return new Promise((resolve, reject) => {
      httpService(POST, this.baseUrl + SUBSCRIPTION_PLAN, request, Utils.getHeader())
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  }

  getPlanById(request) {
    const params = GET_PLANS + "?" + new URLSearchParams(request).toString();
    return new Promise((resolve, reject) => {
      httpService(GET, this.baseUrl + params)
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  }

  cancelSubscription(request) {
    return new Promise((resolve, reject) => {
      httpService(POST, this.baseUrl + CANCEL_SUBSCRITPION, request, Utils.getHeader())
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  }

  createPayment(request) {
    return new Promise((resolve, reject) => {
      httpService(POST, this.baseUrl + CREATE_PAYMENT, request, Utils.getHeader())
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  }
}
