import React from "react";
import countriesList from "../../../constants/countriesList";
import ClickOutside from "../clickOutside";
import Flags from "country-flag-icons/react/3x2";

const LanguageDropdown = ({ onClickOutside, onLangChange, value }) => {
  return (
    <ClickOutside
      onClickOutside={onClickOutside}
      // max-h-75
      className="absolute top-14 bg-black-10 border right-0 border-darkGray-10 w-30 z-10 text-ft17-26 mx-auto py-3.5 pl-3.5 flex gap-2.5 flex-col overflow-auto lang-dropdown-scrollbar"
    >
      {countriesList.map(
        (language, idx) =>
          value.code.toLowerCase() !== language.code.toLowerCase() && (
            <button
              key={idx}
              translate="no"
              onClick={() => onLangChange(language)}
              //   onClick={() => onClickOutside(false)}
              className="flex gap-2"
            >
              <Flag icon={Flags[language.countryCode]} />
              {language.code.toUpperCase()}
            </button>
          )
      )}
    </ClickOutside>
  );
};

export default LanguageDropdown;

const Flag = ({ icon: Icon }) => (!!Icon ? <Icon className="w-8 h-8" /> : null);
