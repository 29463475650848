import React from "react";
import Footer from "../../common/components/footer";
import Header from "../../common/components/header";
import PrivacyPolicy from "./privacyPolicy";

function Policy() {
  return (
    <div className="bg-black-0">
      <Header />
      <PrivacyPolicy />
      <Footer />
    </div>
  );
}

export default Policy;
