import auth0 from "auth0-js";
import { keyTypeConstants } from "../constants";
import { sessionManager } from "../managers/sessionManager";

const { setDataInCookies } = sessionManager;
const { AUTH0_ID_TOKEN } = keyTypeConstants;

export default class Auth0Service {
  constructor() {
    this.auth0 = new auth0.WebAuth({
      domain: process.env.REACT_APP_AUTH0_DOMAIN,
      clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
      redirectUri: window.location.origin,
      scope: process.env.REACT_APP_AUTH0_SCOPE,
      responseType: process.env.REACT_APP_AUTH0_RESPONSE_TYPE,
      grantType: process.env.REACT_APP_AUTH0_GRANT_TYPE,
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    });
  }

  signIn({ email, password }) {
    return new Promise((resolve, reject) => {
      this.auth0.client.login(
        {
          realm: "Username-Password-Authentication",
          username: email,
          password: password,
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
          scope: process.env.REACT_APP_AUTH0_SCOPE,
        },
        async (error, authResult) => {
          if (error) return reject(error);
          setDataInCookies(AUTH0_ID_TOKEN, authResult.idToken, 5);
          this.auth0.client.userInfo(authResult.accessToken, (userInfoError, user) => {
            if (userInfoError) return reject(userInfoError);
            resolve({ ...authResult, userInfoRes: user, userMetaData: user });
          });
        }
      );
    });
  }

  signUp(requestData) {
    return new Promise((resolve, reject) => {
      this.auth0.signup(
        {
          connection: process.env.REACT_APP_AUTH0_REALM,
          email: requestData.email,
          password: requestData.password,
          given_name: requestData.firstName,
          family_name: requestData.lastName,
          name: requestData.firstName + " " + requestData.lastName,
          nickname: requestData.firstName,
          username: requestData.firstName,
        },
        async (signupError, authResult) => {
          if (signupError) return reject(signupError);
          return resolve(authResult);
        }
      );
    });
  }

  forgotPassword = ({ email }) => {
    return new Promise((resolve, reject) => {
      this.auth0.changePassword(
        {
          connection: process.env.REACT_APP_AUTH0_REALM,
          email,
        },
        (changePasswordError, res) => {
          if (changePasswordError) return reject(changePasswordError);
          resolve(res);
        }
      );
    });
  };

  logOut() {
    return new Promise((resolve, reject) => {
      this.auth0.logout(
        {
          returnTo: window.location.origin,
          clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
        },
        async (logoutError, authResult) => {
          if (logoutError) return reject(logoutError);
          return resolve(authResult);
        }
      );
    });
  }

  oAuthSignIn() {
    this.auth0.authorize({
      connection: "google-oauth2",
      redirect_uri: window.location.origin,
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      scope: process.env.REACT_APP_AUTH0_SCOPE,
    });
  }

  userInfo() {
    return new Promise((resolve, reject) => {
      this.auth0.parseHash((parseHashError, authResult) => {
        if (parseHashError) return reject(parseHashError);
        this.auth0.client.userInfo(authResult.accessToken, (userInfoError, user) => {
          if (userInfoError) return reject(userInfoError);
          setDataInCookies(AUTH0_ID_TOKEN, authResult.idToken, 5);
          resolve(user);
        });
      });
    });
  }
}
