import React from "react";
import StoryCard from "../../common/components/storyCard";

const StoriesList = ({ title, storiesList, user }) => {
  return (
    <div className="cursor-pointer max-w-310 mx-auto px-4 md:px-6 lg:px-4 my-11">
      <div className="flex justify-between items-start xs:items-center flex-col xs:flex-row ">
        <div className="text-ft32-50 md:ml-10 lg:ml-0 text-white font-TimesBold">
          {title}
        </div>
      </div>
      {!!storiesList.length ? (
        <div className="grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 gap-y-14 mt-6 gap-x-1">
          {storiesList?.map(({ storyId, story, userRes }) => (
            <StoryCard
              key={storyId}
              story={{
                ...story,
                isBookMarked: true,
                _id: storyId,
                userRes: userRes,
              }}
              user={user}
              showAuthorInfo
            />
          ))}
        </div>
      ) : (
        <div className="h-60vh grid place-content-center text-darkGray-20 font-PoppinsRegular md:text-ft30-37 text-ft20-37">
          No Bookmarked Stories
        </div>
      )}
    </div>
  );
};

export default StoriesList;
