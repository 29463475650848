import React, { useEffect } from "react";
import { AiOutlineClose } from "react-icons/ai";
import LoadingScreen from "../../common/components/loadingScreen";

const VideoPlayer = ({ videoStory, onClose }) => {
  useEffect(() => {
    const video = document.getElementById("myVideo");
    video.play();
  }, [videoStory.videoUrl]);
  return (
    <section className="bg-transparent bg-opacity-80 mb-15px  w-full h-70per flex justify-center items-center relative">
      {videoStory.status === "LOADING" ? (
        <div className="flex absolute z-10">
          <LoadingScreen />
        </div>
      ) : null}
      <button
        onClick={onClose}
        className="flex absolute z-10 md:top-11px top-4.5 right-4.5"
      >
        <AiOutlineClose className="text-white w-6 h-6" />
      </button>
      <video id="myVideo" controls width="100%" preload>
        {videoStory.status === "CREATED" ? (
          <source src={videoStory.videoUrl} type="video/mp4" />
        ) :  
        <div className="flex absolute z-10">
        <LoadingScreen />
      </div>}
      </video>
    </section>
  );
};

export default VideoPlayer;
