import React from "react";
import Paginate from "react-js-pagination";

const Pagination = ({ page, limit, total, onPageChange, pageRange = 3 }) => {
  return (
    <div className="flex justify-end mx-auto max-w-310 px-5 mb-6">
      <Paginate
        activePage={Number(page)}
        innerClass="flex text-white gap-8 items-center text-gray-10"
        itemClass="font-PoppinsSemiBold text-ft18-27"
        activeClass="text-white"
        itemClassLast="text-ft24-35 text-white"
        itemClassFirst="text-ft24-35 text-white"
        itemClassPrev="text-white"
        itemClassNext="text-white"
        itemsCountPerPage={Number(limit)}
        totalItemsCount={Number(total)}
        pageRangeDisplayed={pageRange}
        onChange={onPageChange}
      />
    </div>
  );
};

export default Pagination;
