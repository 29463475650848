import { exportHomeConstants } from "../../constants";
const { ACTION_TYPES } = exportHomeConstants;

const { UDPATE_STATE } = ACTION_TYPES;

export const initialState = {
  popularStories: [],
  latestStories: [],
  isLoading: false,
  age: "4",
  character: "Tiger",
  type: "Adventure",
  name: "Brian",
  isNameChanged: false,
  mainCharacter: "Boy",
  mainCharacterName: "Luke",
};

export default function reducer(state, action) {
  switch (action.type) {
    case UDPATE_STATE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
