/**
 * Created by Ayush Kulshrestha on 18/09/2019.
 */

import Cookies from "universal-cookie";
import Utils from "../utility";

const cookies = new Cookies();

function setDataInCookies(key, data, expires = 30) {
  const expireDate = Utils.addDays(new Date(), expires);
  cookies.set(key, JSON.stringify(data), { path: "/", expires: expireDate });
}

function setDataInCookiesWithoutString(key, data, days = 30, options = {}) {
  const expires = Utils.addDays(new Date(), days);
  cookies.set(key, data, { path: "/", expires, ...options });
}

function getDataFromCookies(key) {
  return cookies.get(key);
}

function removeDataFromCookies(key) {
  cookies.remove(key, { path: "/" });
}

export const sessionManager = {
  setDataInCookies,
  getDataFromCookies,
  removeDataFromCookies,
  setDataInCookiesWithoutString
};
