import React from "react";
import StoryCard from "./storyCard";

const StoriesList = ({ storiesList, profile,myStoryView,storyCount, showStats }) => {
  return (
    <div className="cursor-pointer max-w-310 mx-auto px-4 md:px-6 lg:px-4 mb-14">
      <div className="flex flex-row justify-between items-center ipad:pr-5">
        <div className="iphoneXR:text-ft32-50 mobile:text-ft28-37 md:ml-10 ipad:ml-11 lg:ml-0 mobile:mt-1.5 md:mt-5 lg:mt-0 iphoneXR:mt-0 text-white font-TimesBold">
          {profile?.firstName}'s Stories
        </div>
        {showStats &&
          <div className="flex font-TimesBold lg:mr-2 md:mr-6.5 xs:mt-4 mobile:pb-4 ipad:mr-5 lg:hidden">
            <div className="border flex flex-cols-2 content-center divide-x divide-gray-80 justify-between border-gray-80 borderRadius rounded-lg  md:w-55 md:h-20 w-40 h-13.5 md:mr-2 resize-none">
              <div className="mt-1 md:w-27.5 w-20 justify-center text-center ">
                <span className="text-red-10 md:text-ft12-18 text-ft9-14 font-PoppinsSemiBold p-1">Stories</span>
                <div className="text-white text-ft9-14 font-PoppinsSemiBold ">{storyCount}</div>
              </div>
              <div className=" mt-1 md:w-27.5 w-20 justify-center text-center">
                <span className="text-red-10 md:text-ft12-18 text-ft9-14 font-PoppinsSemiBold">Views</span>
                <div className="text-white text-ft9-14  font-PoppinsSemiBold ">{myStoryView}</div>
              </div>
            </div>
          </div>
        }
        </div>
      {!!storiesList.length ? (
        <div className="grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 gap-y-14 mt-2 gap-x-1">
          {storiesList?.map((story) => (
            <StoryCard key={story?._id} story={story} />
          ))}
        </div>
      ) : (
        <div className="h-101 grid place-content-center text-darkGray-20 font-PoppinsSemiBold text-ft30-37">
          No Story Created
        </div>
      )}
    </div>
  );
};

export default StoriesList;
