import React, { useEffect } from "react";
import { TermsandconditionData } from "../../constants";

function TermsAndService() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="flex flex-col  bg-black-0 pt-15 md:pb-10 mx-auto md:px-6 lg:px-4 pb-5">
        <h1 className="sm:text-ft42-51  text-ft22-33  items-center text-center font-PoppinsBold mb-6 text-red-20">
          Terms of service
        </h1>
        <h3 className="md:text-ft18-27 text-ft14-21 lg:px-2 md:px-2 px-6 max-w-310 mx-auto font-PoppinsSemiBold  mb-6 text-white">
          Welcome to <span className="text-red-10">makemytale.com</span>, a
          website and app for creating and sharing short stories. By accessing
          or using our website or app, you agree to be bound by the following
          terms and conditions ("Terms of Service").
        </h3>
        <div className="max-w-310 lg:px-2 md:px-2 px-6  mx-auto ">
          {TermsandconditionData.map((item) => (
            <div>
              <div className="text-white md:text-ft30-32 text-ft25-37 mt-4 font-TimesBold">
                {item.heading}
              </div>
              <div className="text-white md:text-ft26-50 text-ft20-37 font-TimesRegular italic">
                {item.content}
                {item.heading === "Contact Us" ? (
                  <a
                    className="text-blue-20 ml-2 hover:underline"
                    href="mailto:support@makemytale.com"
                  >
                    support@makemytale.com.
                  </a>
                ) : (
                  ""
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default TermsAndService;
