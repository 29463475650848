import { httpService } from "../utility/httpService";
import { httpConstants, keyTypeConstants } from "../constants";
import { sessionManager } from "../managers/sessionManager";
import store from "../store";
import Auth0Service from "./auth0Service";
import Utils from "../utility";

const { AUTH0_ID_TOKEN } = keyTypeConstants;
const {
  METHOD_TYPE: { GET, POST },
  API_END_POINT: { GET_BOOKS, GET_BOOKS_ID, GENERATE_BOOK, UPLOAD },
} = httpConstants;

export default class BookService {
  baseUrl = process.env.REACT_APP_STORY_BOOK_SERVICE;
  localUrl = "http://localhost:5000";

  getBooks(data) {
    const query = data
      ? `${GET_BOOKS}?${new URLSearchParams(data).toString()}`
      : GET_BOOKS;
    return new Promise((resolve, reject) => {
      httpService(GET, this.baseUrl + query)
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  }

  getBook(bookId) {
    return new Promise((resolve, reject) => {
      httpService(GET, this.baseUrl + GET_BOOKS_ID + bookId)
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  }

  generateBook(body) {
    return new Promise((resolve, reject) => {
      httpService(POST, this.baseUrl + GENERATE_BOOK, body, Utils.getHeader())
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  }

  async uploadFile(body) {
    try {
      const response = await fetch(this.baseUrl + UPLOAD, {
        method: POST,
        body,
        headers: {
          Authorization: `Bearer ${sessionManager.getDataFromCookies(
            AUTH0_ID_TOKEN
          )}`,
        },
      });
      const res = await response.json();
      if (!res.success) {
        if (Number(res.responseCode) === 401) {
          store.dispatch({ type: "REMOVE_USER" });
          new Auth0Service().logOut();
          throw new Error("Unauthorized");
        }
        throw res;
      }
      return res.responseData;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }
}
