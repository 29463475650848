import React, { useState, useEffect } from "react";
import Footer from "../../common/components/footer";
import Header from "../../common/components/header";
import LoadingScreen from "../../common/components/loadingScreen";
import StoryService from "../../services/storyService";
import { useSelector } from "react-redux";
import StoriesList from "./storyList";
import { history } from "../../managers/history";
import Pagination from "../../common/components/pagination";

function BookmarkStories({ location }) {
  const [stories, setStories] = useState({
    data: [],
    total: 0,
  });
  const [loading, setLoading] = useState(false);

  const user = useSelector((state) => state.user);

  const queryParams = new URLSearchParams(location.search);
  const page = Number(queryParams.get("page")) || 1;
  const limit = 12;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    (async () => {
      if (!user.isLoggedIn) {
        history.push("/");
        return;
      }
      try {
        setLoading(true);
        const { data = [], total = 0 } =
          await new StoryService().getBookmarkStories({
            userId: user.userId,
            limit,
            skip: (page - 1) * limit,
          });
        setStories({ data, total });
      } catch (error) {
        console.log("error: ", error);
      } finally {
        setLoading(false);
      }
    })();
    // eslint-disable-next-line
  }, [page, location.pathname]);

  const onPageChange = (pageNumber) => {
    window.scrollTo(0, 0);
    history.push({
      search: new URLSearchParams({ page: pageNumber }).toString(),
    });
  };

  return (
    <div className="min-h-screen bg-black-10">
      <Header />
      {loading ? (
        <LoadingScreen />
      ) : (
        <StoriesList
          title="My Bookmark Stories"
          storiesList={stories.data}
          user={user}
        />
      )}
      <Pagination
        onPageChange={onPageChange}
        limit={limit}
        total={stories.total}
        page={page}
      />
      <Footer />
    </div>
  );
}

export default BookmarkStories;
