import React from "react";

const DeleteStoryPopup = ({ deleteStory , onClose }) => {
  return (
    <div className="fixed inset-0 top-0 bg-blackOpacity-50 z-50 pt-169px pb-20  ">
      <div className="bg-gray-70   md:w-379px w-90per h-51     pt-12.5  md:mx-auto mx-3 border rounded-5 border-gray-10">
        <div className="text-white md:mx-14 mx-10 font-PoppinsMedium">
          Are you sure to delete the story?
        </div>
        <div className="flex gap-2 md:mt-12.2 mt-8 mx-10 md:mx-0 justify-between">
          <button  onClick={() => onClose(false)} className="  h-12.5 md:w-153px w-24  md:ml-7  rounded-10  font-PoppinsMedium bg-gray-70 border-darkGray-10 border hover:bg-gray-80 text-white  flex justify-center  items-center">
            Cancel
          </button>
          <button onClick={()=> {deleteStory()}} className="  h-12.5 md:w-153px w-24 md:mr-7.5   rounded-10  font-PoppinsMedium bg-red-10 hover:bg-red-30 text-white  flex justify-center  items-center">
            Delete
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteStoryPopup;
