import React, { useState, useEffect } from "react";
import { BsFillPencilFill } from "react-icons/bs";
import Utils from "../../utility";

function ProfileComponent({
  profile,
  onPictureChange,
  updateUserIntro,
  user,
  storyCount,
  myStoryView,
}) {
  const [showIntroField, setShowIntroField] = useState(false);
  const [saveInfo, setSaveInfo] = useState("");

  const toggleIntroField = () => {
    if (!isCurrentUser()) return;
    setShowIntroField((prev) => !prev);
  };

  const isCurrentUser = () =>
    user.isLoggedIn && user.userId === profile?.userId;

  const renderIntroField = () => {
    const renderIntroText = () => {
      const introText =
        profile?.userIntro || "Tap to add a little introduction about you";

      return isCurrentUser() ? introText : "";
    };
    return showIntroField ? (
      <textarea
        type="text"
        value={saveInfo}
        autoFocus
        maxLength={150}
        onChange={(event) => setSaveInfo(event.target.value)}
        className="bg-transparent md:w-96 md:h-28 h-16 overflow-x-auto w-90per overflow-y-auto font-PoppinsRegular border mb-2 border-gray-10 rounded-md placeholder-text-gray-10 text-white pr-3 italic md:text-ft17-26 text-ft15-21"
      ></textarea>
    ) : (
      <div className="md:text-ft17-26 text-ft15-21 md:max-w-lg max-w-62.5 font-PoppinsRegular text-gray-10 pr-3 italic">
        {renderIntroText()}
      </div>
    );
  };

  const saveDetails = () => {
    if (!saveInfo || saveInfo === profile.userIntro) return;
    updateUserIntro(saveInfo);
    setShowIntroField(false);
  };

  useEffect(() => {
    setSaveInfo(profile?.userIntro || "");
  }, [profile.userIntro]);

  const renderProfileImage = () => {
    const profileImage = (
      <img
        className="mx-2 self-center w-auto md:h-29.5 md:w-29.5 h-20 flex-shrink-0 bg-white rounded-full"
        src={profile.profilePicture}
        alt=""
      />
    );

    if (!isCurrentUser()) {
      return profileImage;
    } else {
      return (
        <>
          <input
            id="profile-image"
            className="hidden"
            accept="image/*"
            type="file"
            onChange={onImageSelect}
          />
          <label
            htmlFor="profile-image"
            className="cursor-pointer profile-image-wrapper hover:opacity-70"
          >
            {profileImage}
            <img
              className="camera-icon"
              src="/images/camera.svg"
              alt="Change profile"
            />
          </label>
        </>
      );
    }
  };

  const onImageSelect = (e) => {
    const [file] = e.target.files;
    if (!file.name.match(/\.(jpg|jpeg|png)$/)) {
      Utils.failureToast("Please select a valid image.");
      return;
    }
    onPictureChange({
      ...file,
      preview: URL.createObjectURL(file),
    });
  };

  const renderStats = () => {
    if (!user.isLoggedIn || profile?.userId !== user?.userId) return null;

    return (
      <div className="lg:mr-2 md:mr-7 xs:mt-4 mobile:pb-4 ipad:mr-13 mobile:mr-40 mobile:hidden lg:block">
        <div className="border flex flex-cols-2 content-center divide-x divide-gray-80 justify-between border-gray-80 borderRadius rounded-lg w-62.75 h-22.25 md:mr-2">
          <div className="p-1 md:pt-4.5 w-31.2 justify-center text-center">
            <span className="text-red-10 text-ft15-21 justify-center text-center font-PoppinsSemiBold">
              Stories
            </span>
            <div className="text-white font-PoppinsSemiBold justify-center text-center w-18px h-25px">
              {storyCount}
            </div>
          </div>
          <div className="pl-1 md:pt-4.5 w-31.2 justify-center text-center">
            <span className="text-red-10 text-ft15-21 justify-center text-center font-PoppinsSemiBold">
              Views
            </span>
            <div className="text-white font-PoppinsSemiBold justify-center text-center w-18px h-25px">
              {myStoryView}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="flex flex-row justify-between mt-11 w-90per md:w-full mx-auto max-w-310 md:px-6 lg:px-2 pl-2 md:pt-0 pt-0 md:pb-10 pb-5">
      <div className="flex w-90per">
        {renderProfileImage()}
        <div className="text-white flex md:w-full w-90per flex-col py-2 md:pl-8 pl-6">
          <span className="font-PoppinsSemiBold md:text-ft26-50 text-ft22-33">
            {profile?.name}
          </span>
          <span
            className={`relative flex pt-3 ${
              showIntroField ? "flex-col" : "flex-row items-center"
            }`}
          >
            {renderIntroField()}
            <div
              className={`flex gap-4 ${
                showIntroField && "justify-end md:w-96"
              }`}
            >
              {showIntroField && (
                <button
                  onClick={saveDetails}
                  className="font-PoppinsRegular text-ft14-21 text-white bg-red-20 rounded-md py-1 px-2 flex justify-end"
                >
                  Save
                </button>
              )}
              {isCurrentUser() && (
                <button className="flex pb-2 pt-1 cursor-pointer">
                  <BsFillPencilFill onClick={toggleIntroField} className="" />
                </button>
              )}
            </div>
          </span>
        </div>
      </div>
      {renderStats()}
    </div>
  );
}

export default ProfileComponent;
