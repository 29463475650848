import { httpService } from "../utility/httpService";
import { httpConstants } from "../constants";
import  Utils  from "../utility";

const {
  METHOD_TYPE: { POST, GET, PUT, DELETE, PATCH },
  API_END_POINT: {
    GET_A_STORY,
    CREATE_A_STORY,
    GET_CONFIG,
    REPORT_A_STORY,
    RATE_A_STORY,
    GET_STORIES,
    GET_STORY_STATUS,
    REGENERATE_IMAGE,
    USER_CREATED_STORY,
    STORY_VIEW_COUNT,
    STORY_BY_TITLE,
    USER_RATING,
    DELETE_STORY,
    MULTIPLE_STORIES,
    BOOKMARK_STORIES,
    UPDATE_STORY_PRIVACY
  },
} = httpConstants;

export default class StoryService {
  baseUrl = process.env.REACT_APP_STORY_SERVICE;
  userUrl = process.env.REACT_APP_USER_SERVICE;
  localUrl = "http://localhost:3006";

  getStory(query) {
    const params = query ? `${GET_A_STORY}?${query}` : GET_A_STORY;
    return new Promise((resolve, reject) => {
      httpService(GET, this.baseUrl + params)
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  }

  createStory(requestData) {
    return new Promise((resolve, reject) => {
      httpService(POST, this.baseUrl + CREATE_A_STORY, requestData, Utils.getHeader())
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  }

  getConfig() {
    return new Promise((resolve, reject) => {
      httpService(GET, this.baseUrl + GET_CONFIG)
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  }

  sendReport(storyId) {
    return new Promise((resolve, reject) => {
      httpService(GET, this.baseUrl + REPORT_A_STORY + "?_id=" + storyId) //user id
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  }
  sendStoryReport(storyId, userId, name, message) {
    return new Promise((resolve, reject) => {
      const queryParams = `?_id=${storyId}&userId=${userId}&name=${name}&message=${message}`;
      httpService(GET, this.baseUrl + REPORT_A_STORY + queryParams)
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  }


  rateStory(data) {
    return new Promise((resolve, reject) => {
      httpService(PUT, this.baseUrl + RATE_A_STORY, data)
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  }

  getStories(data) {
    const query = new URLSearchParams(data).toString();
    return new Promise((resolve, reject) => {
      httpService(GET, this.baseUrl + GET_STORIES + "?" + query)
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  }

  getStoryStatus(object) {
    const query = new URLSearchParams(object).toString();
    return new Promise((resolve, reject) => {
      httpService(GET, this.baseUrl + GET_STORY_STATUS + "?" + query)
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  }

  reGenerateImage(requestData) {
    return new Promise((resolve, reject) => {
      httpService(POST, this.baseUrl + REGENERATE_IMAGE, requestData, Utils.getHeader())
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  }

  getCreatedStories(object) {
    const query = new URLSearchParams(object).toString();
    return new Promise((resolve, reject) => {
      httpService(GET, this.baseUrl + USER_CREATED_STORY + "?" + query)
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  }

  getStoryViewCount(object){
    const query = new URLSearchParams(object).toString();
    return new Promise((resolve, reject) => {
      httpService(GET, this.baseUrl + STORY_VIEW_COUNT+ "?" +query)
      .then((res)=>{
        if(!res.success || res.responseCode !== 200) return reject(res);
        return resolve(res?.responseData);
      })
      .catch(reject);
    })
  }

  getStoryByTitle(object) {
    const query = new URLSearchParams(object).toString();
    return new Promise((resolve, reject) => {
      httpService(GET, this.baseUrl + STORY_BY_TITLE + "?" + query)
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  }

  getUserRating(object) {
    const query = new URLSearchParams(object).toString();
    return new Promise((resolve, reject) => {
      httpService(GET, this.baseUrl + USER_RATING + "?" + query)
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  }
  deleteStory(requestData) {
    return new Promise((resolve, reject) => {
      httpService(DELETE, this.baseUrl + DELETE_STORY, requestData, Utils.getHeader())
        .then((res) => {
          if (!res.success || res.responseCode !== 200) {
            reject(res);
          } else {
            resolve(res?.responseData);
          }
        })
        .catch(reject);
    });
  }
  
  getMultipleStories(requestData) {
    return new Promise((resolve, reject) => {
      httpService(POST, this.baseUrl + MULTIPLE_STORIES, requestData,Utils.getHeader())
        .then((res) => {
         if (!res.success || res.responseCode !== 200) {
          return reject(res);
         }else{
           return resolve(res?.responseData);
         }
        })
        .catch(reject);
    });
  }

  getBookmarkStories(requestData) {
    return new Promise((resolve, reject) => {
      httpService(GET, this.userUrl + BOOKMARK_STORIES, requestData,Utils.getHeader())
        .then((res) => {
         if (!res.success || res.responseCode !== 200){
           return reject(res);
        } else{
          return resolve(res?.responseData);
        }
        })
        .catch(reject);
    });
  }

  getBookmarkStoriesById(requestData) {
    return new Promise((resolve, reject) => {
      httpService(GET, this.userUrl + `/bookmark/${requestData.userId}/${requestData.storyId}`, requestData,Utils.getHeader())
        .then((res) => {
         if (!res.success || res.responseCode !== 200){
           return reject(res);
         }else{
           return resolve(res?.responseData);
         }
        })
        .catch(reject);
    });
  }

  bookmarkStories(requestData) {
    return new Promise((resolve, reject) => {
      httpService(POST, this.userUrl + BOOKMARK_STORIES, requestData,Utils.getHeader())
        .then((res) => {
         if (!res.success || res.responseCode !== 200){
           return reject(res);
         }else{
           return resolve(res?.responseData);
         }
        })
        .catch(reject);
    });
  }

  updateStoryPrivacy(requestData) {
    return new Promise((resolve, reject) => {
      httpService(PATCH, this.baseUrl + UPDATE_STORY_PRIVACY, requestData,Utils.getHeader())
          .then((res) => {
            if (!res.success || res.responseCode !== 200){
              return reject(res);
            }else{
              return resolve(res?.responseData);
            } 
          })
          .catch(reject);
    });
  }
}
