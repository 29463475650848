import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { profanity } from "@2toad/profanity";
import { BiLoaderAlt } from "react-icons/bi";
import StoriesList from "./storiesList";
import StoryService from "../../../services/storyService";
import profaneWords from "../../../constants/profane-words";
import { errorMessagesConstants, maxLengthconstants } from "../../../constants";
import { history } from "../../../managers/history";
import CropImagePopup from "./cropper";
import Utils from "../../../utility";
import { sessionManager } from "../../../managers/sessionManager";
import ImageWithLoader from "./ImageWithLoader";
profanity.addWords(profaneWords);

const CreateBook = ({ state, dispatch, updateState, onPublish, user }) => {
  const [storiesList, setStoriesList] = useState([]);
  const [errors, setErrors] = useState({ author: "", book: "" });
  const [showCropper, setShowCropper] = useState(false);
  const [authorImage, setAuthorImage] = useState("");
  const [authorImagePreview, setAuthorImagePreview] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const googleTrans = sessionManager.getDataFromCookies("googtrans");
  let currentLanguage = googleTrans ? googleTrans.split("/")[2] : "en";

  const { subscriptionPlan } = user;
  const { planDetails } = subscriptionPlan;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    (async () => {
      if (!user.isLoggedIn) {
        history.push("/login");
        return;
      }
      if (subscriptionPlan.isLoading) return;
      if (
        !subscriptionPlan.hasPlan ||
        planDetails?.planType?.toLowerCase() === "free"
      ) {
        history.push("/subscription-plan");
        return;
      }
      if (planDetails?.planType?.toLowerCase() === "paid") {
        setIsLoading(true);
        try {
          const response = await new StoryService().getCreatedStories({
            userId: user.userId,
            limit: 100,
          });
          setStoriesList(response?.data);
        } catch (error) {
          console.log("error: ", error);
        } finally {
          setIsLoading(false);
        }
      }
    })();
    // eslint-disable-next-line
  }, [user.isLoggedIn, subscriptionPlan.isLoading]);

  const publishBook = () => {
    const { bookTitle, authorName, selectedStories } = state;
    const error = { author: "", book: "" };
  
    if (!bookTitle) {
      error.book = "Please add a title for your book.";
    }
  
    if (!authorName) {
      error.author = "Please add an Author's Name.";
    }
  
    if (error.book || error.author) {
      setErrors(error);
      return;
    }
  
    if (!selectedStories || selectedStories.length === 0) {
      return;
    }
  
    if (profanity.exists(bookTitle)) {
      Utils.failureToast(errorMessagesConstants.BAD_WORD_WARNING);
      updateState((prevState) => ({ ...prevState, bookTitle: "" }));
      return;
    }
  
    if (profanity.exists(authorName)) {
      Utils.failureToast(errorMessagesConstants.BAD_WORD_WARNING);
      updateState((prevState) => ({ ...prevState, authorName: "" }));
      return;
    }
  
    updateState({
      bookTitle: "",
      authorName: "",
      selectedStories: [],
    });
  
    onPublish({
      storyIds: selectedStories,
      authorName,
      bookTitle,
      profileImage: authorImage || user.profilePicture,
      language: currentLanguage,
    });
  };
  
  
  
  
  
  const onTitleChange = (e) => {
    if (e.target.value.length > 40) {
      setErrors((prev) => ({
        ...prev,
        book: "Please shorten your book title to 40 characters or less.",
      }));
      return;
    }
    if (errors.book) setErrors((prev) => ({ ...prev, book: "" }));
    updateState({ bookTitle: e.target.value });
  };
  const onAuthorNameChange = (e) => {
    const { NAME } = maxLengthconstants;
    if (e.target.value.length > NAME) {
      setErrors((prev) => ({
        ...prev,
        author: "Please shorten author name to 20 characters or less.",
      }));
      return;
    }
    if (errors.author) setErrors((prev) => ({ ...prev, author: "" }));
    updateState({ authorName: e.target.value });
  };

  const onImageSelect = (e) => {
    const [file] = e.target.files;
    if (!file.name.match(/\.(jpg|jpeg|png)$/)) {
      Utils.failureToast("Please select valid image.");
      return;
    }
    setAuthorImagePreview(URL.createObjectURL(file));
    setShowCropper(true);
  };

  return (
    <div className="cursor-pointer max-w-310 mx-auto px-4 md:px-15 xl:px-4 my-14">
      <div className="text-ft32-50 text-white font-TimesBold">
        Create New Book
      </div>
      <div className="w-full mt-15 flex sm:flex-row flex-col mobile:mt-3 gap-10 items-center">
        <div className="sm:w-50per w-full mobile:mt-3">
          <label className="text-gray-60 md:text-ft17-26 text-ft15-21 font-PoppinsRegular md:mt-0 mt-4.5">
            Add a Title for your book
          </label>
          <input
            className=" md:text-ft28-50 text-ft20-37 border-solid border-b-2 border-gray-120 bg-transparent pt-3 placeholder-gray-120 text-white font-PoppinsMedium focus:outline-none w-full"
            placeholder="Add a title"
            value={state.bookTitle}
            type="text"
            onChange={onTitleChange}
            required
          />
          {!!errors.book && (
            <div className="text-red-20 font-PoppinsRegular pl-2 md:text-ft15-21 text-ft11-18 mt-1">
              {errors.book}
            </div>
          )}
        </div>
        <div className="sm:w-50per w-full">
          <label className="text-gray-60 md:text-ft17-26 text-ft15-21  font-PoppinsRegular md:mt-0 mt-3">
            Author’s Name
          </label>
          <div className="relative">
            <input
              className=" md:text-ft28-50 text-ft20-37 pr-14 border-solid border-b-2 border-gray-120 bg-transparent py-3 placeholder-gray-120 text-white font-PoppinsMedium focus:outline-none w-full"
              placeholder="Add a Author name"
              onChange={onAuthorNameChange}
              value={state.authorName}
            ></input>
            <input
              onChange={onImageSelect}
              id="author-image"
              type="file"
              className="hidden"
            />
            <label htmlFor="author-image" className="cursor-pointer">
              <ImageWithLoader src={authorImage || user.profilePicture} />
            </label>
          </div>
          {!!errors.author && (
            <div className="text-red-20 font-PoppinsRegular pl-2 md:text-ft15-21 text-ft11-18 mt-1">
              {errors.author}
            </div>
          )}
        </div>
      </div>
      <div className="md:mt-20 mt-10">
        <div className="text-white text-ft17-26 font-PoppinsRegular md:mt-0 mt-3 md:ml-8 lg:ml-0">
          Select Stories (max 4)
        </div>
        {!!errors.story && (
            <div className="text-red-20 font-PoppinsRegular pl-2 md:text-ft15-21 text-ft11-18 mt-1">
              {errors.story}
            </div>
          )}
        {!isLoading ? (
          <div>
            <StoriesList
              state={state}
              onStorySelect={(storyId) =>
                dispatch({ type: "SELECT_STORY", payload: { storyId } })
              }
              updateState={updateState}
              storiesList={storiesList}
            />
          </div>
        ) : (
          <div className="h-40 w-full grid place-content-center">
            <BiLoaderAlt className="w-12 h-12 text-whiteOpacity-60 animate-spin" />
          </div>
        )}
      </div>
      <div className="flex md:flex-row flex-col md:items-center md:justify-center w-full gap-7 mt-13.5">
        <Link
          to="/story-book/home"
          className="border text-white border-white rounded-full md:w-50 md:h-10 py-2 grid place-items-center font-PoppinsMedium text-ft17-26"
        >
          Cancel
        </Link>
        <button
          className="bg-red-20 text-white rounded-full md:w-50 md:h-10 py-2 grid place-items-center font-PoppinsMedium text-ft17-26"
          onClick={publishBook}
        >
          Publish
        </button>
      </div>
      {showCropper && (
        <CropImagePopup
          onClose={() => {
            setShowCropper(false);
          }}
          selectedPicture={authorImagePreview}
          onConfirm={(image) => {
            setAuthorImage(image);
            setShowCropper(false);
          }}
        />
      )}
    </div>
  );
};

export default CreateBook;
