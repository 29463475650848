import { httpConstants } from "../constants";
import store from "../store";
import { Auth0Service } from "../services";

const createRequestOptions = (method, data, headers) => {
  const { GET } = httpConstants.METHOD_TYPE;
  const requestOptions = {
    method: method,
    headers: headers || { "Content-Type": "application/json" },
  };

  if (method !== GET) requestOptions.body = JSON.stringify(data);
  return requestOptions;
};

export const httpService = async (method, url, data, headers) => {
  const requestOptions = createRequestOptions(method, data, headers);

  try {
    const response = await fetch(url, requestOptions);
    const text = await response.text();
    const parsedData = text && JSON.parse(text); 
    if (!parsedData.success) {
      if (Number(parsedData.responseCode) === 401) {
        store.dispatch({ type: "REMOVE_USER" });
        new Auth0Service().logOut();
        throw parsedData.message;
      }
      const error = parsedData.responseCode === 404 ? parsedData : (parsedData && parsedData.message) || response.statusText;
      throw new Error(error);
    }
    return parsedData;
  } catch (err) {
    console.log(err)
    throw err;
  }
};
