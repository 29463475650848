import React from "react";

const Heading = () => {
  return (
    <div className="pt-10 pb-13 text-white text-center">
      <h1 className="font-TimesBold md:text-ft46-50 text-ft36-50">Make Payment</h1>
      <p className="font-PoppinsMedium md:text-ft20-37 text-ft18-27">
        Pay with credit or debit card
      </p>
    </div>
  );
};

export default Heading;
