import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Footer from "../../common/components/footer";
import Header from "../../common/components/header";
import StoriesList from "./storiesList";
import { StoryService } from "../../services";
import LoadingScreen from "../../common/components/loadingScreen";
import { history } from "../../managers/history";
import Pagination from "../../common/components/pagination";

function ViewMore({ location, match }) {
  const [storiesList, setStoriesList] = useState([]);
  const queryParams = new URLSearchParams(location.search);
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const user = useSelector((state) => state.user);

  const page = Number(queryParams.get("page")) || 1;
  const limit = 12;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const query = { limit, skip: (page - 1) * limit };
        if (user?.isLoggedIn) query.userId = user.userId;
        setIsLoading(true);
        query[queryParams.get("category")] = match?.params?.type;
        const { data, total: totalData } = await new StoryService().getStories(
          query
        );
        setStoriesList(data);
        setTotal(totalData);
      } catch (error) {
        console.log("error: ", error);
      } finally {
        setIsLoading(false);
      }
    })();
    // eslint-disable-next-line
  }, [page, location.pathname]);

  const onPageChange = (value) => {
    window.scrollTo(0, 0);
    history.push({
      search: new URLSearchParams({
        category: queryParams.get("category"),
        page: value,
      }).toString(),
    });
  };

  return (
    <div className="min-h-screen bg-black-10">
      <Header showCreateNew />
      {!isLoading ? (
        <>
          <StoriesList
            storiesList={storiesList}
            title={`${match?.params?.type} stories`}
            user={user}
          />
        </>
      ) : (
        <LoadingScreen />
      )}
      <Pagination
        onPageChange={onPageChange}
        limit={limit}
        total={total}
        page={page}
      />
      <Footer />
    </div>
  );
}

export default ViewMore;
