import React from "react";
import Header from "../../common/components/header";
import LoginComponent from "../signIn/signIn";

function Login() {
  return (
    <div className="bg-black-0 h-screen">
      <Header />
      <LoginComponent />
    </div>
  );
}

export default Login;
