import React from "react";

const LoadingScreen = ({ loadingText }) => {
  return (
    <div className="w-full min-cal-200-h-screen flex justify-center flex-col items-center">
      <div className="w-28 h-28 grid place-content-center relative">
        <img
          className="absolute left-1/2  top-1/2 transform -translate-x-1/2 -translate-y-1/2"
          src="/images/pen-icon.svg"
          alt=""
        />
        <div className="loader"></div>
      </div>
      {!!loadingText && (
        <div className="text-white text-center md:text-ft19-27 text-ft15-21 font-PoppinsRegular mt-5">
          {loadingText}
        </div>
      )}
    </div>
  );
};

export default LoadingScreen;
