import React, { useState, useRef, useEffect } from "react";
import { reportOptions } from "../../constants";

const ReportDialog = ({ onClose,storyReport ,setMessageValue }) => {

  const [selectedOption, setSelectedOption] = useState(reportOptions[0]);
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  function handleOptionClick(option) {
    setSelectedOption(option);
    setMessageValue(option);
    setIsOpen(false);
  }

  return (
    <div className="fixed inset-0 top-0 bg-blackOpacity-50 z-50 pt-169px pb-20  ">
      <div className="bg-white md:max-w-470px  md:px-25px px-4 md:mx-auto mx-3 border rounded-5 border-gray-10">
        <div className="flex justify-end items-end">
          <div className="text-center justify-between w-63per flex pb-15px pt-3.5 text-black-10 font-PoppinsMedium text-ft18-27 ">
            <div className="">Report Story</div>
            <div
              onClick={() => onClose(false)}
              className="w-11px h-11px cursor-pointer"
            >
              <img className=" mt-2 " src="/images/cancel.svg" alt="" />
            </div>
          </div>
        </div>

        <div className="text-center pb-6 font-PoppinsRegular text-ft15-21 text-gray-10 ">
          Select a reason
        </div>

        <div
          className="relative  md:w-105 mx-auto w-90per rounded-10 border-gray-50 border"
          ref={dropdownRef}
        >
          <button
            type="button"
            className="py-13px mx-auto flex justify-between pl-4 pr-4 rounded-10 w-full text-left bg-white   font-PoppinsMedium text-ft17-26 text-black-10"
            onClick={() => setIsOpen(!isOpen)}
            aria-haspopup="listbox"
            aria-expanded={isOpen}
            aria-labelledby="listbox-label"
          >
            <span className="block truncate">{selectedOption}</span>
            <div className="flex my-auto items-center">
              {" "}
              <img
                className="cursor-pointer h-2.5 w-3.5 "
                src="/images/arrow-down.svg"
                alt=""
              />
            </div>
          </button>
          {isOpen && (
            <div className="absolute mt-1 w-full rounded-md bg-white shadow-lg">
              <ul
                tabIndex="-1"
                role="menu"
                aria-labelledby="listbox-label"
                className="max-h-60 rounded-md py-1 text-base  overflow-auto focus:outline-none sm:text-sm"
              >
                {reportOptions.map((option) => (
                  <li
                    key={option}
                    onClick={() => handleOptionClick(option)}
                    className="text-black-10 cursor-pointer select-none relative py-2 pl-3 pr-9"
                  >
                    <div className="flex items-center">
                      <span
                        className={`${
                          selectedOption === option
                            ? "font-PoppinsSemiBold "
                            : "font-PoppinsRegular"
                        } block truncate`}
                      >
                        {option}
                      </span>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
        <button onClick={()=> {storyReport()}} className="  h-12.5 md:w-105 mx-auto w-3/4 rounded-10 font-PoppinsMedium bg-red-10 hover:bg-red-30 text-white mt-8 mb-10 flex justify-center  items-center">
          Submit Report
        </button>
      </div>
    </div>
  );
};

export default ReportDialog;
