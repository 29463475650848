import React from "react";
import Header from "../../common/components/header";
import Footer from "../../common/components/footer";
import SubscriptionPlan from "./subscriptionPlan";
import LoadingScreen from "../../common/components/loadingScreen";

const PlanCards = ({
  handleOnCheck,
  onSubscribe,
  subcriptionPlans,
  checkedPlanType,
  loading,
}) => {
  return (
    <>
      <Header />
      {loading ? (
        <LoadingScreen />
      ) : (
        <>
          <SubscriptionPlan
            onCheck={handleOnCheck}
            onSubscribe={onSubscribe}
            subcriptionPlans={subcriptionPlans}
            checkedPlanType={checkedPlanType}
          />
        </>
      )}
      <Footer />
    </>
  );
};

export default PlanCards;
