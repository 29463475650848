import React from "react";

const Success = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30.3"
    height="30.35"
    viewBox="0 0 30.3 30.35"
  >
    <path
      id="Success"
      d="M0,15.177A15.184,15.184,0,0,1,15.15,0a15,15,0,0,1,5.893,1.2,15.232,15.232,0,0,1,8.066,8.079h0A15.156,15.156,0,1,1,0,15.177Zm2.506,0A12.644,12.644,0,1,0,15.15,2.512,12.669,12.669,0,0,0,2.506,15.177Zm9.97,3.938L9.912,16.53A1.254,1.254,0,0,1,11.7,14.765l1.677,1.692,5.237-5.218a1.238,1.238,0,0,1,.885-.369,1.25,1.25,0,0,1,.885.369l0,.006a1.265,1.265,0,0,1-.009,1.771l-6.129,6.1a1.25,1.25,0,0,1-1.771,0Z"
      fill="#26ba13"
    />
  </svg>
);

export default Success;
