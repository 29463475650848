import React, { forwardRef } from "react";
import LoadingScreen from "../../../common/components/loadingScreen";
import BookCard from "../../../common/components/bookCard";

const RecentlyCreated = ({ state }, ref) => {
  const hasBooks = !!state.books.length;
  const isLoaderActive = state.loader;

  let content;

  if (isLoaderActive) {
    content = <LoadingScreen />;
  } else if (hasBooks) {
    content = (
      <div className="mt-10 grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 gap-y-14 gap-x-1 ipad:ml-6.5 md:ml-1.5 lg:ml-0">
        {state.books.map((data, index) => (
          <BookCard key={index} data={data} />
        ))}
      </div>
    );
  } else {
    content = (
      <div className="h-60vh grid place-content-center text-darkGray-20 font-PoppinsSemiBold text-ft30-37">
        No book created
      </div>
    );
  }

  return (
    <>
      <div
        ref={ref}
        className="max-w-310 mx-auto px-4 md:px-6 lg:px-4 md:my-14 my-10"
      >
        <div className="md:text-ft32-50 text-ft28-50 mb-15 text-white font-TimesBold md:max-w-none max-w-87.5 mx-auto md:mx-0">
          Recently Created Books
        </div>
        {content}
      </div>
    </>
  );
};

export default forwardRef(RecentlyCreated);
