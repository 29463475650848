import React, { useState, useEffect, useRef } from "react";
import MainSection from "./mainSection";
import RecentlyCreated from "./recentlyCreated";
import { BookService } from "../../../services";
import BookPagination from "./bookPagination";
import { useLocation } from "react-router-dom";
import { history } from "../../../managers/history";

const StoryBookHome = ({ state, user }) => {
  const [booksState, setBooksState] = useState({
    books: [],
    totalBooks: "",
    loader: false,
  });
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const page = Number(queryParams.get("page")) || 1;
  const limit = 4;

  const scrollRef = useRef();

  useEffect(() => {
    window.scrollTo(0,0)
  }, []);

  useEffect(() => {
    setBooksState((pre) => ({ ...pre, loader: true }));
    (async () => {
      try {
        const { data = [], total = 0 } = await new BookService().getBooks({
          limit,
          skip: (page - 1) * limit,
        });
        setBooksState({ books: data, totalBooks: total, loader: false });
      } catch (err) {
        console.log(err);
      } finally {
        setBooksState((pre) => ({ ...pre, loader: false }));
      }
    })();
    // eslint-disable-next-line
  }, [page, location.pathname]);

  const onPageChange = (value) => {
    if (scrollRef.current)
      scrollRef.current.scrollIntoView({ behavior: "smooth" });
    history.push({
      search: new URLSearchParams({
        page: value,
      }).toString(),
    });
  };

  return (
    <>
      <MainSection state={state} user={user} />
      <RecentlyCreated ref={scrollRef} state={booksState} />
      <BookPagination
        onPageChange={onPageChange}
        limit={limit}
        total={booksState.totalBooks}
        page={page}
      />
    </>
  );
};

export default StoryBookHome;
