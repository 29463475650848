import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import Footer from "../../common/components/footer";
import Header from "../../common/components/header";
import LoadingScreen from "../../common/components/loadingScreen";
import { history } from "../../managers/history";
import { SubscriptionService } from "../../services";
import BillingComponent from "./billing";
import { actionTypeConstants } from "../../constants";
import Utils from "../../utility";

const { RESET_SUBSCRIPTION } = actionTypeConstants;

function Billing() {
  const [currentPlan, setCurrentPlan] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      const { subscriptionPlan } = user;
      if (subscriptionPlan.isLoading) return;
      if (!subscriptionPlan.hasPlan) {
        history.push("/subscription-plan");
        return;
      }
      setIsLoading(true);
      try {
        const [response] = await new SubscriptionService().getPlanById({
          _id: subscriptionPlan?.planDetails?.planId,
        });
        setCurrentPlan(response);
      } catch (error) {
        console.log("error: ", error);
      } finally {
        setIsLoading(false);
      }
    })();
    // eslint-disable-next-line
  }, [user.subscriptionPlan.isLoading]);

  const handleCancelSubscription = async () => {
    const confirmText = "Yes, Cancel";
    const cancelButtonText = "No, don't";

    if (currentPlan?.planType?.toLowerCase() === "free") {
      await cancelSubscription();
      Utils.succesToast("Subscription cancelled successfully");
      dispatch({ type: RESET_SUBSCRIPTION });
      setTimeout(() => {
        history.push("/");
      }, 1000);
      return;
    }

    const result = await showCancelSubscriptionConfirmation(confirmText, cancelButtonText);

    if (result.isConfirmed && result.value) {
      await cancelSubscription(result.value);
      Utils.succesToast("Subscription cancelled successfully");
      dispatch({ type: RESET_SUBSCRIPTION });
      setTimeout(() => {
        history.push("/");
      }, 1000);
    }
  };

  const cancelSubscription = async (reason = "") => {
    try {
      await new SubscriptionService().cancelSubscription({ reason });
    } catch (error) {
      Utils.succesToast(
        "An error occurred while cancelling your subscription, Please try again!"
      );
      console.log("error: ", error);
    }
  };

  const showCancelSubscriptionConfirmation = (confirmText, cancelButtonText) => {
    return Swal.fire({
      title: "Cancel Subscription?",
      text: "Important: This action can not be undone. The subscription will move to cancelled state.",
      input: "text",
      confirmButtonText: confirmText,
      confirmButtonColor: "#FF2A29",
      cancelButtonText: cancelButtonText,
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      inputValidator: (value) => {
        if (!value) {
          return "Please enter the reason";
        }
      },
    });
  };

  return (
    <div className="bg-black-0">
      <Header />
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <BillingComponent
          currentPlan={currentPlan}
          subscribedPlan={user.subscriptionPlan}
          onCancelSubscription={handleCancelSubscription}
        />
      )}
      <Footer />
    </div>
  );
}

export default Billing;
