import React, { useState, useEffect } from "react";
import { HiDotsHorizontal } from "react-icons/hi";
import { Link, useLocation } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import Stars from "react-stars";
import ClickOutside from "../../common/components/clickOutside";
import TaleCard from "./taleCard";
import { FaPlay } from "react-icons/fa";
import { GiPauseButton } from "react-icons/gi";
import { BiLoaderAlt } from "react-icons/bi";
import { Tooltip } from "@material-ui/core";
import Utils from "../../utility";
import StoryTextHighlighter from "./storyTextHighlighter";
import VideoPlayer from "./videoPlayer";
import { history } from "../../managers/history";
import { sessionManager } from "../../managers/sessionManager";
import { StoryService } from "../../services";
import { BsBookmark } from "react-icons/bs";
import BookMarkIcon from "../../assets/images/bookmark-icon.svg";

const MainSection = ({
  data,
  story,
  totalRatings,
  audioStory,
  onPlay,
  isPlaying,
  audioTime,
  onVideoPlay,
  videoStory,
  user,
  onDelete,
  setVideoStory,
  setShowReportDailog,
  setShowDeleteDailog,
  highlighTextId,
  textStory,
  isPrivate,
  updateStoryPrivacy,
  currLang,
}) => {
  const [showReportBtn, setShowReportBtn] = useState(false);
  const { setDataInCookies } = sessionManager;
  const { pathname } = useLocation();
  const [loading, setLoading] = useState(false);
  const [bookmarkStory, setBookmarkStory] = useState(false);
  const [showStoryPrivacy, setShowStoryPrivacy] = useState(false);
  const [showShareMenu, setShowShareMenu] = useState(false);

  const getShareUrl = () => window.origin + pathname;
  const [copied, setCopied] = useState(false);

  function handleScroll() {
    const element = document.getElementById("rate-tale");
    element.scrollIntoView({ behavior: "smooth", block: "center" });
  }

  const handleCopy = () => {
    setCopied(true);
  };

  const getProfileUrl = (userData) => {
    const userID = userData?._id?.substring(userData?._id.length - 8);
    const userName = userData?.firstName.toLowerCase();
    return userName + "-" + userID;
  };

  const createdOnText =
    story?.addedOn && Utils.epocToPrettyTime(story?.addedOn);
  const isLessThan24Hours = Date.now() - story?.addedOn < 24 * 60 * 60 * 1000;

  const redirectToLogin = () => {
    setDataInCookies("REDIRECT_LINK", pathname, 1);
    setDataInCookies("REPORT_DIALOG", true, 1);
    history.push("/login");
  };

  useEffect(() => {
    if (user.isLoggedIn && story._id) {
      (async () => {
        try {
          await new StoryService().getBookmarkStoriesById({
            userId: user.userId,
            storyId: story._id,
          });
          setBookmarkStory(true);
        } catch (error) {
          console.log("error: ", error);
        }
      })();
    }
  }, [user, story]);

  const BookmarkStories = async () => {
    setBookmarkStory(!bookmarkStory);
    setLoading(true);
    try {
      const response = await new StoryService().bookmarkStories({
        userId: user.userId,
        storyId: story._id,
      });

      if (response) {
        setLoading(false);
      }
    } catch (error) {
      console.log("error: ", error);
    }
  };

  let ageText = "";
  if (!!story?.age) {
    ageText = `${story?.age} +`;
  }

  let privacyText = "";
  if (isPrivate) {
    privacyText = "Private";
  } else {
    privacyText = "Public";
  }

  const mainSectionText = `${story?.type || ""} · ${
    story?.character || ""
  } · ${ageText} · ${privacyText} ·`;

  let bookmarkButtonContent;

  if (bookmarkStory) {
    if (loading) {
      bookmarkButtonContent = (
        <BiLoaderAlt className="h-5 w-5 animate-spin text-gray-10 dark:text-gray-600 fill-blue-10 " />
      );
    } else {
      bookmarkButtonContent = (
        <button onClick={BookmarkStories}>
          <img src={BookMarkIcon} className="h-full" alt="" />
        </button>
      );
    }
  } else {
    if (loading) {
      bookmarkButtonContent = (
        <BiLoaderAlt className="h-5 w-5 animate-spin text-gray-10 dark:text-gray-600 fill-blue-10 " />
      );
    } else {
      bookmarkButtonContent = (
        <BsBookmark
          className="text-white cursor-pointer h-full"
          onClick={BookmarkStories}
        />
      );
    }
  }

  let privacyButtonContent = null;
  if (
    user?.userId === story?.userRes?.userId &&
    user?.subscriptionPlan?.planDetails?.planType !== "Free" &&
    !user?.subscriptionPlan?.expired
  ) {
    privacyButtonContent = (
      <div className="relative h-4.5">
        <button
          onClick={() => {
            setShowStoryPrivacy((p) => !p);
          }}
          className="h-4.5"
        >
          {isPrivate ? (
            <img src="/images/lock.svg" alt="" />
          ) : (
            <img src="/images/lock-open.svg" alt="" />
          )}
        </button>
        {showStoryPrivacy && (
          <ClickOutside onClickOutside={setShowStoryPrivacy}>
            <div
              className={`absolute top-10 lock-menu text-white bg-black-20 ${
                user.isLoggedIn && user.userId === story.userRes.userId
                  ? "md:w-197px w-46 pl-1"
                  : ""
              } rounded-lg md:text-ft14-21 text-ft12-18 z-10 border border-black-20 items-center`}
            >
              {user.isLoggedIn && user.userId === story.userRes.userId && (
                <div className="flex p-2">
                  <img src="/images/lock.svg" alt="" />
                  <button
                    onClick={() => {
                      updateStoryPrivacy();
                      setShowStoryPrivacy(false);
                    }}
                    className="block py-1 px-3 text-center font-PoppinsMedium"
                  >
                    {isPrivate ? "Make Story Public" : "Make Story Private"}
                  </button>
                </div>
              )}
            </div>
          </ClickOutside>
        )}
      </div>
    );
  }

  return (
    <section className="w-full  px-5.5 md:mt-13.5 mt-8.5">
      <div className="w-full pt-2">
        {videoStory.show && (
          <VideoPlayer
            videoStory={videoStory}
            onClose={() => {
              setVideoStory((p) => ({ ...p, show: false }));
            }}
          />
        )}
      </div>
      <div className="max-w-210 mx-auto flex justify-between md:items-center md:flex-row flex-col">
        <div className="flex gap-2.5">
          {story?.userRes?.userId && (
            <Link to={`/profile/${getProfileUrl(story?.userRes)}`}>
              <img
                className="h-49px w-49px rounded-full mt-3"
                src={story?.userRes?.profilePicture}
                alt={story?.userRes?.userId ? story.userRes.name : ""}
              />
            </Link>
          )}
          <div className="mt-2">
            {story?.userRes?.userId && (
              <div className="text-white font-PoppinsMedium text-ft14-21 flex items-center">
                {story?.userRes?.name}
              </div>
            )}
            <div className="flex md:items-center md:mt-1.5 md:gap-3 md:flex-row flex-col">
              <div className="text-gray-10 text-ft14-21 font-PoppinsMedium">
                {mainSectionText}
              </div>
              <div className="flex gap-4">
                <AudioListen
                  onPlay={onPlay}
                  audioTime={audioTime}
                  audioStory={audioStory}
                  isPlaying={isPlaying}
                  story={story}
                />
                <VideoPlay
                  setVideoStory={setVideoStory}
                  onPlay={onVideoPlay}
                  videoStory={videoStory}
                  story={story}
                />
              </div>
            </div>

            <div className=" text-gray-10 text-ft14-21 font-PoppinsMedium">
              {createdOnText && (
                <span className="text-gray-10 text-ft14-21  font-PoppinsMedium ">
                  Created {isLessThan24Hours ? "" : " on"} {createdOnText}
                </span>
              )}
            </div>
          </div>
        </div>

        <div className="flex md:flex-row flex-col justify-between md:items-center md:mt-0 mt-5.5">
          <div className="flex gap-4.5 items-center md:justify-end justify-between">
            <div className="flex gap-4.5 items-center relative">
              {bookmarkButtonContent}

              {privacyButtonContent}

              <div className="relative h-4.5">
                <button
                  onClick={() => {
                    setShowShareMenu((p) => !p);
                  }}
                  className="h-4.5"
                >
                  <img src="/images/share.svg" alt="" />
                </button>
                {showShareMenu && (
                  <ClickOutside onClickOutside={setShowShareMenu}>
                    <div
                      className={`absolute top-10 lock-menu text-white  bg-black-20 ${
                        user.isLoggedIn ? "lg:w-197px w-35 pl-1" : ""
                      } rounded-lg md:text-ft14-21 text-ft12-18 z-10 font-PoppinsMedium border border-black-20 items-center`}
                    >
                      {user.isLoggedIn && (
                        <>
                          <TwitterShareButton
                            title={`Read the story, ${story?.storyTitle} I generated using AI on makemytale.com`}
                            url={getShareUrl()}
                            className="flex p-2 items-center"
                          >
                            <img
                              src="/images/twitter-icon.svg"
                              alt=""
                              className=""
                            />
                            <div className="px-3">Twitter</div>
                          </TwitterShareButton>
                          <FacebookShareButton
                            title={`Read the story, ${story?.storyTitle} I generated using AI on makemytale.com`}
                            url={getShareUrl()}
                            className="flex p-2 items-center"
                          >
                            <img
                              src="/images/facebook-icon.svg"
                              alt=""
                              className=""
                            />
                            <div className="px-3">Facebook</div>
                          </FacebookShareButton>
                        </>
                      )}
                    </div>
                  </ClickOutside>
                )}
              </div>

              <div>
                <Tooltip
                  disableTouchListener
                  className="cursor-pointer"
                  title={
                    <div className="z-40 rounded  font-PoppinsMedium  md:text-ft14-21 text-ft11-18">
                      {copied ? "Copied" : "Copy"}
                    </div>
                  }
                  text={getShareUrl()}
                  placement="top"
                >
                  <CopyToClipboard text={getShareUrl()} className="h-4.5">
                    <img
                      onClick={handleCopy}
                      className="cursor-pointer "
                      src="/images/link-connection-icon.svg"
                      alt=""
                    />
                  </CopyToClipboard>
                </Tooltip>
              </div>
            </div>
            <div className="relative mt-2">
              <button onClick={() => setShowReportBtn((p) => !p)}>
                <HiDotsHorizontal className="w-8 h-8 text-white" />
              </button>
              {showReportBtn && (
                <ClickOutside onClickOutside={setShowReportBtn}>
                  <div
                    className={`absolute top-10 right-0 text-black  bg-white ${
                      user.isLoggedIn && user.userId === story.userRes.userId
                        ? "w-35 pl-1"
                        : ""
                    } rounded-lg text-base z-10 text-ft17-26 border items-center`}
                  >
                    {user.isLoggedIn &&
                      user.userId === story.userRes.userId && (
                        <button
                          onClick={() => {
                            setShowReportBtn(false);
                            setShowDeleteDailog(true);
                          }}
                          className="block py-1 px-3 text-center font-bold"
                        >
                          Delete Story
                        </button>
                      )}
                    {user.userId !== story.userRes.userId && (
                      <button
                        onClick={() => {
                          user.isLoggedIn
                            ? setShowReportDailog(true)
                            : redirectToLogin();
                        }}
                        className="block text-left py-1 px-3 font-bold"
                      >
                        Report
                      </button>
                    )}
                  </div>
                </ClickOutside>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="max-w-210 mx-auto">
        <div className="text-white md:text-ft42-51 text-ft30-37 font-TimesBold md:mt-16 mt-8">
          {story?.storyTitle || ""}
        </div>
        <div className="flex  text-ft14-21 md:mt-2 mt-1 lg:w-3/12 items-center font-PoppinsMedium">
          <span className="cursor-pointer" onClick={handleScroll}>
            <Stars
              count={5}
              value={Number(story?.currentRating?.$numberDecimal)}
              edit={false}
              size={20}
              half={true}
              color1={"#6C6C6C"}
              color2={"#F8C413"}
            />
          </span>

          <div className="text-gray-10 ml-1.5 ">{`(${totalRatings} ratings)`}</div>
        </div>
      </div>
      <div className="max-w-210 mx-auto  md:mt-67px mt-7.5 flex flex-col md:gap-13.5 gap-7.5">
        {audioStory.status === "CREATED"
          ? textStory.map((para, idx) => (
              <StoryTextHighlighter
                key={idx}
                isPlaying={isPlaying}
                activeSegmentId={highlighTextId}
                storyParagraph={para}
                storyId={story?._id}
                currLang={currLang}
                showRegenerate={
                  (user.isLoggedIn &&
                    user?.userId === story?.userRes?.userId) ||
                  (user.isLoggedIn && user?.role === "admin")
                }
              />
            ))
          : data.map((tale, idx) => (
              <TaleCard
                user={user}
                key={idx}
                tale={tale}
                audioStory={audioStory}
                storyId={story?._id}
                currLang={currLang}
                showRegenerate={
                  (user.isLoggedIn &&
                    user?.userId === story?.userRes?.userId) ||
                  (user.isLoggedIn && user?.role === "admin")
                }
              />
            ))}
      </div>
    </section>
  );
};

export default MainSection;

const AudioListen = ({ audioStory, audioTime, onPlay, isPlaying, story }) => {
  const isLessThan3Hours = Date.now() < story?.addedOn + 3 * (60 * 60 * 1000);

  let buttonText = audioStory.show ? audioTime : "Listen";

  const playButtonContent = isPlaying ? (
    <>
      <button className="text-white h-4 w-4 rounded-full grid place-content-center bg-red-10">
        <GiPauseButton className="w-2 h-2" />
      </button>
      <span className="text-gray-10 text-ft14-21 ml-1 font-PoppinsMedium ">
        {buttonText}
      </span>
    </>
  ) : (
    <>
      <button className="text-white h-4 w-4 rounded-full grid place-content-center bg-red-10">
        <FaPlay className="w-2 h-2" />
      </button>
      <span className="text-gray-10 text-ft14-21 ml-1 font-PoppinsMedium ">
        {buttonText}
      </span>
    </>
  );

  const loaderContent = (
    <div translate="no" className="flex justify-center items-center">
      <BiLoaderAlt className="h-5 w-5 animate-spin text-gray-10 dark:text-gray-600 fill-blue-10" />
      <span className="text-gray-10 text-ft14-21 ml-1 font-PoppinsMedium ">
        {buttonText}
      </span>
    </div>
  );

  return audioStory.status === "CREATED" ? (
    <div className="md:flex mobile:flex flex">
      <button onClick={onPlay} className="flex items-center gap-1">
        {playButtonContent}
      </button>
    </div>
  ) : (
    <> {isLessThan3Hours && loaderContent}</>
  );
};

const VideoPlay = ({ setVideoStory, videoStory, onPlay, story }) => {
  story?.addedOn && Utils.epocToPrettyTime(story?.addedOn);
  const scrollToVideo = () => window.scrollBy(0, 60);
  const playVideo = () => {
    onPlay();
    scrollToVideo();
  };
  return (
    <>
      <button onClick={playVideo} className="flex items-center gap-1">
        <span className="text-white h-4 w-4 rounded-full grid place-content-center">
          <img className="w-5 h-5" src="/images/video-icon.svg" alt="" />
        </span>
        <span className="text-gray-10 text-ft14-21 ml-1 font-PoppinsMedium ">
          Watch
        </span>
      </button>
    </>
  );
};
