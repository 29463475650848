import React from "react";
import RateThisTale from "./rateTale";

const RateReport = ({ onChange, rate, onReport, deleteStory, setShowReportDailog }) => {
  return (
    <div className="flex justify-between md:items-center md:flex-row flex-col max-w-210 mx-auto md:px-8 px-5.5 lg:px-0   md:mt-22 mt-8 md:mb-22 mb-9">
      <RateThisTale onChange={onChange} rate={rate} />
      <div className="text-gray-60 font-PoppinsMedium text-ft16-22">
        Found an issue?{" "}
        <button
          className="text-white"
          onClick={() => setShowReportDailog(true)}
        >
          Report
        </button>
        {/* <button className="text-white ml-2" onClick={deleteStory}>
          Delete Story
        </button> */}
      </div>
    </div>
  );
};

export default RateReport;
