import React from "react";
import Header from "../../common/components/header";
import CreateAccount from "./createAccount";

function SignUp() {
  return (
    <div className="bg-black-0 h-screen">
      <Header  />
      <CreateAccount/>
    </div>
  );
}

export default SignUp;
