import React, { useReducer, useEffect, useState } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import Header from "../../common/components/header";
import Footer from "../../common/components/footer";
import StoryBookHome from "./storybookHome";
import CreateBook from "./createBook";
import reducer, { initialState } from "./reducer";
import { storyBookConstants } from "../../constants";
import MyBooks from "./myBooks";
import PreviewBook from "./previewBook";
import { history } from "../../managers/history";
import { BookService } from "../../services";

const { ACTION_TYPES } = storyBookConstants;

const { UDPATE_STATE } = ACTION_TYPES;

const StoryBook = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const user = useSelector((userState) => userState.user);

  const [loading, setLoading] = useState(false)

  const updateState = (payload) => dispatch({ type: UDPATE_STATE, payload });

  const handlePublishBook = async (_requestData) => {
    updateState({ storyBook: { status: "LOADING", data: _requestData } });
    try {
      history.push("/story-book/my-books");
      const response = await new BookService().generateBook(_requestData);
      updateState({ storyBook: { status: "CREATED", data: response } });
      setLoading(true)
    } catch (error) {
      updateState({ storyBook: { status: "ERRORED", data: {} } });
    }
    finally{
      setLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      updateState({ booksIsLoading: true });
      try {
        const { data = [] } = await new BookService().getBooks({
          userId: user?.userId,
        });
        updateState({ myBooks: data });
      } catch (error) {
        console.error("error: ", error);
      } finally {
        updateState({ booksIsLoading: false });
      }
    })();
    // eslint-disable-next-line
  }, [loading]);

  useEffect(() => {
    updateState({ authorName: user.name });
    // eslint-disable-next-line
  },[loading]);

  return (
    <div className="bg-black-10 min-h-screen">
      <Header />
      <Switch>
        <Route
          path="/story-book/home"
          exact
          render={() => <StoryBookHome state={state} user={user} />}
        />
        <Route
          path="/story-book/create-book"
          exact
          render={() => (
            <CreateBook
              state={state}
              dispatch={dispatch}
              onPublish={handlePublishBook}
              updateState={updateState}
              user={user}
            />
          )}
        />
        <Route
          path="/story-book/my-books"
          exact
          render={() => (
            <MyBooks updateState={updateState} state={state} user={user} />
          )}
        />
        <Route
          path="/story-book/book/:bookId"
          exact
          render={() => <PreviewBook />}
        />
        <Redirect exact from="*" to="/story-book/home" />
      </Switch>
      <Footer />
    </div>
  );
};

export default StoryBook;
