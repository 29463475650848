import { actionTypeConstants, keyTypeConstants } from "../constants";
import Utils from "../utility";
import { sessionManager } from "../managers/sessionManager";

const {
  UPDATE_USER,
  REMOVE_USER,
  SET_USER_PLAN,
  DEDUCT_BALANCE,
  SET_LOADING_SUB,
  RESET_SUBSCRIPTION,
} = actionTypeConstants;
const { USER, AUTH0_ID_TOKEN } = keyTypeConstants;

const getToken = () => {
  return sessionManager.getDataFromCookies(AUTH0_ID_TOKEN);
};

const storeData = (data) => {
  Utils.storeToLocalStorage(USER, data);
};

const clearData = () => {
  localStorage.clear();
  sessionManager.removeDataFromCookies(AUTH0_ID_TOKEN);
};

const getUserData = () => {
  const token = getToken();
  if (token) return Utils.getDataFromLocalStorage(USER);
  localStorage.clear();
  return null;
};

const userData = !!getUserData() ? getUserData() : {};

const PLAN_DEFAULT = {
  hasPlan: false,
  expired: false,
  isLoading: true,
  planDetails: {
    featureBalances: {
      readStory: "Unlimited",
      AIStory: 0,
      audioBook: 0,
      videoBook: 0,
      coAuthor: false,
      coAuthorProfile: false,
      editCreatedStory: false,
      downloadStoryBook: false,
      imageWithOwnPrompt: false,
    },
    dailyCredits: {
      readStory: "Unlimited",
      AIStory: 0,
      audioBook: 0,
      videoBook: 0,
    },
  },
};

const initialState = {
  isLoggedIn: !!userData?.userId,
  userId: userData?.userId || "",
  _id: userData?._id || "",
  name: userData?.name || "",
  firstName: userData?.firstName || "",
  lastName: userData?.lastName || "",
  email: userData?.email || "",
  profilePicture: userData?.profilePicture || "",
  userIntro: userData?.userIntro || "",
  role: userData?.role,
  accessToken: getToken() || "",
  subscriptionPlan: { ...PLAN_DEFAULT },
};

export default function user(state = initialState, action) {
  switch (action.type) {
    case UPDATE_USER:
      const { isLoggedIn, subscriptionPlan, accessToken, ...localData } = {
        ...state,
        ...action.payload,
      };
      storeData(localData);
      return { ...state, ...action.payload, isLoggedIn: true };
    case SET_USER_PLAN:
      return { ...state, subscriptionPlan: { ...action.payload } };
    case DEDUCT_BALANCE:
      return {
        ...state,
        subscriptionPlan: {
          ...state.subscriptionPlan,
          planDetails: Number(state.subscriptionPlan.planDetails?.dailyCredits?.AIStory) < 1
              ? {
                  ...state.subscriptionPlan.planDetails,
                  featureBalances: {
                    ...state.subscriptionPlan.planDetails.featureBalances,
                    AIStory: Number(state?.subscriptionPlan?.planDetails?.featureBalances?.AIStory || 0) - 1,
                    audioBook: Number(state?.subscriptionPlan?.planDetails?.featureBalances?.audioBook || 0) - 1,
                  },
                }
              : {
                  ...state.subscriptionPlan.planDetails,
                  dailyCredits: {
                    ...state.subscriptionPlan.planDetails.dailyCredits,
                    AIStory: Number(state?.subscriptionPlan?.planDetails?.dailyCredits?.AIStory || 0) - 1,
                    audioBook: Number(state?.subscriptionPlan?.planDetails?.dailyCredits?.audioBook || 0) - 1,
                  },
                },
        },
      };
    case SET_LOADING_SUB:
      return {
        ...state,
        subscriptionPlan: {
          ...state.subscriptionPlan,
          isLoading: action.payload,
        },
      };
    case REMOVE_USER:
      clearData();
      return {
        isLoggedIn: false,
        userId: "",
        name: "",
        firstName: "",
        lastName: "",
        email: "",
        profilePicture: "",
        accessToken: "",
        userIntro: "",
        subscriptionPlan: { ...PLAN_DEFAULT, isLoading: false },
      };
    case RESET_SUBSCRIPTION:
      return {
        ...state,
        subscriptionPlan: { ...PLAN_DEFAULT, isLoading: false },
      };
    default:
      return state;
  }
}
