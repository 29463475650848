const initialState = {
  storyConfig: { storyId: "", age: "", character: "", type: "", fetchData: false },
  storyStatus: { showLoader: false, isReady: false },
  showLayer: false,
  generatedStory: {},
};

export default function story(state = initialState, action) {
  switch (action.type) {
    case "UPDATE_STORY":
      return { ...state, ...action.payload };
    case "UPDATE_STORY_STATUS":
      return { ...state, ...action.payload };
    case "UPDATE_GENERATED_STORY":
      return { ...state, generatedStory: { ...action.payload } };
    case "UPDATE_CONFIG":
      return {
        ...state,
        storyConfig: { ...action.payload },
      };
    case "RESET_CONFIG":
      return {
        ...state,
        storyConfig: {
          storyId: "",
          age: "",
          character: "",
          type: "",
          fetchData: false,
        },
      };
    case "RESET_STORY":
      return {
        storyConfig: {
          storyId: "",
          age: "",
          character: "",
          type: "",
          fetchData: false,
        },
        storyStatus: { showLoader: false, isReady: false },
        showLayer: false,
        generatedStory: {},
      };
    default:
      return state;
  }
}
