import React from "react";
import { MdClose } from "react-icons/md";
import { FaPlay } from "react-icons/fa";
import { GiPauseButton } from "react-icons/gi";

const AudioPlayer = ({
  isPlaying,
  onPlayPauseClick,
  skip,
  onClose,
  trackProgress,
}) => {
  return (
    <div className="md:bottom-10 bottom-0 right-0 md:right-10 md:mb-10 md:mr-5 fixed w-full md:h-143px md:w-351px h-22.5 ">
      <button
        className="absolute top-0 right-0 mr-2 mt-2"
        onClick={() => onClose(false)}
      >
        <MdClose className="text-white w-6 h-6" />
      </button>
      <div className="bg-red-20 md:rounded-lg py-3  md:block flex">
        <div className="flex md:justify-evenly gap-2 md:ml-0 mt-4 ml-2 w-2/5 md:w-full  items-center">
          <button onClick={() => skip("back")}>
            <img
              className="w-9 md:w-14 "
              src="/images/prev-button.svg"
              alt=""
            />
          </button>
          {isPlaying ? (
            <button
              className="bg-white h-9 w-9 md:w-14 md:h-14 rounded-full grid place-content-center text-red-10"
              onClick={() => onPlayPauseClick(false)}
            >
              <GiPauseButton className="w-6 h-6  " />
            </button>
          ) : (
            <button
              className="bg-white h-9 w-9 md:w-14 md:h-14  rounded-full grid place-content-center text-red-10"
              onClick={() => onPlayPauseClick(true)}
            >
              <FaPlay className="md:w-6 w-4  md:h-6 h-4" />
            </button>
          )}
          <button
            className="bg-white  w-9 md:w-14   rounded-full grid place-content-center text-red-10"
            onClick={() => skip("forward")}
          >
            <img
              className="w-9 md:w-14 "
              src="/images/next-button.svg"
              alt=""
            />
          </button>
        </div>
        <div className="h-1 bg-red-30 md:mx-4 my-7 w-2/4 md:w-auto md:auto">
          <div
            style={{
              width: trackProgress,
            }}
            className="h-full bg-white"
          ></div>
        </div>
      </div>
    </div>
  );
};

export default AudioPlayer;
