import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Header from "../../common/components/header";
import Footer from "../../common/components/footer";
import MainSection from "./mainSection";
import LoadingScreen from "../../common/components/loadingScreen";
import StoryService from "../../services/storyService";
import { UserService } from "../../services";
import Utils from "../../utility";
import { history } from "../../managers/history";
import LoadingPopup from "./loadingPopup";
import AudioPlayer from "./audioPlayer";
import RateReport from "./rateReport";
import ReportDialog from "./reportDialog";
import DeleteStoryPopup from "./deleteStoryPopup";
import { sessionManager } from "../../managers/sessionManager";
import { keyTypeConstants } from "../../constants";
const { AUTH0_ID_TOKEN } = keyTypeConstants;

const createTextDuration = ({ storyAudio, images }) => {
  const textStory = {};
  const textDurations = [];
  let lastDuration = 0;
  let curtRunnedDuration = 0;

  storyAudio.imageDurations.forEach((data, idx) => {
    lastDuration = curtRunnedDuration;
    curtRunnedDuration = data.duration + curtRunnedDuration;
    if (!textStory[data.image]) {
      const image = images.find(({ id }) => id === data.image);
      textStory[data.image] = { image, storyTexts: [] };
    }
    textStory[data.image]["storyTexts"].push({ id: idx, text: data.storyText });
    textDurations.push({
      id: idx,
      startTime: lastDuration,
      endTime: curtRunnedDuration,
    });
  });

  return { textStory: Object.values(textStory), textDurations };
};
const showDailog = () => {
  const isOpen = sessionManager.getDataFromCookies("REPORT_DIALOG");
  if (isOpen) {
    sessionManager.removeDataFromCookies("REPORT_DIALOG");
    return isOpen;
  } else {
    return false;
  }
};
const Home = ({ match }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [messageValue, setMessageValue] = useState("");

  const [generatedStory, setGeneratedStory] = useState({});
  const [showPopup, setShowPopup] = useState(false);
  const [showReportDailog, setShowReportDailog] = useState(showDailog());
  const [showDeleteDailog, setShowDeleteDailog] = useState(false);
  const [language, setLanguage] = useState(false);
  const [currLang, setCurrLang] = useState("");
  const [audioStory, setAudioStory] = useState({
    show: false,
    audioUrl: "",
    status: "PENDING",
  });
  const [videoStory, setVideoStory] = useState({
    show: false,
    videoUrl: "",
    status: "PENDING",
  });

  useEffect(() => {
    const googleTrans = sessionManager.getDataFromCookies("googtrans");
    let currentLanguage = googleTrans ? googleTrans.split("/")[2] : "en";
    setCurrLang(currentLanguage);
    console.log("currentLanguage: " + currentLanguage);
  }, [language]);

  document.body.style.overflow = showReportDailog ? "hidden" : "unset";
  const [isPlaying, setIsPlaying] = useState(false);
  const [audioTime, setAudioTime] = useState("0:00");
  const [totalRatings, setTotalRatings] = useState(0);
  const [rate, setRate] = useState(0);
  const [trackProgress, setTrackProgress] = useState("0%");
  const [highlighTextId, setactiveSegmentIdId] = useState(0);
  const [textStoryAndDurations, setTextStoryAndDurations] = useState({
    textStory: [],
    textDurations: [],
  });

  const { search, pathname } = useLocation();

  const story = useSelector((state) => state.story);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [isStoryPrivate, setIsStoryPrivate] = useState(story.isPrivate);

  const refState = useRef({
    storyInterval: null,
    audioVideoInterval: null,
    trackAudio: true,
    trackVideo: true,
  });

  const audioRef = useRef(new Audio());
  const intervalRef = useRef();

  useEffect(() => {
    if (user.isLoggedIn && story._id) {
      (async () => {
        try {
          await new UserService().addReadStory({
            userId: user.userId,
            storyId: story?._id,
          });
        } catch (err) {
          console.log(err);
        }
      })();
    }
    // eslint-disable-next-line
  }, [user.isLoggedIn, story._id]);

  useEffect(() => {
    if (audioRef.current) {
      if (isPlaying) audioRef.current.play();
      else audioRef.current.pause();
    }
    return () => {
      if (audioRef.current && isPlaying) audioRef.current.pause();
    };
  }, [isPlaying]);

  useEffect(() => {
    const urlTitle = match?.params?.storyTitle.split("-");
    const title = urlTitle.slice(0, -1).join(" ");
    const storyTitle =
      title !== "makemytale story" ? title.replace(/_/g, "-") : "";
    document.title = storyTitle.charAt(0).toUpperCase() + storyTitle.slice(1);
    return () => {
      if (refState.current.audioVideoInterval !== null) {
        clearInterval(refState.current.audioVideoInterval);
      }
    };
    // eslint-disable-next-line
  }, []);

  const setRefState = (obj) => {
    refState.current = { ...refState.current, ...obj };
  };
  useEffect(() => {
    if (story.storyStatus.isReady && !story.showLayer) {
      setShowPopup(false);
      clearInterval(refState.current.storyInterval);
      updateStory();
    }
    // eslint-disable-next-line
  }, [story.storyStatus.isReady, pathname]);

  const checkVideoAndImageStatus = (storyId) => {
    refState.current.audioVideoInterval = setInterval(() => {
      (async () => {
        const checkAudio = refState.current.trackAudio;
        const checkVideo = refState.current.trackVideo;
        if (checkAudio || checkVideo) {
          const { innerStory } = await new StoryService().getStoryStatus({
            _id: storyId,
          });
          const audioStatus = innerStory?.storyAudio?.status;
          const videoStatus = innerStory?.storyVideo?.status;
          if (checkAudio && audioStatus === "CREATED") {
            setAudioStory({
              show: false,
              audioUrl: innerStory?.storyAudio?.audioUrl,
              status: "CREATED",
            });
            const textDurations = createTextDuration(innerStory);
            setTextStoryAndDurations(textDurations);
            setRefState({ trackAudio: false });
          } else if (checkAudio && audioStatus === "ERRORED") {
            setAudioStory({ show: false, audioUrl: "", status: "ERRORED" });
            setRefState({ trackAudio: false });
          }
          if (checkVideo && videoStatus === "CREATED") {
            setVideoStory((prev) => ({
              ...prev,
              videoUrl: innerStory?.storyVideo?.videoUrl,
              status: "CREATED",
            }));
            setRefState({ trackVideo: false });
          } else if (checkVideo && videoStatus === "ERRORED") {
            setVideoStory({ show: false, audioUrl: "", status: "ERRORED" });
            setRefState({ trackVideo: false });
          }
        } else {
          clearInterval(refState.current.audioVideoInterval);
          setRefState({ audioVideoInterval: null });
        }
      })();
    }, 10000);
  };

  useEffect(() => {
    fetchStory();
    // eslint-disable-next-line
  }, [search, pathname]);

  useEffect(() => {
    (async () => {
      try {
        if (!story?._id) return;
        setTotalRatings(story?.totalRatings || 0);
        if (user.isLoggedIn) {
          const response = await new StoryService().getUserRating({
            _id: story?._id,
            userId: user?.userId,
          });
          setRate(response?.rating || 0);
        }
      } catch (error) {}
    })();

    // eslint-disable-next-line
  }, [story?._id, story?.totalRatings]);

  const checkStoryStatusRecursive = (storyId) => {
    return new Promise((resolve, reject) => {
      refState.current.storyInterval = setInterval(() => {
        (async () => {
          try {
            const response = await new StoryService().getStoryStatus({
              _id: storyId,
            });
            if (response?.status === "CREATED") {
              clearInterval(refState.current.storyInterval);
              resolve(response?.story);
            } else if (response?.status === "ERRORED") {
              clearInterval(refState.current.storyInterval);
              reject("Error");
            }
          } catch (error) {
            clearInterval(refState.current.storyInterval);
            reject(error);
          }
        })();
      }, 10000);
    });
  };

  const updateStoryPrivacy = async () => {
    try {
      const response = await new StoryService().updateStoryPrivacy({
        userId: user.userId,
        _id: story._id,
        isPrivate: !isStoryPrivate,
      });
      if (response) {
        dispatch({
          type: "UPDATE_STORY",
          payload: { isPrivate: !isStoryPrivate },
        });
        setIsStoryPrivate((pre) => !pre);
      }
    } catch (error) {}
  };

  useEffect(() => {
    const { fetchData, ...selectedConfigs } = story?.storyConfig;
    if (fetchData) {
      setShowPopup(true);
      dispatch({
        type: "UPDATE_STORY_STATUS",
        payload: { storyStatus: { ...story.storyStatus, showLoader: true } },
      });
      (async () => {
        try {
          const response = await checkStoryStatusRecursive(
            selectedConfigs?.storyId
          );
          if (!response || !response?.storyParagraphs) throw new Error("");
          const paragraphs = Utils.organizeParagraph(
            response?.storyParagraphs,
            response?.images
          );
          setAudioStory((prev) => ({ ...prev, status: "LOADING" }));
          setVideoStory((prev) => ({ ...prev, status: "LOADING" }));
          setGeneratedStory({ paragraphs, ...response });
          dispatch({
            type: "UPDATE_GENERATED_STORY",
            payload: { paragraphs, ...response },
          });
          setIsStoryPrivate(response.isPrivate);
          dispatch({
            type: "UPDATE_STORY_STATUS",
            payload: {
              storyStatus: { showLoader: true, isReady: true },
              storyConfig: { ...story.storyConfig, fetchData: false },
            },
          });
          checkVideoAndImageStatus(selectedConfigs?.storyId);
        } catch (error) {
          console.log("error: ", error);
          Utils.failureToast(
            "Unable to write your tale, Too many people are accessing our service, please try in a few minutes. In the mean time, here is another story to read.",
            {
              className: "p-5.25 font-PoppinsMedium md:max-w-lg",
            }
          );
          dispatch({
            type: "UPDATE_STORY_STATUS",
            payload: {
              storyStatus: { showLoader: false, isReady: false },
              showLayer: false,
            },
          });
        } finally {
          setShowPopup(false);
        }
      })();
    }
    // eslint-disable-next-line
  }, [story.storyConfig.fetchData]);

  const fetchStory = async () => {
    if (story?.storyConfig.fetchData) return;
    setIsLoading(true);
    const urlTitle = match?.params?.storyTitle.split("-");
    const id = urlTitle[urlTitle.length - 1];
    const title = urlTitle.slice(0, -1).join(" ");
    const storyTitle =
      title !== "makemytale story" ? title.replace(/_/g, "-") : "";
    const query = new URLSearchParams({
      id,
      title: storyTitle,
      userId: user?.userId,
    }).toString();
    try {
      const [response] = await new StoryService().getStoryByTitle(query);
      if (!response) {
        history.push("/");
        return;
      }
      const paragraphs = Utils.organizeParagraph(
        response.storyParagraphs,
        response.images
      );
      dispatch({ type: "UPDATE_STORY", payload: { paragraphs, ...response } });
      setIsStoryPrivate(response.isPrivate);
      if (response?.storyAudio?.status === "CREATED") {
        setAudioStory({
          show: false,
          audioUrl: response?.storyAudio?.audioUrl,
          status: "CREATED",
        });
        const textDurations = createTextDuration(response);
        setTextStoryAndDurations(textDurations);
      }
      if (response?.storyVideo?.status === "CREATED") {
        setVideoStory((prev) => ({
          ...prev,
          videoUrl: response?.storyVideo?.videoUrl,
          status: "CREATED",
        }));
      }
    } catch (error) {
      Utils.failureToast(error?.message, {
        className: "alert-toast font-PoppinsMedium",
      });
      const currentUrl = window.location.pathname;
      sessionManager.setDataInCookies("REDIRECT_LINK", currentUrl);
      const token = sessionManager.getDataFromCookies(AUTH0_ID_TOKEN);

      history.push(!token ? "/login" : "/");
      console.log("error: ", error);
    } finally {
      setIsLoading(false);
    }
  };

  const storyReport = async () => {
    try {
      await new StoryService().sendStoryReport(
        story?._id,
        user.userId,
        user?.name,
        messageValue
      );
      Utils.succesToast("Report submitted successfully", {
        className: "alert-toast font-PoppinsMedium",
      });

      setShowReportDailog(false);
    } catch (error) {
      console.log("report error: ", error);
      Utils.failureToast("Report is already submitted", {
        className: "alert-toast font-PoppinsMedium",
      });
      setShowReportDailog(false);
    }
  };

  const deleteStory = async () => {
    try {
      await new StoryService().deleteStory({ storyId: story._id });
      Utils.succesToast("Story deleted successfully", {
        className: "alert-toast font-PoppinsMedium",
      });
      setTimeout(() => {
        history.push("/created-stories");
      }, 2000);
    } catch (error) {
      console.log("error: ", error);
      Utils.failureToast(
        "An errored occurred while Deleting story, Please try again.",
        { className: "alert-toast font-PoppinsMedium" }
      );
    }
  };

  const updateStory = () => {
    Utils.navigateToStory(generatedStory?._id, generatedStory?.storyTitle);
    setGeneratedStory({});
    dispatch({
      type: "UPDATE_STORY_STATUS",
      payload: { storyStatus: { showLoader: false, isReady: false } },
    });
  };

  const onRating = async (rating) => {
    if (!user.isLoggedIn) {
      sessionManager.setDataInCookies("REDIRECT_LINK", pathname);
      history.push("/login");
      return;
    }
    if (rate) return;
    setRate(rating);
    setTotalRatings((prev) => prev + 1);

    try {
      await new StoryService().rateStory({
        _id: story?._id,
        rating,
        userId: user.userId,
      });
    } catch (error) {
      console.log("error: ", error);
    }
  };

  const handleTimeUpdate = (currentTime) => {
    const activeText = textStoryAndDurations?.textDurations?.find(
      ({ startTime, endTime }) => {
        return currentTime >= startTime && currentTime <= endTime;
      }
    );
    if (activeText && activeText?.id !== highlighTextId) {
      setactiveSegmentIdId(activeText?.id);
    }
  };

  const startTimer = () => {
    clearInterval(intervalRef.current);
    intervalRef.current = setInterval(() => {
      if (!audioRef.current.ended) {
        const duration = audioRef.current?.duration;
        handleTimeUpdate(audioRef.current.currentTime);
        setTrackProgress(
          duration
            ? `${(audioRef.current.currentTime / duration) * 100}%`
            : "0%"
        );
        setAudioTime(Utils.secondsToms(audioRef.current.currentTime));
      } else setIsPlaying(false);
    }, [500]);
  };

  const onPlay = () => {
    if (isPlaying || audioStory.status !== "CREATED") return;
    setVideoStory((p) => ({ ...p, show: false }));
    setAudioStory((p) => ({ ...p, show: true }));
    audioRef.current = new Audio(
      audioStory.audioUrl || story?.storyAudio?.audioUrl
    );
    startTimer();
    setIsPlaying(true);
  };

  const onAudioClose = () => {
    if (intervalRef.current !== null) clearInterval(intervalRef.current);
    setAudioStory((p) => ({ ...p, show: false }));
    setIsPlaying(false);
    setTrackProgress("0%");
    setAudioTime("0:00");
    audioRef.current.pause();
  };

  const onPlayPauseClick = (value) => {
    setIsPlaying(value);
  };

  const skip = (direction) => {
    if (direction === "forward") {
      audioRef.current.currentTime = audioRef.current.currentTime + 10;
    }
    if (direction === "back") {
      audioRef.current.currentTime = audioRef.current.currentTime - 10;
    }
  };

  const onVideoPlay = () => {
    if (audioStory.show) onAudioClose();
    setVideoStory((p) => ({ ...p, show: true }));
  };

  return (
    <div className="bg-black-10 min-h-screen">
      <Header setLanguage={setLanguage} showCreateNew top={"top-0"} />
      {!isLoading && (
        <>
          <MainSection
            setShowReportDailog={setShowReportDailog}
            setShowDeleteDailog={setShowDeleteDailog}
            story={story}
            isPrivate={isStoryPrivate}
            updateStoryPrivacy={updateStoryPrivacy}
            user={user}
            isPlaying={isPlaying}
            audioStory={audioStory}
            videoStory={videoStory}
            setVideoStory={setVideoStory}
            totalRatings={totalRatings}
            onPlay={onPlay}
            audioTime={audioTime}
            onRating={onRating}
            data={story?.paragraphs || []}
            onVideoPlay={onVideoPlay}
            textStory={textStoryAndDurations.textStory}
            highlighTextId={highlighTextId}
            currLang={currLang}
          />
          <RateReport
            rate={rate}
            onChange={onRating}
            setShowReportDailog={setShowReportDailog}
          />
          <Footer />
        </>
      )}
      {audioStory.show && (
        <AudioPlayer
          isPlaying={isPlaying}
          story={story}
          onClose={onAudioClose}
          onPlayPauseClick={onPlayPauseClick}
          skip={skip}
          trackProgress={trackProgress}
        />
      )}
      {showReportDailog && (
        <ReportDialog
          onClose={setShowReportDailog}
          storyReport={storyReport}
          setMessageValue={setMessageValue}
          setShowReportDailog={setShowReportDailog}
        />
      )}
      {showDeleteDailog && (
        <DeleteStoryPopup
          deleteStory={deleteStory}
          onClose={setShowDeleteDailog}
          setShowDeleteDailog={setShowDeleteDailog}
        />
      )}
      {!isLoading && showPopup && (
        <LoadingPopup
          story={story}
          setshowLayer={dispatch}
          onClose={setShowPopup}
        />
      )}
      {isLoading && <LoadingScreen />}
    </div>
  );
};

export default Home;
