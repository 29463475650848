import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { Document, Page, pdfjs } from "react-pdf";
import { BookService } from "../../../services";
import LoadingScreen from "../../../common/components/loadingScreen";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

const PreviewBook = () => {
  const [pdf, setPdf] = useState("");
  const [numPages, setNumPages] = useState(20);
  const [isLoading, setIsLoading] = useState(true);
  const { pathname } = useLocation();

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const bookId = pathname.split("/")[3];
        const response = await new BookService().getBook(bookId);
        setPdf(response?.bookPdf);
      } catch (error) {}
    })();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {isLoading && <LoadingScreen />}
      <div className={`flex justify-center ${isLoading && "hidden"}`}>
        <Document
          className="mx-auto"
          file={{ url: pdf }}
          onLoadSuccess={({ numPages: loadedNumPages }) => {
            setNumPages(loadedNumPages);
            setIsLoading(false);
          }}
        >
          {[...Array(numPages).keys()].map((page) => (
            <Page key={page} pageNumber={page + 1} />
          ))}
        </Document>
      </div>
    </>
  );
};

export default PreviewBook;
