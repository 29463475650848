import React, { useEffect } from "react";
import { privacyPolicyList } from "../../constants";

function PrivacyPolicy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="flex flex-col  bg-black-0 pt-15 md:pb-10 mx-auto md:px-6 lg:px-4 pb-5">
        <h1 className="sm:text-ft42-51  text-ft22-33  items-center text-center font-PoppinsBold mb-6 text-red-10">
          Privacy and policy
         
        </h1>
        <h3 className=" md:text-ft18-27 text-ft14-21 lg:px-2 md:px-2 px-8 max-w-310 mx-auto font-PoppinsSemiBold  mb-6 text-white">
          At <span className="text-red-10">makemytale.com</span>, we understand the importance of protecting the
          privacy of our users, particularly children. We are committed to
          providing a safe and secure online experience for all of our users,
          and this Privacy Policy explains how we collect, use, and protect the
          personal information of our users.
        </h3>
      
        <div className="max-w-310 lg:px-2 md:px-2 px-6  mx-auto ">
          {privacyPolicyList.map((item) => (
            <div>
              <div className="text-white  md:text-ft30-32 text-ft25-37 mt-4 font-TimesBold">
                {item.heading}
              </div>
              <div className="text-white md:text-ft30-37 text-ft22-33  font-TimesSemiBold ">
                {item.subheading}
              </div>
              <div className="text-white md:text-ft26-50 text-ft20-37 font-TimesRegular italic">
                {item.content} 
                {item.heading === "Contact Us" ? <a className="text-blue-20 hover:underline ml-1" href="mailto:support@makemytale.com">support@makemytale.com</a> : ""}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default PrivacyPolicy;
