import React from "react";
import Footer from "../../common/components/footer";
import Header from "../../common/components/header";
import TermsAndService from "./termsAndService";

function TermsServices() {
  return (
    <div className="bg-black-0">
      <Header />
      <TermsAndService />
      <Footer />
    </div>
  );
}

export default TermsServices;
