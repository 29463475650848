import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { BiLoaderAlt } from "react-icons/bi";
import { errorMessagesConstants } from "../../constants";
import { Auth0Service } from "../../services";

const { EMAIL_REQUIRED, INVALID_EMAIL } = errorMessagesConstants;

function ForgotPassword() {
  const [isLoading, setIsloading] = useState(false);
  const [forgetPasswordError, setForgetPasswordError] = useState("");
  const [forgetSuccesSent, setForgetSuccesSent] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    setIsloading(true);
    try {
      await new Auth0Service().forgotPassword(data);
      setForgetSuccesSent(true);
      reset({ email: "" });
    } catch (error) {
      console.log(error);
      setForgetPasswordError(
        "An error occurred while sending the recovery mail, Please try again."
      );
    } finally {
      setIsloading(false);
    }
  };

  let emailErrorMessage = null;
  if (!!errors.email) {
    emailErrorMessage = (
      <div className="font-PoppinsRegular text-red-20 ml-4 md:text-ft15-21 text-ft11-18 mt-1">
        {errors.email.message}
      </div>
    );
  }

  let forgetPasswordErrorMessage = null;
  if (!!forgetPasswordError.length > 0) {
    forgetPasswordErrorMessage = (
      <div className="font-PoppinsRegular text-red-20 ml-4 md:text-ft15-21 text-ft11-18 mt-1">
        {forgetPasswordError}
      </div>
    );
  }

  let buttonContent = isLoading ? (
    <BiLoaderAlt className="w-8 h-8 animate-spin" />
  ) : (
    "Reset Password"
  );

  return (
    <>
      <div className="flex flex-col   bg-black-0 pt-15  mx-auto">
        <h1 className="sm:text-ft46-50 text-ft30-37 mb-9  items-center text-center font-TimesBold  text-white">
          Forgot Password?
        </h1>
        {forgetSuccesSent ? (
          <div className="md:w-519px  w-90per  bg-white mx-auto rounded-xl">
            <div className="mx-auto">
              <img
                className="mx-auto mt-14"
                src="/images/forget-check.svg"
                alt=""
              />
            </div>
            <div className="font-PoppinsMedium  mobile:items-center mobile:ml-3 iphoneXR:ml-7 iphone:ml-4 justify-center md:justify-center md:items-center md:ml-8 text-black-10  md:text-ft20-37 text-ft14-21 mt-9 mb-10">
              We’ll send instructions to the email provided{" "}
              <span className="mobile:flex mobile:items-center flex items-center justify-center">
                if it’s associated with an account.
              </span>
            </div>
            <p className=" text-ft4 font-OpenSansRegular flex justify-center items-center  pb-10">
              <Link to="/login" className="font-PoppinsMedium text-gray-10 ">
                <span className="font-PoppinsMedium text-red-10 ml-1">
                  Back to Sign In
                </span>
              </Link>
            </p>
          </div>
        ) : (
          <div className="md:w-519px  pt-8 w-90per  bg-white mx-auto rounded-xl md:px-13.5 px-3 ">
            <div className="font-PoppinsMedium mobile:px-0 flex flex-col justify-center text-ft20-37 md:px-12 px-2 text-black-10">
              Enter the email which you used
              <span className="flex justify-center mobile:items-center mobile:justify-center iphoneXR:flex iphoneXR:pr-5 iphoneXR:justify-center">
                to login to your account
              </span>
            </div>
            <div className="md:w-387px flex justify-between mx-auto  h-12.5 border border-gray-50 rounded-10 mt-7.75">
              <input
                type="email"
                {...register("email", {
                  required: EMAIL_REQUIRED,
                  pattern: {
                    value:
                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: INVALID_EMAIL,
                  },
                })}
                onChange={() => setForgetSuccesSent("")}
                placeholder="Email Address"
                className=" text-ft4 bg-transparent rounded-10 py-3 remove-prefill-autofill px-5 md:px-5 placeholder-gray-60 text-black-10 font-PoppinsMedium focus:outline-none w-full"
              ></input>
            </div>
            {emailErrorMessage}
            {forgetPasswordErrorMessage}

            <button
              onClick={handleSubmit(onSubmit)}
              className="h-13 md:w-387px mx-auto w-3/4 rounded-10 mt-27px mb-65px font-PoppinsMedium bg-red-10 hover:bg-red-30 text-white mt-7.75S flex justify-center items-center"
            >
              {buttonContent}
            </button>
          </div>
        )}
      </div>
    </>
  );
}

export default ForgotPassword;
