import React, { useState } from "react";
import { BiLoaderAlt } from "react-icons/bi";

function NewPassword() {
  const [isLoading] = useState(false);
  return (
    <>
      <div className="flex flex-col  bg-black-0 pt-15 pb-60 h-screen mx-auto">
        <h1 className="sm:text-ft46-50 text-ft30-37  items-center text-center font-TimesBold mb-8.5 text-white">
          Set new password
        </h1>

        <div className="md:w-519px h-92.5 pt-11 w-90per  bg-white mx-auto rounded-xl md:px-13.5 px-3 ">
          <div className="font-PoppinsMedium text-ft20-37 ml-3 text-black-0">
            Enter a new password for your account
          </div>

          <div className="flex flex-col w-auto mx-auto ml-3.5 ">
            <div className="md:w-387px flex justify-between  h-12.5 border border-gray-50 rounded-10 mt-9.5">
              <input
                type="text"
                placeholder="New Password"
                className=" text-ft4 bg-transparent rounded-10 py-3 md:px-8 px-3 placeholder-gray-60 text-black-0 font-PoppinsMedium focus:outline-none w-full"
              ></input>
            </div>
            <div className="md:w-387px flex justify-between  h-12.5 border border-gray-50 rounded-10 mt-7.5">
              <input
                type="text"
                placeholder="Confirm Password"
                className=" text-ft4 bg-transparent rounded-10 py-3 md:px-8 px-3 placeholder-gray-60 text-black-0 font-PoppinsMedium focus:outline-none w-full"
              ></input>
            </div>
          </div>

          <button className="  h-13 md:w-387px mx-auto w-3/4 rounded-10 mt-7.5 font-PoppinsMedium bg-red-10 hover:bg-red-30 text-white mt-7.75S flex justify-center  items-center">
            {isLoading ? (
              <BiLoaderAlt className="w-8 h-8 animate-spin" />
            ) : (
              "Change Password"
            )}
          </button>
        </div>
      </div>
    </>
  );
}

export default NewPassword;
