import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import LoadingScreen from "../../../common/components/loadingScreen";
import BookCard from "../../../common/components/bookCard";

const MyBooks = ({ state }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (state.booksIsLoading) return <LoadingScreen />;

  const hasBooks = !!state?.myBooks?.length || !!state?.storyBook?.status;
  const isStoryBookLoading = state?.storyBook?.status === "LOADING";

  let bookLoader = null;
  if (isStoryBookLoading) {
    bookLoader = (
      <BookLoader
        title={state?.storyBook?.data?.bookTitle}
        author={state?.storyBook?.data?.authorName}
      />
    );
  }

  return (
    <div className=" max-w-310 mx-auto px-4 md:px-6 lg:px-4 my-11">
      <div className="flex justify-between items-center">
        <div className="text-ft32-50  lg:ml-0 text-white font-TimesBold">
          My Books
        </div>
        <Link
          to="/story-book/create-book"
          className="flex md:min-w-45 mobile:h-7 md:h-10 bg-red-20 hover:bg-red-30 md:rounded-5.5 rounded-full text-white items-center justify-center md:gap-2 font-PoppinsMedium text-ft15-21"
        >
          <img
            className="md:w-4  md:h-4 h-6 w-6 mobile:h-3 mobile:w-5"
            src="/images/pen-icon.svg"
            alt=""
          />
          <span className="md:text-ft16-22 text-ft11-18 md:pr-1 mobile:pr-2">
            Create New
          </span>
        </Link>
      </div>
      {hasBooks ? (
        <div className="mt-10 grid xl:grid-cols-2  items-center justify-center md:mr-0 mobile:mr-8 iphoneXR:mr-12  iphone:mr-10 lg:grid-cols-2 md:grid-cols-2 gap-y-14 gap-x-1">
          {bookLoader}
          {state?.myBooks?.map((data, idx) => (
            <BookCard key={idx} data={data} />
          ))}
        </div>
      ) : (
        <div className="h-60vh grid place-content-center text-darkGray-20 font-PoppinsSemiBold text-ft30-37">
          No book created
        </div>
      )}
    </div>
  );
};

export default MyBooks;


const BookLoader = ({ title, author }) => {
  return (
    <div className="w-74 h-107.3 rounded-l-lg">
      <div className="h-25per bg-green-20 flex flex-col justify-center items-center">
        <div className="text-ft20-37 text-white font-TimesBold mb-11px text-center px-3">
          {title}
        </div>
        <div className="text-ft9-14 text-white font-TimesBold mb-11px">{`by ${author}`}</div>
      </div>
      <div className="h-75per bg-white flex flex-col justify-center  items-center">
        <img
          className="mx-auto mb-5 animate-spin"
          src="/images/loader-red.png"
          alt=""
        />
        <div className="text-red-10 font-PoppinsSemiBold ft14-21">
          Creating your book
        </div>
      </div>
    </div>
  );
};
