import React from "react";
import Footer from "../../common/components/footer";
import Header from "../../common/components/header";
import FeedbackComponent from "./feedback";

function Feedback() {
  return (
    <div className="bg-black-0">
      <Header />
      <FeedbackComponent />
      <Footer />
    </div>
  );
}

export default Feedback;
