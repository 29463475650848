import React, { useState, useEffect } from "react";
import { BiLoaderAlt } from "react-icons/bi";
import Stars from "react-stars";

function LoadingPopup({ story, setshowLayer, onClose }) {
  const [value, setValue] = useState(0);
  const [valueForMobile, setValueForMobile] = useState(0);
  const [percent, setPercent] = useState(0.2);

  const onClickStory = () => {
    setshowLayer({
      type: "UPDATE_STORY_STATUS",
      payload: {
        showLayer: true,
        storyConfig: { ...story.storyConfig, fetchData: false },
      },
    });
    onClose();
  };
  useEffect(() => {
    const id = setInterval(() => {
      if (percent >= 0.8) {
        clearInterval(id);
      } else {
        setPercent((pre) => pre + 0.2);
      }
    }, 3000);
    return () => {
      clearInterval(id);
      setshowLayer({
        type: "UPDATE_STORY_STATUS",
        payload: {
          showLayer: true,
          storyConfig: { ...story.storyConfig, fetchData: false },
        },
      });
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (percent <= 0.8) {
      setValue(percent * 620);
    }
  }, [percent]);

  useEffect(() => {
    if (percent <= 0.8) {
      setValueForMobile(percent * 200);
    }
  }, [percent]);

  const storyType = story?.type || "";
  const storyCharacter = story?.character || "";
  const storyAge = !!story?.age ? `${story?.age} +` : "";
  const storyTitle = story?.storyTitle || "";
  const currentRating = Number(story?.currentRating?.$numberDecimal);

  return (
    <>
      <div className="fixed inset-0 bg-black-0  min-h-screen   mx-auto text-white items-center justify-center flex">
        <div className="px-3  items-center  text-center">
          <h2 className="font-PoppinsSemiBold text-ft26-40 md:text-ft34-50 mb-5">
            It will take some time to create your story
          </h2>
          <p className="text-gray-140 items-center text-ft11-18 md:text-ft20-37 md:mb-0 mb-10 font-PoppinsMedium">
            Meanwhile you can enjoy a similar story from our library
          </p>
          <div className="flex flex-col justify-center items-center gap-5 sm:pt-15">
            <div className="bg-red-30 rounded w-52 h-1 md:w-155 md:mr-5 mr:3 md:mb-15">
              <div
                className="bg-white h-1 rounded duration-500 ease-in mobile:hidden lg:block "
                style={{ width: `${value}px` }}
              />
              <div
                className="bg-white h-1 rounded duration-500 ease-in lg:hidden mobile:block "
                style={{ width: `${valueForMobile}px` }}
              />
            </div>
            <div className="w-full justify-center items-center">
              {story && story?.images ? (
                <div
                  className="h-36 w-80 md:h-65 justify-center  md:w-155 md:ml-8 ml-6 iphone:ml-6 mobile:ml-4  bg-gray-10 gap-2 sm:gap-6 flex flex-row cursor-pointer "
                  onClick={onClickStory}
                >
                  <img
                    alt=""
                    className="h-full w-1/3 md:w-auto"
                    src={story?.images[0]?.url}
                  />
                  <div className="w-2/3 pl-6 sm:pl-5 flex flex-col pt-5">
                    <div className="text-gray-60 text-ft12-18 sm:text-ft14-21 text-left font-PoppinsMedium col-start-1 col-span-2">
                      {`${storyType} · ${storyCharacter} · ${storyAge}`}
                    </div>
                    <div className="text-white text-ft16-24 mt-2 text-left sm:text-ft20-37 font-PoppinsBold col-start-1 col-span-2">
                      {storyTitle}
                    </div>
                    <div className="flex flex-row w-full text-ft12-18 sm:text-ft14-21 mt-2 items-center font-PoppinsMedium col-start-1 col-span-2">
                      <span className="cursor-pointer">
                        <Stars
                          count={5}
                          value={currentRating}
                          edit={false}
                          size={16}
                          half={true}
                          color1={"#B2B2B2"}
                          color2={"#F8C413"}
                        />
                      </span>
                    </div>
                  </div>
                </div>
              ) : (
                <BiLoaderAlt className="h-6.5 w-6.5 animate-spin"></BiLoaderAlt>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LoadingPopup;
