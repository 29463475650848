import React, { useEffect } from "react";
import StoryCard from "../../common/components/storyCard";

const StoriesList = ({ title, storiesList, user }) => {
  useEffect(() => {
    window.scrollTo(0,0)
  }, []);
  return (
    <div className="cursor-pointer max-w-310 mx-auto px-4 md:px-6 lg:px-4 my-11">
      <div className="text-ft42-51 md:ml-10 lg:ml-0 text-white font-TimesBold capitalize">{title}</div>
      <div className="grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 md:gap-y-14 gap-10 mt-6 gap-x-1">
        {storiesList?.map((story) => (
          <StoryCard user={user} key={story?._id} story={story} showAuthorInfo />
        ))}
      </div>
    </div>
  );
};

export default StoriesList;
