import { httpService } from "../utility/httpService";
import { httpConstants, keyTypeConstants } from "../constants";
import { sessionManager } from "../managers/sessionManager";
import store from "../store";
import Auth0Service from "./auth0Service";
import Utils from "../utility";

const { AUTH0_ID_TOKEN } = keyTypeConstants;

const {
  METHOD_TYPE: { POST, GET, PUT },
  API_END_POINT: {
    SAVE_USER,
    READ_STORY,
    ADD_READ_STORY,
    GET_USER,
    UPLOAD_PICTURE,
    UPDATE_PROFILE,
    GET_PROFILE,
    CHANGE_PASSWORD,
    ADD_FEEDBACK,
  },
} = httpConstants;

export default class UserService {
  baseUrl = process.env.REACT_APP_USER_SERVICE;
  localUrl = "http://localhost:3003";

  saveUser = async (requestData) => {
    return new Promise((resolve, reject) => {
      httpService(POST, this.baseUrl + SAVE_USER, requestData)
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };

  getUserData(object) {
    const query = new URLSearchParams(object).toString();
    return new Promise((resolve, reject) => {
      httpService(GET, this.baseUrl + GET_USER + "?" + query)
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  }

  getReadedStories(object) {
    const query = new URLSearchParams(object).toString();
    return new Promise((resolve, reject) => {
      httpService(GET, this.baseUrl + READ_STORY + "?" + query)
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  }

  addReadStory(requestData) {
    return new Promise((resolve, reject) => {
      httpService(POST, this.baseUrl + ADD_READ_STORY, requestData)
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  }

  updatePicture(imageData) {
    return new Promise((resolve, reject) => {
      fetch(this.baseUrl + UPLOAD_PICTURE, {
        method: POST,
        body: imageData,
        headers: {
          Authorization: `Bearer ${sessionManager.getDataFromCookies(
            AUTH0_ID_TOKEN
          )}`,
        },
      })
        .then(async (response) => {
          try {
            const data = await response.json();
            if (!data.success) {
              if (Number(data.responseCode) === 401) {
                store.dispatch({ type: "REMOVE_USER" });
                new Auth0Service().logOut();
                return reject();
              }
              const error =
                data.responseCode === 500 ? data : data?.message || response.statusText;
              return reject(error);
            }
            return resolve(data.responseData);
          } catch (err) {
            reject(err);
          }
        })
        .catch(reject);
    });
  }
  

  updateProfile(requestData) {
    return new Promise((resolve, reject) => {
      httpService(PUT, this.baseUrl + UPDATE_PROFILE, requestData, Utils.getHeader())
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  }

  getProfile(object) {
    const query = new URLSearchParams(object).toString();
    return new Promise((resolve, reject) => {
      httpService(GET, this.baseUrl + GET_PROFILE + "?" + query)
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  }

  changePassword(request, token) {
    return new Promise((resolve, reject) => {
      const headers = Utils.getHeader();
      if (token) headers.Authorization = `Bearer ${token}`;
      httpService(PUT, this.baseUrl + CHANGE_PASSWORD, request, headers)
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  }
  addFeedback(requestData) {
    return new Promise((resolve, reject) => {
      httpService(POST, this.baseUrl + ADD_FEEDBACK, requestData)
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  }
}
