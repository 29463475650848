import React from "react";
import ReactStars from "react-stars";

const RateThisTale = ({ onChange, rate }) => {
  return (
    <div id="rate-tale" className="flex md:gap-6 gap-3 md:items-center">
      <div className="text-white font-PoppinsMedium md:text-ft20-37 grid place-items-center text-ft16-22">
        Rate this tale
      </div>
      <div className="justify-center items-center mb-1.5">
        <ReactStars
          count={5}
          value={Number(rate)}
          edit={true}
          onChange={onChange}
          size={32}
          half={false}
          color1={"#6C6C6C"}
          color2={"#F8C413"}
        />
      </div>
    </div>
  );
};

export default RateThisTale;
