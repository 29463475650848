import React, { useState } from "react";
import { MdOutlineFileDownload, MdOutlineIosShare } from "react-icons/md";
import { BiLoaderAlt, BiLink } from "react-icons/bi";
import Utils from "../../utility";
import ClickOutside from "./clickOutside";
import {
  FacebookShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookIcon,
  TelegramIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";

const formatTime = (dateTime) => {
  const text = dateTime && Utils.epocToPrettyTime(dateTime);
  const isLessThan24Hours = Date.now() - dateTime < 24 * 60 * 60 * 1000;
  return `Created ${isLessThan24Hours ? "" : " on"} ${text}`;
};

const BookCard = ({ data }) => {
  const [isDownloading, setDownloading] = useState(false);
  const [shareDropdown, setShareDropdown] = useState(false);

  const onDownload = async () => {
    setDownloading(true);
    try {
      await Utils.downloadResource(data.bookPdf, `MakeMyTale-StoryBook.pdf`);
    } catch (error) {
      console.error(error);
    } finally {
      setDownloading(false);
    }
  };
  const copyUrl = (url) => {
    Utils.copyToClipboard(url);
    setShareDropdown((p) => !p);
    Utils.succesToast("Copied to clipboard");
  };
  const title = "MakeMyTale-StoryBook";
  return (
    <div className="flex flex-col justify-center sm:flex-row md:ml-0 mobile:ml-8 iphone:ml-10 iphoneXR:ml-13 gap-8">
      <div className="relative max-w-70">
        <div className="inline-block">
          <img src={`${data?.bookImage}`} alt="/" width={296} height={391} />
        </div>

        <div className="absolute top-2 right-2 z-10 inline-block">
          <div className="cursor-pointer">
            <MdOutlineIosShare
              onClick={(event) => {
                setShareDropdown((pre) => !pre);
              }}
              className="text-white w-5 h-5 my-auto"
            />
            {shareDropdown && (
              <div className="absolute">
                <ClickOutside
                  onClickOutside={setShareDropdown}
                  className="absolute z-10 bg-white flex flex-col mobile:-left-30 left-0 right-0 gap-3 mx-auto max-h-80 overflow-y-auto overflow-x-hidden w-40 text-black-0 pl-3 pr-4 py-5 font-PoppinsSemiBold text-ft14-21 rounded"
                >
                  <FacebookShareButton
                    url={data.bookPdf}
                    quote={title}
                    className="Demo__some-network__share-button flex flex-row gap-2"
                  >
                    <FacebookIcon size={32} round />{" "}
                    <div className="my-auto">Facebook</div>
                  </FacebookShareButton>
                  <TwitterShareButton
                    url={data.bookPdf}
                    title={title}
                    className="Demo__some-network__share-button flex flex-row gap-2"
                  >
                    <TwitterIcon size={32} round />{" "}
                    <div className="my-auto">Twitter</div>
                  </TwitterShareButton>
                  <WhatsappShareButton
                    url={data.bookPdf}
                    title={title}
                    separator=":: "
                    className="Demo__some-network__share-button flex flex-row gap-2"
                  >
                    <WhatsappIcon size={32} round />{" "}
                    <div className="my-auto">WhatsApp</div>
                  </WhatsappShareButton>
                  <TelegramShareButton
                    url={data.bookPdf}
                    title={title}
                    className="Demo__some-network__share-button flex flex-row gap-2"
                  >
                    <TelegramIcon size={32} round />{" "}
                    <div className="my-auto">Telegram</div>
                  </TelegramShareButton>
                  <div
                    className="Demo__some-network__share-button flex flex-row gap-2"
                    onClick={() => copyUrl(data.bookPdf)}
                  >
                    <BiLink className="w-8 h-5 my-auto" />
                    <div className="text-black my-auto"> Copy Link</div>
                  </div>
                </ClickOutside>
              </div>
            )}
          </div>
        </div>
      </div>

      <div>
        <div className="text-ft14-21 text-gray-10 font-PoppinsRegular md:mt-0 mb-6px">
          {formatTime(data?.addedOn)}
        </div>
        <div className="text-ft20-37 text-white font-TimesBold mb-11px max-w-57.5 truncate-line-2">
          {data?.bookTitle}
        </div>
        <div className="flex flex-col gap-13px">
          <button
            onClick={onDownload}
            className="cursor-pointer border text-red-10 border-red-10 rounded-full w-28.5 min-w-40 h-30px flex items-center justify-center gap-1 font-PoppinsMedium text-ft14-21"
          >
            <BiLoaderAlt
              className={`${!isDownloading && "hidden"} w-5 h-5 animate-spin`}
            />
            <>
              <MdOutlineFileDownload
                className={`${isDownloading && "hidden"} w-5 h-5`}
              />
              Download
            </>
          </button>
          <a
            href={data.bookPdf}
            target="_blank"
            rel="noopener noreferrer"
            className="cursor-pointer border text-red-10 border-red-10 rounded-full w-28.5 min-w-40 h-28px flex items-center justify-center gap-1 font-PoppinsMedium text-ft14-21"
          >
            View story
          </a>
        </div>
      </div>
    </div>
  );
};

export default BookCard;
