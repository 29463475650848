import React from "react";
import Pagination from "react-js-pagination";

const BookPagination = ({ page, limit, total, onPageChange }) => {
  return (
    <div className="flex justify-end mx-auto max-w-310 px-5 mb-6">
      <Pagination
        activePage={page}
        innerClass="flex text-white gap-8 items-center text-gray-10"
        itemClass="font-PoppinsSemiBold text-ft18-27"
        activeClass="text-white"
        itemClassLast="text-ft24-35 text-white"
        itemClassFirst="text-ft24-35 text-white"
        itemClassPrev="text-white"
        itemClassNext="text-white"
        itemsCountPerPage={limit}
        totalItemsCount={Number(total)}
        pageRangeDisplayed={3}
        onChange={onPageChange}
      />
    </div>
  );
};

export default BookPagination;