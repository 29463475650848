import React, { useState, useEffect, useRef } from "react";
import { BiLoaderAlt } from "react-icons/bi";
import { UserService } from "../../services";
import { useForm } from "react-hook-form";
import { errorMessagesConstants } from "../../constants";
import Utils from "../../utility";

const { EMAIL_REQUIRED, INVALID_EMAIL, NAME_IS_REQUIRED, FEEDBACK_REQUIRED } =
  errorMessagesConstants;

function FeedbackComponent() {
  const [isLoading, setIsloading] = useState(false);
  const [state, setState] = useState({ name: "", email: "", feedback: "" });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const isToast = useRef(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prev) => ({ ...prev, [name]: value }));
  };
  const onSubmit = async () => {
    setIsloading(true);
    try {
      await new UserService().addFeedback(state);
      Utils.succesToast("Feedback added successfully");
      setState({ name: "", email: "", feedback: "" });
    } catch (error) {
      if (!isToast.current) {
        isToast.current = true;
        setTimeout(() => {
          isToast.current = false;
        }, 1500);
        Utils.failureToast("Failed to add feedback", {
          duration: 1500,
        });
      }
    } finally {
      setIsloading(false);
    }
  };
  return (
    <>
      <div className="flex flex-col  bg-black-0 pt-15 md:pb-10 mx-auto pb-5">
        <h1 className="sm:text-ft30-32 text-ft24-35  items-center text-center font-TimesBold mb-11px text-white">
          Let us know your feedback!
        </h1>
        <h3 className="sm:text-ft16-22 text-ft11-18 lg:px-2 md:px-2 pl-10 pr-12  max-w-310 mx-auto items-center font-PoppinsSemiBold  mb-37px text-white">
          Please take a moment to share your thoughts and help us serve you
          better.
        </h3>
        <div className="mx-auto md:w-440px w-90per' justify-center items-center">
          <div className="md:w-440px w-auto h-13 ">
            <input
              type="text"
              value={state.name}
              {...register("name", {
                required: NAME_IS_REQUIRED,
              })}
              onChange={(e) => handleChange(e)}
              placeholder="Your name*"
              name="name"
              className=" text-ft4 bg-transparent border border-gray-50 rounded-10 remove-prefill-autofill change-prefill-text-color py-3 px-5 md:px-5 placeholder-gray-60 text-white font-PoppinsMedium focus:outline-none focus:ring-1 focus:border-white w-full"
            ></input>
          </div>
          {errors.name && state.name.trim() === '' && (
            <div className="font-PoppinsRegular text-red-20 ml-4 md:text-ft15-21 text-ft11-18 mt-1">
              {errors.name.message}
            </div>
          )}
          <div className="md:w-440px w-auto h-13 mt-25px">
            <input
              value={state.email}
              type="email"
              {...register("email", {
                required: EMAIL_REQUIRED,
                pattern: {
                  value:
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: INVALID_EMAIL,
                },
              })}
              onChange={(e) => handleChange(e)}
              placeholder="Your email address*"
              name="email"
              className=" text-ft4 bg-transparent border border-gray-50 remove-prefill-autofill change-prefill-text-color rounded-10 py-3 px-5 md:px-5 placeholder-gray-60 text-white font-PoppinsMedium focus:outline-none focus:ring-1 focus:border-white  w-full"
            ></input>
          </div>
          {!!errors.email && state.email.trim() === '' && (
            <div className="font-PoppinsRegular text-red-20 ml-4 md:text-ft15-21 text-ft11-18 mt-1">
              {errors.email.message}
            </div>
          )}
          <div className="md:w-440px w-auto h-44 mt-27px">
            <textarea
              type="text"
              value={state.feedback}
              {...register("feedback", {
                required: FEEDBACK_REQUIRED,
              })}
              onChange={(e) => handleChange(e)}
              placeholder="Your feedback*"
              name="feedback"
              rows="6"
              className=" text-ft4  border border-gray-50 resize-none bg-transparent break-words h-auto rounded-5 py-3 px-5 md:px-5 break-all overflow-y-auto  placeholder-gray-60 text-white font-PoppinsMedium focus:outline-none focus:ring-1 focus:border-white  flex w-full  flex-wrap "
            ></textarea>
          </div>
          {!!errors.feedback && state.feedback.trim() === '' && (
            <div className="font-PoppinsRegular text-red-20 ml-4 md:text-ft15-21 text-ft11-18 mt-1">
              {errors.feedback.message}
            </div>
          )}
          <button
            onClick={handleSubmit(onSubmit)}
            // disabled={
            //   state.name && state.email && state.feedback ? false : true
            // }
            className={`cursor-pointer md:h-11 md:w-440px mx-auto w-60.5 h-12.5 rounded-10 font-PoppinsMedium bg-red-10 text-white mt-11 flex justify-center  items-center`}
          >
            {isLoading ? (
              <BiLoaderAlt className="w-8 h-8 animate-spin" />
            ) : (
              "Submit Feedback"
            )}
          </button>
        </div>
      </div>
    </>
  );
}

export default FeedbackComponent;
