import React, { useState, useEffect ,useRef } from "react";
import Reactstars from "react-stars";
import Utils from "../../utility";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { StoryService } from "../../services";
import BookMarkActive from "../../assets/images/BookmarkActive.svg"
import BookMarkInctive from "../../assets/images/BookmarkInactive.svg"
import { LazyLoadImage } from "react-lazy-load-image-component";

const description = (value) => {
  let text = "";
  if (value?.storyTitle) text = `${value?.storyTitle}`;
  else if (value?.startsWith) text = value?.startsWith.replace(/"/g, "");
  return text;
};

const StoryCard = ({ user, story, showAuthorInfo = false }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [bookmarkStory, setBookmarkStory] = useState(
    story?.isBookMarked || false
  );
  const slideRef = useRef(null);

  const exactImage = () => story?.images?.[0]?.url || story?.images?.[1]?.url;

  useEffect(() => {
    const onResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
  }, []);

  const BookmarkStories = async (e) => {
    e.stopPropagation();
    setBookmarkStory((prev) => !prev);
    try {
      await new StoryService().bookmarkStories({
        userId: user?.userId,
        storyId: story?._id,
      });
    } catch (error) {
      console.log("error: ", error);
    }
  };

  const handleHover = (hover) => {
    if (!isMobile && slideRef.current) {
      if (hover) {
        slideRef.current.slickPlay();
      } else {
        slideRef.current.slickPause();
      }
    }
  };

  return (
    <div
      className="md:w-72.5 w-full md:ml-9 ipad:ml-12 lg:ml-0"
      onClick={() => Utils.navigateToStory(story?._id, story?.storyTitle)}
    >
      <div
        onMouseEnter={() => handleHover(true)}
        onMouseLeave={() => handleHover(false)}
        className="md:w-72.5 h-72.5 w-full relative"
      >
        <div className="absolute top-0 left-0 w-full h-full bg-linear-black-50-black-180 z-20 opacity-0 hover:opacity-100 duration-200">
          <div className=" flex justify-end items-start mt-1 mr-2">
            {user?.isLoggedIn && (
              <button onClick={BookmarkStories}>
                {!bookmarkStory ? (
                <img alt="bookmark" src={BookMarkInctive}/>
                ) : (
                  <img alt="bookmark" src={BookMarkActive}/>
                )}
              </button>
            )}
          </div>
          {showAuthorInfo && story?.userRes?.userId && (
            <div className="absolute bottom-3 left-2 flex items-center gap-1.5">
              <div className="w-7 h-7">
                <img
                  className="w-full h-full object-cover rounded-full"
                  src={story?.userRes?.profilePicture}
                  alt={story?.userRes?.name ? story.userRes.name : ""}
                />
              </div>
              <div className="font-PoppinsMedium text-ft14-21 text-white">
                {story?.userRes?.name}
              </div>
            </div>
          )}
        </div>
        <div>
          <div>
            {isMobile ? (
              <img
                className="object-cover md:w-72.5 h-72.5 w-full"
                src={exactImage()}
                alt=""
              />
            ) : (
              <Slider
                dots={true}
                infinite={true}
                speed={150}
                ref={slideRef}
                slidesToShow={1}
                slidesToScroll={1}
                autoplay={false}
                autoplaySpeed={700}
                arrows={false}
              >
                {story?.images?.map((image, index) => (
                  <LazyLoadImage
                    key={index}
                    className="object-cover w-full h-full"
                    src={image?.url}
                    alt=""
                  />
                ))}
              </Slider>
            )}
          </div>
        </div>
      </div>
      <div className="text-gray-10 text-ft14-21 font-PoppinsMedium mt-3">
        {`${story?.type || ""} · ${story?.character || ""} · ${
          story?.age || ""
        }`}
      </div>
      <div className="text-white font-PoppinsSemiBold mt-1 md:w-72.5 truncate-line-2">
        {description(story)}
      </div>
      {!!story?.totalRatings && (
        <div>
          <Reactstars
            count={5}
            value={Number(story?.currentRating?.$numberDecimal)}
            edit={false}
            size={20}
            half={true}
            color1={"#6C6C6C"}
            color2={"#F8C413"}
          />
        </div>
      )}
    </div>
  );
};

export default StoryCard;
