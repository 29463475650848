import swal from "sweetalert";
import moment from "moment";
import toast from "react-hot-toast";
import { history } from "../managers/history";
import { apiConstant, httpConstants, keyTypeConstants } from "../constants";
import { sessionManager } from "../managers/sessionManager";
import {
  SuccessIcon as successIcon,
  FailureIcon as failureIcon,
} from "../common/icons";

const { AUTH0_ID_TOKEN } = keyTypeConstants;

export const dispatchAction = (type, data) => {
  return (dispatch) => dispatch({ type, data });
};

function getHeader() {
  return {
    "Content-Type": httpConstants.CONTENT_TYPE.APPLICATION_JSON,
    Authorization: `Bearer ${sessionManager.getDataFromCookies(
      AUTH0_ID_TOKEN
    )}`,
  };
}

function isNumber(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}

function succesToast(msg, options = {}) {
  toast(msg || "", {
    icon: successIcon(),
    className: "font-PoppinsRegular text-ft16-22 rounded-4.5",
    ...options,
  });
}

function failureToast(msg, options = {}) {
  toast(msg || apiConstant.API_FAILURE, {
    icon: failureIcon(),
    className: "font-PoppinsRegular text-ft16-22 rounded-4.5",
    ...options,
  });
}

function generateGUID() {
  const nav = window.navigator;
  const screen = window.screen;
  let guid = nav.mimeTypes.length;
  guid += nav.userAgent.replace(/\D+/g, "");
  guid += nav.plugins.length;
  guid += screen.height || "";
  guid += screen.width || "";
  guid += screen.pixelDepth || "";
  return guid;
}

function basicAlert(message) {
  swal({
    title: message,
    icon: "/images/alert-icon.png",
  });
}

function validationAlert(message, type = "info") {
  swal({
    title: message,
    icon: type,
  });
}

function getTimeDifference(timeStampTo) {
  let minFive = 300000;
  let oneDay = 86400000;
  let difference = "";
  let am = " AM";
  let pm = " PM";
  let hh = epochToDate(timeStampTo, "hh");
  let mm = epochToDate(timeStampTo, "mm");
  let dateFormat = epochToDate(timeStampTo, "DD MMM YYYY");
  let hours = new Date(timeStampTo).getHours();
  let timeDifference = new Date().getTime() - timeStampTo;
  if (timeDifference < oneDay) {
    if (timeDifference < minFive) {
      difference = "Just Now";
    } else {
      if (hours < 12) difference = "Today at " + hh + ":" + mm + am;
      else difference = "Today at " + hh + ":" + mm + pm;
    }
  } else {
    if (hours < 12) difference = dateFormat + ", " + hh + ":" + mm + am;
    else difference = dateFormat + ", " + hh + ":" + mm + pm;
  }
  return difference;
}

function epochToDate(timeStamp, timeFormat) {
  timeStamp = Math.floor(timeStamp); //to convert to integer if seconds is String.
  let dateObject = new Date(timeStamp);
  return moment(dateObject).format(timeFormat); //DD MMM YYYY
}

function getTimeFromNow(timeStamp) {
  return moment(timeStamp, "YYYYMMDD").fromNow();
}

function dateCompare(timeStampFrom, timeStampTo) {
  let diffTime = timeStampFrom * 1000 - timeStampTo;
  return diffTime / (1000 * 60 * 60 * 24);
}

function navigateToPath(path) {
  history.push(path);
}

function validateName(name) {
  let reg = /[A-Z][a-zA-Z]*/;
  return reg.test(name);
}

function validateEmail(email) {
  let reg =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return reg.test(email);
}

function isPasswordValid(password) {
  let reg = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
  return reg.test(password);
}

function isEmpty(string) {
  return !string || string.trim().length === 0;
}

function isMenuActive(path) {
  return window.location.pathname.includes(path);
}

function showUnderDevelopment() {
  basicAlert("Under Development");
}

function epocToPrettyTime(seconds) {
  seconds = Math.floor(seconds);
  const nowTimeMilliseconds = new Date().getTime();
  const date = new Date(seconds);
  const dateObject = moment(date).format("DD MMM YYYY");
  seconds = Math.floor(nowTimeMilliseconds / 1000 - seconds / 1000);
  let interval = Math.floor(seconds / 172800);
  if (interval >= 1) return dateObject;
  interval = Math.floor(seconds / 86400);
  if (interval >= 1) return "yesterday";

  interval = Math.floor(seconds / 3600);
  if (interval >= 1) {
    if (interval === 1) return interval + " hr ago";
    return interval + " hrs ago";
  }
  interval = Math.floor(seconds / 60);
  if (interval >= 1) {
    if (interval === 1) return interval + " min ago";
    return interval + " min ago";
  } else return "Just now";
}

function epocToPrettyTimeForFuture(seconds) {
  seconds = Math.floor(seconds);
  var nowTimeMilliseconds = new Date().getTime();

  seconds = Math.floor(seconds / 1000 - nowTimeMilliseconds / 1000);
  var interval = Math.floor(seconds / 86400);
  if (interval >= 1) return interval + " days";

  interval = Math.floor(seconds / 3600);
  if (interval >= 1) {
    if (Number(interval) === 1) return interval + " hr";
    return interval + " hrs";
  }
  interval = Math.floor(seconds / 60);
  if (interval >= 1) {
    if (Number(interval) === 1) return interval + " minute";
    return interval + " minutes";
  } else return "0 minute";
}

function exactImage(data) {
  if (Array.isArray(data)) {
    return {
      generate: false,
      url: data[0],
    };
  } else {
    return {
      generate: true,
      url: data.url || "",
      id: data?.id ?? "",
    };
  }
}

function organizeParagraph(paragraphs, images) {
  let curtImage = 0;
  const newParagraphs = [];
  let text = "";
  for (let i = 0; i < paragraphs.length; i++) {
    const para = paragraphs[i];
    if (paragraphs.length === i + 1) {
      if (para.startsWith("[Image")) {
        newParagraphs.push({ text: "", image: exactImage(images[curtImage]) });
      } else {
        newParagraphs.push({
          text: text + " " + para,
          image: { generate: false, url: "" },
        });
      }
    } else if (para.startsWith("[Image")) {
      newParagraphs.push({ text, image: exactImage(images[curtImage]) });
      curtImage += 1;
      text = "";
    } else {
      text += para + " ";
    }
  }
  const lastParaIdx = newParagraphs.length - 1;
  const lastPara = newParagraphs[lastParaIdx];
  if (!lastPara.image.url) {
    const value = newParagraphs[lastParaIdx - 1];
    newParagraphs[lastParaIdx - 1] = {
      ...value,
      text: value.text + " " + lastPara.text,
    };
    newParagraphs.pop();
  }
  return newParagraphs;
}

function storeToLocalStorage(key, data) {
  localStorage.setItem(key, JSON.stringify(data));
}

function getDataFromLocalStorage(key) {
  const data = localStorage.getItem(key);
  return data ? JSON.parse(data) : null;
}

function updateLocalStorageArray(key, value) {
  const data = localStorage.getItem(key);
  const prevValue = data ? JSON.parse(data) : [];
  localStorage.setItem(key, JSON.stringify([...prevValue, value]));
}

function secondsToms(d) {
  d = Number(d);
  let m = Math.floor((d % 3600) / 60);
  let s = Math.floor((d % 3600) % 60);
  const secs = s < 10 ? "0" + s : s;
  return m + ":" + secs;
}

function addDays(date, days) {
  return new Date(date.getTime() + days * 24 * 60 * 60 * 1000);
}

function formatCurrency(amount, currency = "INR") {
  return new Intl.NumberFormat("en-US", { style: "currency", currency }).format(
    amount
  );
}

function getLastString(str, len) {
  return str.substr(str.length - len);
}

function formatDashCase(str) {
  return str
    .toLowerCase()
    .replace(/-/g, "_")
    .replace(/\s+/g, "-")
    .replace(/^-+|-+$/g, "")
    .replace(/--+/g, "-");
}

function navigateToStory(storyId, storyTitle) {
  const shortId = getLastString(storyId, 6);
  const title = !!storyTitle ? formatDashCase(storyTitle) : "makemytale-story";
  history.push(`/story/${title}-${shortId}`);
}

/**
 * Pick a random element from an array.
 * @param array - The array to pick a random element from.
 * @returns a random element from the array.
 */
function pickRandom(array) {
  return array[Math.floor(Math.random() * array.length)];
}

async function downloadResource(url, filename) {
  try {
    const blob = await fetch(url, {
      headers: new Headers({ Origin: window.location.origin }),
      mode: "cors",
    }).then((response) => response.blob());
    const anchor = document.createElement("a");
    anchor.style.display = "none";
    anchor.href = window.URL.createObjectURL(blob);
    anchor.setAttribute("download", filename);
    document.body.appendChild(anchor);
    anchor.click();
    window.URL.revokeObjectURL(anchor.href);
    document.body.removeChild(anchor);
  } catch (error) {
    console.error(error);
  }
}

const createImage = (url) => {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", (error) => reject(error));
    image.setAttribute("crossOrigin", "anonymous");
    image.src = url;
  });
};

const dataURLtoFile = async (dataUrl, fileName) => {
  var arr = dataUrl.split(",");
  let mime = arr[0].match(/:(.*?);/)[1];
  let bstr = atob(arr[1]);
  let n = bstr.length;

  let u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], fileName, { type: mime });
};

async function getCroppedImg(
  imageSrc,
  pixelCrop,
  fileName = JSON.stringify(new Date().getTime()) + ".png"
) {
  const image = await createImage(imageSrc);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  const safeArea = Math.max(image.width, image.height);
  canvas.width = safeArea;
  canvas.height = safeArea;
  ctx.translate(safeArea, safeArea);
  ctx.translate(-safeArea, -safeArea);
  ctx.fillStyle = "rgb(200,0,0)";
  ctx.drawImage(image, safeArea - image.width, safeArea - image.height);
  const data = ctx.getImageData(0, 0, safeArea, safeArea);
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;
  ctx.putImageData(
    data,
    0 - safeArea + image.width - pixelCrop.x,
    0 - safeArea + image.height - pixelCrop.y
  );
  // As Base64 string
  const dataUrl = canvas.toDataURL("image/png");

  return dataURLtoFile(dataUrl, fileName);
}
function copyToClipboard(text) {
  navigator.clipboard
    .writeText(text)
    .then(() => console.log(`Copied to clipboard: ${text}`));
}

export default {
  getHeader,
  failureToast,
  succesToast,
  generateGUID,
  basicAlert,
  validationAlert,
  isNumber,
  navigateToPath,
  validateName,
  validateEmail,
  isEmpty,
  isMenuActive,
  isPasswordValid,
  showUnderDevelopment,
  epochToDate,
  dateCompare,
  getTimeFromNow,
  epocToPrettyTime,
  getTimeDifference,
  epocToPrettyTimeForFuture,
  organizeParagraph,
  storeToLocalStorage,
  getDataFromLocalStorage,
  updateLocalStorageArray,
  secondsToms,
  addDays,
  formatCurrency,
  getLastString,
  navigateToStory,
  pickRandom,
  downloadResource,
  getCroppedImg,
  copyToClipboard,
};
