import React, { useRef } from "react";
import StoryCard from "./storyCard";

const StoriesList = ({ state, onStorySelect, storiesList }) => {
  const selectedStories = state.selectedStories;
  const containerRef = useRef(null);

  const handleLeftClick = () => {
    containerRef.current.scrollBy({
      left: -300,
      behavior: "smooth",
    });
  };

  const handleRightClick = () => {
    containerRef.current.scrollBy({
      left: 300,
      behavior: "smooth",
    });
  };

  return (
    <div className="relative cursor-pointer md:max-w-310 md:mt-10 mt-6 my-14">
      {!!storiesList.length ? (
        <div
          ref={containerRef}
          className="create-book flex gap-y-14 mt-4 gap-x-8 overflow-auto"
        >
          <div
            onClick={handleLeftClick}
            className="text-white text-ft36-20 font-bold absolute top-32 md:-left-9 xl:-left-10 z-10 md:flex hidden"
          >
            <img src="/images/arrow-left.svg" alt="/" />
          </div>
          <div
            onClick={handleRightClick}
            className="text-white text-ft36-20 font-bold absolute top-32 md:-right-9 xl:-right-10 z-10 md:flex hidden"
          >
            <img src="/images/arrow-right.svg" alt="/" />
          </div>
          {storiesList?.map((story) => (
            <StoryCard
              selected={selectedStories.includes(story._id)}
              selectStories={() => onStorySelect(story._id)}
              key={story?._id}
              story={{ ...story, _id: story?._id }}
            />
          ))}
        </div>
      ) : (
        <div className="h-60vh grid place-content-center text-darkGray-20 font-PoppinsSemiBold text-ft30-37">
          No Stories created
        </div>
      )}
    </div>
  );
};

export default StoriesList;
