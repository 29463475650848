import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import Footer from "../../common/components/footer";
import Header from "../../common/components/header";
import StoriesList from "./storiesList";
import LoadingScreen from "../../common/components/loadingScreen";
import ProfileComponent from "./profileComponent";
import Cropper from "./cropper";
import { UserService, StoryService } from "../../services";

function Profile({ match }) {
  const [loading, setLoading] = useState(false);
  const [userProfile, setUserProfile] = useState({});
  const [storiesList, setStoriesList] = useState([]);
  const [selectedPicture, setSelectedPicture] = useState({});
  const [showCropper, setShowCropper] = useState(false);
  const [loadingUpload, setLoadingUpload] = useState(false);

  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const history = useHistory();

  const [userName, userID] = match?.params?.userId.split("-");
  const [storyCount, setStoryCount] = useState(0);
  const [myStoryView, setMyStoryView] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    document.body.style.overflow = showCropper ? "hidden" : "unset";
  }, [showCropper]);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match?.params?.userId]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const profileData = await new UserService().getProfile({
        firstName: userName.toLowerCase() || "",
        _id: userID,
      });
      setUserProfile(profileData);
      await getCreatedStories(profileData?.userId);
      if (user.isLoggedIn && profileData?.userId === user?.userId) {
        await fetchMyStoryViewCount();
      }
    } catch (error) {
      console.log("error: ", error);
      history.push("/");
    } finally {
      setLoading(false);
    }
  };

  const getCreatedStories = async (id) => {
    try {
      const response = await new StoryService().getCreatedStories({
        userId: id,
        limit: 100,
      });
      setStoriesList(response?.data);
      setStoryCount(response?.total);
    } catch (error) {
      console.log("error: ", error);
    }
  };

  const fetchMyStoryViewCount = async () => {
    try {
      const responseData = await new StoryService().getStoryViewCount({ userId: user.userId });
      setMyStoryView(responseData || 0);
    } catch (error) {
      console.error('Error fetching story view count:', error);
    }
  };

  const onPictureChange = (image) => {
    setShowCropper(true);
    setSelectedPicture(image);
  };

  const onApply = async (image) => {
    setLoadingUpload(true);
    try {
      const form = new FormData();
      form.append("image", image);
      const response = await new UserService().updatePicture(form);
      dispatch({
        type: "UPDATE_USER",
        payload: { profilePicture: response?.profilePicture },
      });
      setUserProfile((prev) => ({
        ...prev,
        profilePicture: response?.profilePicture,
      }));
      setShowCropper(false);
      setSelectedPicture({});
    } catch (error) {
      console.log("error: ", error);
    } finally {
      setLoadingUpload(false);
    }
  };

  const updateUserIntro = async (userIntro) => {
    try {
      await new UserService().updateProfile({
        userId: user?.userId,
        userIntro,
      });
      dispatch({ type: "UPDATE_USER", payload: { userIntro } });
      setUserProfile((prev) => ({ ...prev, userIntro }));
    } catch (error) {
      console.log("error: ", error);
    }
  };

  return (
    <div className="min-h-screen bg-black-10">
      <Header />
      {loading ? (
        <LoadingScreen />
      ) : (
        <>
          <ProfileComponent
            profile={userProfile}
            user={user}
            onPictureChange={onPictureChange}
            updateUserIntro={updateUserIntro}
            myStoryView={myStoryView}
            storyCount={storyCount}
          />
          <StoriesList
            profile={userProfile}
            storiesList={storiesList}
            myStoryView={myStoryView}
            storyCount={storyCount}
            showStats={user.isLoggedIn && userProfile?.userId === user?.userId}
          />
        </>
      )}
      <Footer />
      {showCropper && (
        <div className="fixed mx-auto z-50 max-w-310 w-full flex justify-center">
          <Cropper
            onClose={() => {
              setShowCropper(false);
              setSelectedPicture({});
            }}
            isLoading={loadingUpload}
            selectedPicture={selectedPicture}
            onApply={onApply}
          />
        </div>
      )}
    </div>
  );
}

export default Profile;
