import React, { useState, useEffect } from "react";
import StoryCard from "../../common/components/storyCard";
import StoryService from "../../services/storyService";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { history } from "../../managers/history";

const viewMoreLink = (data) => {
  let viewType = "latest";
  let category;

  if (data?.type) {
    viewType = data.type;
    category = "type";
  } else if (data?.character) {
    viewType = data.character;
    category = "character";
  }

  history.push(`/browse/${viewType}?category=${category || ''}&page=1`);
};

const StoriesList = ({ title, type, character, list, user }) => {
  const [stories, setStories] = useState({ totalCount: "", list: [] });

  useEffect(() => {
    (async () => {
      try {
        const query = {};
        if (type) query.type = type;
        if (character) query.character = character;
        if (user?.isLoggedIn) query.userId = user.userId;
        const { data = [], total } = await new StoryService().getStories(query);
        setStories({ list: data, totalCount: total });
      } catch (error) {
        console.log("error: ", error);
      }
    })();
  }, [type, character, user]);

  return (
    <div className="cursor-pointer max-w-310 mx-auto px-4 md:px-6 lg:px-4 my-14">
      <div className="flex justify-between">
        <div className="md:text-ft30-32 text-ft24-35 md:ml-10 lg:ml-0 text-white font-TimesBold">
          {type === "latest" ? `${stories.totalCount} Stories Created` : title}
        </div>
        <div className="flex whitespace-nowrap md:px-5 mb-6 ipad:mr-6.5 lg:-mr-3 mr-0">
          <div
            onClick={() => viewMoreLink(list)}
            className="text-white bg-red-10 hover:bg-red-30 rounded-5.5 pl-2 text-ft11-18 md:text-ft16-22 md:pl-4 md:pr-4 pr-2 pt-2.5 pb-2 font-PoppinsMedium flex gap-1 md:gap-2"
          >
            View All <MdOutlineKeyboardArrowRight className="md:w-6 w-4 md:h-6 h-4 md:pt-0 md:pb-1" />
          </div>
        </div>
      </div>

      <div className="grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 md:gap-y-22 gap-10 gap-x-1">
        {stories.list?.map((story) => (
          <StoryCard showAuthorInfo key={story?._id} story={story} user={user} />
        ))}
      </div>
    </div>
  );
};

export default StoriesList;
