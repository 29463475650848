import React, { useEffect } from "react";
import { aboutPageData } from "../../constants";

function AboutComponent() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="flex flex-col  bg-black-0 pt-15 md:px-6 lg:px-4 md:pb-10 mx-auto pb-5">
        <h1 className="sm:text-ft42-51 text-ft22-33  items-center text-center font-PoppinsBold mb-6 text-red-10">
          About Us
        </h1>
        <h3 className="sm:text-ft24-35 text-ft1 lg:px-2 md:px-2 px-6 max-w-310 mx-auto font-PoppinsSemiBold  mb-6 text-white">
          Welcome to{" "}
          <a
            className="text-red-10 cursor-pointer"
            target="_blank"
            href="https://makemytale.com"
            rel="noopener noreferrer"
          >
            makemytale.com
          </a>{" "}
          , a platform where creativity and innovation come together to create a
          magical world of storytelling. Our goal is to provide a safe and
          secure environment where children can express their imagination and
          creativity through the power of AI-powered story creation.
        </h3>

        <div className="max-w-310 lg:px-2 md:px-2 px-6  mx-auto ">
          {aboutPageData.map((item) => (
            <div className="text-white pb-3 text-ft20-37 md:text-ft26-50 font-TimesRegular ">
              {item.content}
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default AboutComponent;
