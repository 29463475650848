import React from "react";
import { BiLoaderAlt } from "react-icons/bi";

const BlurLoadingScreen = () => {
  return (
    <div className="fixed inset-0 grid place-content-center  bg-blackOpacity-50 h-screen z-50">
      <BiLoaderAlt className="text-white animate-spin w-14 h-14" />
    </div>
  );
};

export default BlurLoadingScreen;
