import React from "react";
import { Link } from "react-router-dom";
import StoryCard from "../../common/components/storyCard";

const StoriesList = ({ title, storiesList, user }) => {
  return (
    <div className="cursor-pointer max-w-310 mx-auto px-4 md:px-6 lg:px-4 my-11">
      <div className="flex justify-between items-start xs:items-center flex-col xs:flex-row ">
        <div className="text-ft32-50 md:ml-10 lg:ml-0 text-white font-TimesBold">
          {title}
        </div>
        {!storiesList.length && (
          <Link
            to="/"
            className="flex md:w-48 duration-150 md:h-10 w-8.5 h-8.5 bg-red-20 hover:bg-red-30 md:rounded-5.5 rounded-full text-white items-center justify-center gap-2 font-PoppinsMedium text-ft16-22"
          >
            <img
              className="md:w-auto md:h-auto h-4 w-4"
              src="/images/pen-icon.svg"
              alt=""
            />
            <span className="md:inline hidden">Create New Story</span>
          </Link>
        )}
      </div>
      {!!storiesList.length ? (
        <div className="grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 md:gap-y-14 gap-10 mt-6 gap-x-1">
          {storiesList?.map((story) => (
            <StoryCard key={story?._id} story={story} user={user} />
          ))}
        </div>
      ) : (
        <div className="h-60vh grid place-content-center text-darkGray-20 font-PoppinsRegular text-ft30-37">
          No Story Created
        </div>
      )}
    </div>
  );
};

export default StoriesList;
