import { storyBookConstants } from "../../constants";
const { ACTION_TYPES } = storyBookConstants;

const { UDPATE_STATE, SELECT_STORY } = ACTION_TYPES;

export const initialState = {
  authorName: "",
  bookTitle: "",
  selectedStories: [],
  storyBook: {
    status: "",
    data: {},
  },
  myBooks: [],
  booksIsLoading: true,
};

export default function reducer(state, action) {
  switch (action.type) {
    case UDPATE_STATE:
      return { ...state, ...action.payload };
    case SELECT_STORY:
      return {
        ...state,
        selectedStories: state.selectedStories.includes(action.payload.storyId)
          ? state.selectedStories.filter((id) => id !== action.payload.storyId)
          : state.selectedStories.length < 4
          ? [...state.selectedStories, action.payload.storyId]
          : state.selectedStories,
      };
    default:
      return state;
  }
}
