import React, { useEffect } from "react";
import { cancelSubscriptionData } from "../../constants";

function CancelSubscription() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="flex flex-col  bg-black-0 pt-15 md:pb-10 mx-auto md:px-6 lg:px-4 pb-5">
        <h1 className="sm:text-ft42-51 text-ft22-33  items-center text-center font-PoppinsBold mb-6 text-red-10">
          Cancellation Policy
        </h1>

        <div className="max-w-310 lg:px-2 md:px-2 px-6  mx-auto">
          {cancelSubscriptionData.map((item) => (
            <div className="py-10">
              <div className="text-white md:text-ft26-50 text-ft20-37 font-TimesRegular italic">
                {item.content}
                {item.heading === "cancel" ? (
                  <a
                    className="text-blue-20 hover:underline ml-1"
                    href="mailto:support@makemytale.com"
                  >
                    support@makemytale.com.
                  </a>
                ) : (
                  ""
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default CancelSubscription;
