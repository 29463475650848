import React, { useState, useEffect } from "react";

function ImageWithLoader({ src, alt, className = "" }) {
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [imageSrc, setImageSrc] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    setHasError(false);
    setImageSrc(null);
    const img = new Image();
    img.src = src;
    img.onload = () => {
      setIsLoading(false);
      setImageSrc(src);
    };
    img.onerror = () => {
      setIsLoading(false);
      setHasError(true);
    };
  }, [src]);

  return (
    <div className="h-full">
      {isLoading && (
        <div className="flex justify-center items-center h-full">
          <div className="image-loader md:w-13 md:h-13 w-8 h-8"></div>
        </div>
      )}
      {hasError && <p className="text-red-10">Error</p>}
      {imageSrc && (
        <img
          className={className}
          src={imageSrc}
          alt={alt}
          style={{ display: isLoading || hasError ? "none" : "block" }}
        />
      )}
    </div>
  );
}

export default ImageWithLoader;
