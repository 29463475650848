import React from "react";
import Footer from "../../common/components/footer";
import Header from "../../common/components/header";
import CancelSubscription from "./cancelSubscription";

function CancellationPolicy() {
  return (
    <div className="bg-black-0">
      <Header />
      <CancelSubscription />
      <Footer />
    </div>
  );
}

export default CancellationPolicy;
