import React, { useEffect } from "react";
import Header from "../../common/components/header";
import ChangePasswordComponent from "./changePassword";

function ChangePassword() {
  useEffect(() => {
    window.scrollTo(0,0)
  }, []);
  return (
    <div className="bg-black-0 h-screen ">
      <Header />
      <ChangePasswordComponent />
    </div>
  );
}

export default ChangePassword;
