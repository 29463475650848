import React from "react";
import Header from "../../../common/components/header";
import PaymentCard from "./paymentCard";
import Heading from "./heading";

const PaymentScreen = ({ plan, handleSubscribePlan }) => {
  return (
    <div className="min-h-screen pb-32">
      <Header />
      <Heading />
      <PaymentCard plan={plan} handleSubscribePlan={handleSubscribePlan} />
    </div>
  );
};

export default PaymentScreen;
