import React, { useState } from "react";
import ClickOutside from "../../common/components/clickOutside";
import { MdArrowDropDown } from "react-icons/md";

const DropDown = ({
  value,
  onChange,
  options,
  suffix = "",
  fieldWidth = "",
}) => {
  const [showOptions, setShowOptions] = useState(false);

  return (
    <span className="border-b border-black-20 md:inline-block md:w-auto w-full block relative">
      <span
        className="flex items-center w-full cursor-pointer select-none"
        onClick={() => setShowOptions((prev) => !prev)}
      >
        <div className={`flex md:px-7.5 items-center ${fieldWidth}`}>
          <div className="text-red-10 font-PoppinsSemiBold text-ft22-33 md:text-ft41-50 ">
            {`${value} ${suffix}`}
          </div>
          <MdArrowDropDown
            className={`text-red-10 md:w-12 transform duration-100 ${
              showOptions && "-rotate-180"
            } "`}
          />
        </div>
      </span>
      {showOptions && (
        <ClickOutside
          onClickOutside={setShowOptions}
          className="absolute z-10 bg-white flex flex-col left-0 right-0 mx-auto max-h-40 overflow-y-auto overflow-x-hidden  text-black-0 pl-3 pr-4 py-5 font-PoppinsSemiBold md:text-ft20-37 text-ft20-37 rounded"
        >
          {options.map(
            (item, idx) =>
              value !== item && (
                <button
                  className="block text-left"
                  onClick={() => onChange(item)}
                  key={idx}
                >
                  {`${item} ${suffix}`}
                </button>
              )
          )}
        </ClickOutside>
      )}
    </span>
  );
};

export default DropDown;
