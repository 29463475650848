import React from "react";
import Header from "../../common/components/header";
import ForgotPassword from "./forgotPassword";

function Login() {
  return (
    <div className="bg-black-0 h-screen">
      <Header />
      <ForgotPassword />
    </div>
  );
}

export default Login;
