export default [
  {
    code: "en",
    text: "English",
    country: "US",
    countryCode: "US",
  },
  {
    code: "es",
    text: "Spanish",
    country: "Spain",
    countryCode: "ES",
  },
  {
    code: "fr",
    text: "French",
    country: "France",
    countryCode: "FR",
  },
  {
    code: "ru",
    text: "Russian",
    country: "Russia",
    countryCode: "RU",
  },
  {
    code: "it",
    text: "Italian",
    country: "Italy",
    countryCode: "IT",
  },
  {
    code: "hi",
    text: "Hindi",
    country: "India",
    countryCode: "IN",
  },
  // {
  //   code: "zh-CN",
  //   text: "Chinese (Simplified)",
  //   country: "China",
  //   countryCode: "CN",
  // },
  // {
  //   code: "ja",
  //   text: "Japanese",
  //   country: "Japan",
  //   countryCode: "JP",
  // },
  {
    code: "pt",
    text: "Portuguese",
    country: "Portugal",
    countryCode: "PT",
  },
];
