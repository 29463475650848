import React from "react";

const TaleCreationLoader = ({ isReady, onClick }) => {
  return (
    <div className="h-44.5 w-351px bg-red-10 p-15px text-white flex flex-col justify-around items-center rounded-md cursor-pointer">
      {isReady ? (
        <div className="text-ft24-35 font-PoppinsSemiBold">
          Your story is ready
        </div>
      ) : (
        <div className="text-ft24-35 font-PoppinsSemiBold">
          Creating your story
        </div>
      )}

      {isReady ? (
        <button
          onClick={onClick}
          className="text-red-10 bg-white h-10 py-2 px-8 font-PoppinsMedium rounded-full "
        >
          Read Story
        </button>
      ) : (
        <>
          <div className="text-ft15-21 font-PoppinsMedium text-center">
            You can access it from your profile if you close this page
          </div>
          <div className="bg-red-30 rounded w-full">
            <div className="bg-white h-1 rounded duration-500 ease-in w-90per " />
          </div>
        </>
      )}
    </div>
  );
};

export default TaleCreationLoader;
