import React from "react";
import Header from "../../common/components/header";
import NewPassword from "./newPassword";

function SetPassword() {
  return (
    <div className="bg-black-0 h-screen ">
      <Header />
      <NewPassword />
    </div>
  );
}

export default SetPassword;
