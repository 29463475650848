import React from "react";
import Reactstars from "react-stars";
import Utils from "../../utility";

const description = (value) => {
  let text = "";
  if (value?.storyTitle) text = `${value?.storyTitle}`;
  else if (value?.startsWith) text = value?.startsWith.replace(/"/g, "");
  return text;
};

const StoryCard = ({ story }) => {
  const exactImage = () => story?.images?.[0]?.url || story?.images?.[1]?.url;
  
  return (
    <div
      className="md:w-72.5 w-full md:ml-9 ipad:ml-11.5 lg:ml-0"
      onClick={() => Utils.navigateToStory(story?._id, story?.storyTitle)}
    >
      <div className="md:w-72.5 w-full h-72.5">
        <img className="object-cover w-full h-full" src={exactImage()} alt="" />
      </div>
      <div className="text-gray-10 text-ft14-21 font-PoppinsMedium mt-3">
        {`${story?.type || ""} · ${story?.character || ""} · ${
          story?.age || ""
        }`}
      </div>
      <div className="text-white font-PoppinsSemiBold mt-1 md:w-72.5 truncate-line-2">
        {description(story)}
      </div>
      <div>
        <Reactstars
          count={5}
          value={Number(story?.currentRating?.$numberDecimal)}
          edit={false}
          size={20}
          half={true}
          color1={"#6C6C6C"}
          color2={"#F8C413"}
        />
      </div>
    </div>
  );
};

export default StoryCard;
