import React, { useState, useCallback } from "react";
import Cropper from "react-easy-crop";
import { BiLoaderAlt } from "react-icons/bi";
import getCroppedImg from "./cropImage";
import Slider from "@mui/material/Slider";

function CropImagePopup({ selectedPicture, onClose, onApply, isLoading }) {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [cropArea, setCropArea] = useState({});
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCropArea(croppedAreaPixels);
  }, []);

  const onApplyChange = async () => {
    const imageData = await getCroppedImg(selectedPicture.preview, cropArea);
    onApply(imageData);
  };

  return (
    <div className="fixed inset-0 -top-18.5 bg-blackOpacity-50 z-50 pt-169px pb-20 ">
      <div className=" h-134 md:w-429px mx-auto w-90per bg-white  rounded-xl">
        <div className="flex items-center justify-between">
          <div className="w-8"></div>
          <div className="h-14 flex justify-center items-center font-PoppinsMedium text-black-0 ">
            Crop Photo
          </div>
          <button className="w-8" onClick={onClose}>
            <img className="" src="/images/cancel.svg" alt="" />
          </button>
        </div>
        <div>
          <div className="relative h-80 w-auto">
            <Cropper
              image={selectedPicture?.preview || ""}
              crop={crop}
              zoom={zoom}
              aspect={1}
              showGrid={false}
              cropShape="round"
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />
          </div>
          <div className="flex mt-6 items-center">
            <img className="ml-6.5 mr-3" src="/images/zoom-decrease.svg" alt="" />

            <Slider
              onChange={(e) => {
                setZoom(e.target.value);
              }}
              value={zoom}
              max={3}
              min={1}
              step={0.1}
              sx={{
                width: 316,
                height: "6px",
                color: "#0B0B0B",
              }}
            />
            <img className="mr-6.5 ml-3" src="/images/zoom-increase.svg" alt="" />
          </div>
          <div className="flex justify-center mt-8">
            <button
              className="h-12.5 w-52 rounded-10 border font-PoppinsMedium bg-red-10 text-white grid place-content-center text-ft17-26"
              onClick={onApplyChange}
            >
              {isLoading ? (
                <BiLoaderAlt className="w-8 h-8 animate-spin" />
              ) : (
                " Apply Changes"
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CropImagePopup;
