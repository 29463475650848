import React from "react";
import Footer from "../../common/components/footer";
import Header from "../../common/components/header";
import AboutComponent from "./about";

function About() {
  return (
    <div className="bg-black-0">
      <Header />
      <AboutComponent />
      <Footer />
    </div>
  );
}

export default About;
