import React, { useEffect, useState } from "react";
import Footer from "../../common/components/footer";
import Header from "../../common/components/header";
import StoriesList from "./storiesList";
import LoadingScreen from "../../common/components/loadingScreen";
import StoryService from "../../services/storyService";
import { useSelector } from "react-redux";

function CreatedStories() {
  const [loading, setIsLoading] = useState(true);
  const [storiesList, setStoriesList] = useState([]);

  const user = useSelector((state) => state.user);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (user.isLoggedIn) {
      (async () => {
        try {
          setIsLoading(true);
          const response = await new StoryService().getCreatedStories({
            userId: user.userId,
            limit: 100,
          });
          setStoriesList(response?.data);
        } catch (error) {
          console.log("error: ", error);
        } finally {
          setIsLoading(false);
        }
      })();
    }
    // eslint-disable-next-line
  }, [user.isLoggedIn]);

  return (
    <div className="min-h-screen bg-black-10">
      <Header showCreateNew />
      {loading ? (
        <LoadingScreen />
      ) : (
        <StoriesList user={user} title="My Created Stories" storiesList={storiesList} />
      )}
      <Footer />
    </div>
  );
}

export default CreatedStories;
