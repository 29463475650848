import React from "react";

const StoryCard = ({ selected, selectStories, story, storyId }) => {
  const description = (value) => {
    let text = "";
    if (value?.storyTitle) text = `${value?.storyTitle}`;
    else if (value?.startsWith) text = value?.startsWith.replace(/"/g, "");
    return text;
  };

  const exactImage = () => {
    if (Array.isArray(story?.images?.[0])) {
      return story?.images?.[0] || story?.images?.[1] || story?.images?.[2];
    }
    return story?.images?.[0]?.url || story?.images?.[1]?.url;
  };
  return (
    <div
      className="md:w-72.5 w-44 md:ml-7.5 lg:ml-0 md:pb-12 pb-8 relative"
      onClick={() => selectStories(storyId)}
    >
      <div className="md:w-72.5 w-44 md:h-72.5 relative">
        <img className="object-cover w-full h-full" src={exactImage()} alt="" />
        {selected ? (
          <div className="absolute top-2 right-2 bg-red-10 rounded-full w-7.75 h-7.75 grid place-content-center">
            <img src="/images/check-mark.svg" alt="/" />
          </div>
        ) : (
          <img
            className="absolute top-2 right-2"
            src="/images/unselected.svg"
            alt="/"
          />
        )}
      </div>
      <div className="text-gray-10 text-ft14-21 font-PoppinsMedium mt-3">
        {`${story?.type || ""} · ${story?.character || ""} · ${
          story?.age || ""
        }`}
      </div>
      <div className="text-white font-PoppinsSemiBold mt-1 md:w-72.5 truncate-line-2">
        {description(story)}
      </div>
    </div>
  );
};

export default StoryCard;
