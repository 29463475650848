
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////                                                                                                                                    /////////
/////////                                                                                                                                    /////////
/////////                                                                                                                                    /////////
/////////      WARNING: this file contains profanity. The below list of profane words is necessary for this tool to function properly.       /////////
/////////                        Do not read below this line if you do not wish to be exposed to lots of profane words                       /////////
/////////                                                                                                                                    /////////
/////////                                                                                                                                    /////////
/////////                                                                                                                                    /////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////











export default [
  "girls",
  "2g1c",
  "a$$hole",
  "a2m",
  "a54",
  "a55hole",
  "aeolus",
  "ahole",
  "alabama hot pocket",
  "alaskan pipeline",
  "anal impaler",
  "anal leakage",
  "analannie",
  "analprobe",
  "analsex",
  "anilingus",
  "apeshit",
  "areola",
  "areole",
  "arian",
  "arsehole",
  "aryan",
  "ass fuck",
  "ass hole",
  "assault",
  "assbag",
  "assbagger",
  "assbandit",
  "assbang",
  "assbanged",
  "assbanger",
  "assbangs",
  "assbite",
  "assblaster",
  "assclown",
  "asscock",
  "asscracker",
  "assface",
  "assfaces",
  "assfuck",
  "assgoblin",
  "assh0le",
  "asshat",
  "ass-hat",
  "asshead",
  "assho1e",
  "asshopper",
  "asshore",
  "ass-jabber",
  "assjacker",
  "assjockey",
  "asskiss",
  "asskisser",
  "assklown",
  "asslick",
  "asslicker",
  "asslover",
  "assman",
  "assmaster",
  "assmonkey",
  "assmucus",
  "assmunch",
  "assmuncher",
  "assnigger",
  "asspacker",
  "asspirate",
  "ass-pirate",
  "asspuppies",
  "assranger",
  "assshit",
  "assshole",
  "asssucker",
  "asswad",
  "asswhore",
  "asswipe",
  "asswipes",
  "auto erotic",
  "autoerotic",
  "axwound",
  "azazel",
  "azz",
  "babe",
  "babeland",
  "babes",
  "baby batter",
  "baby juice",
  "badfuck",
  "ball gag",
  "ball gravy",
  "ball kicking",
  "ball licking",
  "ball sack",
  "ball sucking",
  "balllicker",
  "bampot",
  "bang",
  "bang (one's) box",
  "bangbros",
  "banger",
  "banging",
  "bareback",
  "barely legal",
  "barenaked",
  "barf",
  "barface",
  "barfface",
  "bastardo",
  "bastards",
  "bastinado",
  "batty boy",
  "bawdy",
  "bazongas",
  "bazooms",
  "bbw",
  "bdsm",
  "beaner",
  "beaners",
  "beardedclam",
  "beatch",
  "beater",
  "beatyourmeat",
  "beaver",
  "beaver cleaver",
  "beaver lips",
  "beef curtain",
  "beef curtains",
  "beer",
  "beeyotch",
  "bender",
  "beotch",
  "bicurious",
  "big black",
  "big breasts",
  "big knockers",
  "big tits",
  "bigbastard",
  "bigbutt",
  "bigger",
  "bigtits",
  "bimbo",
  "bimbos",
  "bint",
  "birdlock",
  "bisexual",
  "bi-sexual",
  "bitch tit",
  "bitchass",
  "bitched",
  "bitchez",
  "bitchtits",
  "bitchy",
  "black cock",
  "blonde action",
  "blonde on blonde action",
  "bloodclaat",
  "bloody hell",
  "blow",
  "blow me",
  "blow mud",
  "blow your load",
  "blue waffle",
  "blumpkin",
  "boang",
  "bod",
  "bodily",
  "bogan",
  "bohunk",
  "boink",
  "bollick",
  "bollocks",
  "bollox",
  "bomd",
  "bondage",
  "bone",
  "boned",
  "boners",
  "bong",
  "boobies",
  "booby",
  "booger",
  "bookie",
  "boong",
  "boonga",
  "bootee",
  "bootie",
  "booty",
  "booty call",
  "booze",
  "boozer",
  "boozy",
  "bosom",
  "bosomy",
  "bowel",
  "bowels",
  "bra",
  "brassiere",
  "breast",
  "breastjob",
  "breastlover",
  "breastman",
  "breeder",
  "brotherfucker",
  "brown showers",
  "brunette action",
  "buggered",
  "buggery",
  "bukkake",
  "bull shit",
  "bullcrap",
  "bulldike",
  "bulldyke",
  "bullet vibe",
  "bullshits",
  "bullshitted",
  "bullturds",
  "bum boy",
  "bumblefuck",
  "bumclat",
  "bumfuck",
  "bummer",
  "bung",
  "bung hole",
  "bunga",
  "bunghole",
  "bunny fucker",
  "bust a load",
  "busty",
  "butchdike",
  "butchdyke",
  "butt fuck",
  "butt plug",
  "buttbang",
  "butt-bang",
  "buttcheeks",
  "buttface",
  "buttfuck",
  "butt-fuck",
  "buttfucka",
  "buttfucker",
  "butt-fucker",
  "butthead",
  "buttman",
  "buttmunch",
  "buttmuncher",
  "butt-pirate",
  "c.0.c.k",
  "c.o.c.k.",
  "c.u.n.t",
  "c-0-c-k",
  "caca",
  "cahone",
  "camel toe",
  "cameltoe",
  "camgirl",
  "camslut",
  "camwhore",
  "carpetmuncher",
  "cervix",
  "chesticle",
  "chi-chi man",
  "chick with a dick",
  "child-fucker",
  "chin",
  "chinc",
  "chincs",
  "chinky",
  "choad",
  "choade",
  "choc ice",
  "chocolate rosebuds",
  "chode",
  "chodes",
  "chota bags",
  "circlejerk",
  "cleveland steamer",
  "climax",
  "clit licker",
  "clitface",
  "clitfuck",
  "clitorus",
  "clitty",
  "clitty litter",
  "clogwog",
  "clover clamps",
  "clunge",
  "clusterfuck",
  "cocain",
  "cocaine",
  "c-o-c-k",
  "cock pocket",
  "cock snot",
  "cock sucker",
  "cockass",
  "cockbite",
  "cockblock",
  "cockburger",
  "cockeye",
  "cockfucker",
  "cockholster",
  "cockjockey",
  "cockknocker",
  "cockknoker",
  "cocklicker",
  "cocklover",
  "cocklump",
  "cockmaster",
  "cockmongler",
  "cockmongruel",
  "cockmonkey",
  "cocknose",
  "cocknugget",
  "cockshit",
  "cocksmith",
  "cocksmoke",
  "cocksmoker",
  "cocksniffer",
  "cocksucer",
  "cocksuckers",
  "cockwaffle",
  "coffin dodger",
  "coital",
  "commie",
  "condom",
  "coochie",
  "coochy",
  "coonnass",
  "coons",
  "cooter",
  "cop some wood",
  "coprolagnia",
  "coprophilia",
  "corksucker",
  "cornhole",
  "corp whore",
  "crabs",
  "crack",
  "cracker",
  "crackwhore",
  "crack-whore",
  "crappy",
  "creampie",
  "cretin",
  "crikey",
  "cripple",
  "crotte",
  "cum chugger",
  "cum dumpster",
  "cum freak",
  "cum guzzler",
  "cumbubble",
  "cumdump",
  "cumdumpster",
  "cumguzzler",
  "cumjockey",
  "cummin",
  "cumshots",
  "cumslut",
  "cumstain",
  "cumtart",
  "cunn",
  "cunnie",
  "cunntt",
  "cunny",
  "c-u-n-t",
  "cunt hair",
  "cuntass",
  "cuntbag",
  "cuntface",
  "cuntfuck",
  "cuntfucker",
  "cunthole",
  "cunthunter",
  "cuntrag",
  "cuntsicle",
  "cuntslut",
  "cunt-struck",
  "cuntsucker",
  "cut rope",
  "cybersex",
  "d0ng",
  "d0uch3",
  "d0uche",
  "d1ld0",
  "d1ldo",
  "dago",
  "dagos",
  "dammit",
  "damned",
  "damnit",
  "darkie",
  "darn",
  "date rape",
  "daterape",
  "dawgie-style",
  "deep throat",
  "deepthroat",
  "deggo",
  "dendrophilia",
  "dick head",
  "dick hole",
  "dick shy",
  "dickbag",
  "dickbeaters",
  "dickbrain",
  "dickdipper",
  "dickface",
  "dickflipper",
  "dickfuck",
  "dickfucker",
  "dickheads",
  "dickhole",
  "dickish",
  "dick-ish",
  "dickjuice",
  "dickmilk",
  "dickmonger",
  "dickripper",
  "dicks",
  "dicksipper",
  "dickslap",
  "dick-sneeze",
  "dicksucker",
  "dicksucking",
  "dicktickler",
  "dickwad",
  "dickweasel",
  "dickweed",
  "dickwhipper",
  "dickwod",
  "dickzipper",
  "diddle",
  "dike",
  "diligaf",
  "dillweed",
  "dimwit",
  "dingle",
  "dingleberries",
  "dingleberry",
  "dipship",
  "dipshit",
  "dirty",
  "dirty pillows",
  "dirty sanchez",
  "dog style",
  "doggie style",
  "doggiestyle",
  "doggie-style",
  "doggy style",
  "doggystyle",
  "doggy-style",
  "dolcett",
  "domination",
  "dominatrix",
  "dommes",
  "dong",
  "donkey punch",
  "donkeypunch",
  "doochbag",
  "doofus",
  "dookie",
  "dopey",
  "double dong",
  "double penetration",
  "doublelift",
  "douch3",
  "douche",
  "douchebag",
  "douchebags",
  "douche-fag",
  "douchewaffle",
  "douchey",
  "dp action",
  "drunk",
  "dry hump",
  "dumass",
  "dumb ass",
  "dumbass",
  "dumbasses",
  "dumbcunt",
  "dumbfuck",
  "dumbshit",
  "dummy",
  "dumshit",
  "dvda",
  "dykes",
  "eat a dick",
  "eat hair pie",
  "eat my ass",
  "eatpussy",
  "ecchi",
  "enlargement",
  "erect",
  "erection",
  "erotic",
  "erotism",
  "escort",
  "essohbee",
  "eunuch",
  "extacy",
  "extasy",
  "f.u.c.k",
  "facefucker",
  "facial",
  "fack",
  "fagbag",
  "fagfucker",
  "fagg",
  "fagged",
  "faggit",
  "faggotcock",
  "faggots",
  "fagtard",
  "faig",
  "faigt",
  "fannybandit",
  "fart",
  "fartknocker",
  "fastfuck",
  "fat",
  "fatfuck",
  "fatfucker",
  "fecal",
  "felch",
  "felcher",
  "feltch",
  "feltcher",
  "female squirting",
  "femdom",
  "fenian",
  "figging",
  "fingerbang",
  "fingering",
  "fist fuck",
  "fisted",
  "fisting",
  "fisty",
  "flamer",
  "flaps",
  "fleshflute",
  "flog the log",
  "floozy",
  "foad",
  "foah",
  "fondle",
  "foobar",
  "foot fetish",
  "footfuck",
  "footfucker",
  "footjob",
  "footlicker",
  "foreskin",
  "freakfuck",
  "freakyfucker",
  "freefuck",
  "freex",
  "frigg",
  "frigga",
  "frotting",
  "fubar",
  "fuc",
  "f-u-c-k",
  "fuck buttons",
  "fuck hole",
  "fuck off",
  "fuck puppet",
  "fuck trophy",
  "fuck yo mama",
  "fuck you",
  "fuckass",
  "fuck-ass",
  "fuckbag",
  "fuck-bitch",
  "fuckboy",
  "fuckbrain",
  "fuckbutt",
  "fuckbutter",
  "fuckedup",
  "fuckersucker",
  "fuckface",
  "fuckfreak",
  "fuckher",
  "fuckhole",
  "fuckingbitch",
  "fuckmeat",
  "fuckmehard",
  "fuckmonkey",
  "fucknugget",
  "fucknut",
  "fucknutt",
  "fuckoff",
  "fuckstick",
  "fucktard",
  "fuck-tard",
  "fucktards",
  "fucktart",
  "fucktoy",
  "fucktwat",
  "fuckup",
  "fuckwad",
  "fuckwhore",
  "fuckwitt",
  "fuckyou",
  "fudge-packer",
  "fukkers",
  "fuq",
  "futanari",
  "fvck",
  "fxck",
  "gae",
  "gai",
  "gang bang",
  "gang-bang",
  "ganja",
  "gash",
  "gassy ass",
  "gay sex",
  "gayass",
  "gaybob",
  "gaydo",
  "gayfuck",
  "gayfuckist",
  "gays",
  "gaytard",
  "gaywad",
  "gender bender",
  "genitals",
  "gey",
  "gfy",
  "ghay",
  "ghey",
  "giant cock",
  "gigolo",
  "ginger",
  "gippo",
  "girl on",
  "girl on top",
  "girls gone wild",
  "git",
  "glans",
  "goatcx",
  "god damn",
  "godamn",
  "godamnit",
  "goddam",
  "goddammit",
  "goddamnit",
  "goddamnmuthafucker",
  "godsdamn",
  "gokkun",
  "golden shower",
  "goldenshower",
  "golliwog",
  "gonad",
  "gonads",
  "gonorrehea",
  "goo girl",
  "gooch",
  "goodpoop",
  "gook",
  "gooks",
  "goregasm",
  "gotohell",
  "gringo",
  "grope",
  "group sex",
  "gspot",
  "g-spot",
  "gtfo",
  "guido",
  "guro",
  "h0m0",
  "h0mo",
  "ham flap",
  "hand job",
  "handjob",
  "hard core",
  "hard on",
  "hardcore",
  "he11",
  "headfuck",
  "hebe",
  "heeb",
  "hell",
  "hemp",
  "hentai",
  "heroin",
  "herp",
  "herpes",
  "herpy",
  "heshe",
  "he-she",
  "hitler",
  "hiv",
  "ho",
  "hobag",
  "hoe",
  "holy shit",
  "hom0",
  "homey",
  "homodumbshit",
  "homoerotic",
  "homoey",
  "honkey",
  "honky",
  "hooch",
  "hookah",
  "hooker",
  "hoor",
  "hootch",
  "hooter",
  "hooters",
  "hot carl",
  "hot chick",
  "hotpussy",
  "how to kill",
  "how to murdep",
  "how to murder",
  "huge fat",
  "hump",
  "humped",
  "humping",
  "hun",
  "hussy",
  "hymen",
  "iap",
  "iberian slap",
  "inbred",
  "incest",
  "injun",
  "intercourse",
  "j3rk0ff",
  "jack off",
  "jackass",
  "jackasses",
  "jackhole",
  "jaggi",
  "jagoff",
  "jail bait",
  "jailbait",
  "japs",
  "jelly donut",
  "jerk",
  "jerk off",
  "jerk0ff",
  "jerkass",
  "jerked",
  "jerkoff",
  "jigaboo",
  "jiggaboo",
  "jiggerboo",
  "jizzed",
  "jock",
  "juggs",
  "jungle bunny",
  "junglebunny",
  "junkie",
  "junky",
  "kafir",
  "kike",
  "kikes",
  "kill",
  "kinbaku",
  "kinkster",
  "kinky",
  "kkk",
  "klan",
  "knob",
  "knob end",
  "knobbing",
  "kooch",
  "kooches",
  "kootch",
  "kraut",
  "kunja",
  "kunt",
  "kwif",
  "kyke",
  "lameass",
  "lardass",
  "leather restraint",
  "leather straight jacket",
  "lech",
  "lemon party",
  "leper",
  "lesbian",
  "lesbians",
  "lesbo",
  "lesbos",
  "lez",
  "lezbian",
  "lezbians",
  "lezbo",
  "lezbos",
  "lezza",
  "lezzie",
  "lezzies",
  "lezzy",
  "lmao",
  "lmfao",
  "loin",
  "loins",
  "lolita",
  "looney",
  "lovemaking",
  "lube",
  "lusty",
  "mafugly",
  "make me come",
  "male squirting",
  "mams",
  "massa",
  "masterbat",
  "masterbating",
  "masturbating",
  "masturbation",
  "maxi",
  "mcfagget",
  "menage a trois",
  "menses",
  "menstruate",
  "menstruation",
  "meth",
  "m-fucking",
  "mick",
  "middle finger",
  "midget",
  "milf",
  "minge",
  "minger",
  "missionary position",
  "molest",
  "mong",
  "moo moo foo foo",
  "moolie",
  "moron",
  "mother fucker",
  "motherfucka",
  "mound of venus",
  "mr hands",
  "mtherfucker",
  "mthrfucker",
  "mthrfucking",
  "muff diver",
  "muff puff",
  "muffdiver",
  "muffdiving",
  "munging",
  "munter",
  "murder",
  "mutha",
  "muthafuckaz",
  "muther",
  "mutherfucking",
  "muthrfucking",
  "nad",
  "nads",
  "naked",
  "nambla",
  "napalm",
  "nappy",
  "nawashi",
  "nazism",
  "need the dick",
  "negro",
  "neonazi",
  "nig nog",
  "nigaboo",
  "niggle",
  "niglet",
  "nig-nog",
  "nimphomania",
  "nimrod",
  "ninny",
  "nipple",
  "nipples",
  "nonce",
  "nooky",
  "nsfw images",
  "nude",
  "nudity",
  "nut butter",
  "nut sack",
  "nutter",
  "nympho",
  "nymphomania",
  "octopussy",
  "old bag",
  "omg",
  "omorashi",
  "one cup two girls",
  "one guy one jar",
  "opiate",
  "opium",
  "oral",
  "orally",
  "organ",
  "orgasmic",
  "orgies",
  "orgy",
  "ovary",
  "ovum",
  "ovums",
  "p.u.s.s.y.",
  "paddy",
  "paedophile",
  "paki",
  "panooch",
  "pansy",
  "pantie",
  "panties",
  "panty",
  "pastie",
  "pasty",
  "pcp",
  "peckerhead",
  "pedo",
  "pedobear",
  "pedophile",
  "pedophilia",
  "pedophiliac",
  "pee",
  "peepee",
  "pegging",
  "penetrate",
  "penetration",
  "penial",
  "penile",
  "penisbanger",
  "penispuffer",
  "perversion",
  "peyote",
  "phalli",
  "phallic",
  "phone sex",
  "piece of shit",
  "pikey",
  "pillowbiter",
  "pimp",
  "pinko",
  "piss off",
  "piss pig",
  "pissed off",
  "piss-off",
  "pisspig",
  "playboy",
  "pleasure chest",
  "pms",
  "polack",
  "pole smoker",
  "polesmoker",
  "pollock",
  "ponyplay",
  "poof",
  "poon",
  "poonani",
  "poonany",
  "poontang",
  "poop chute",
  "poopchute",
  "poopuncher",
  "porch monkey",
  "porchmonkey",
  "pot",
  "potty",
  "prickteaser",
  "prig",
  "prince albert piercing",
  "prod",
  "prostitute",
  "prude",
  "psycho",
  "pthc",
  "pubes",
  "pubic",
  "pubis",
  "punani",
  "punanny",
  "punany",
  "punkass",
  "punky",
  "punta",
  "puss",
  "pussy fart",
  "pussy palace",
  "pussylicking",
  "pussypounder",
  "pust",
  "puto",
  "queaf",
  "queef",
  "queer",
  "queerbait",
  "queerhole",
  "queero",
  "queers",
  "quicky",
  "quim",
  "racy",
  "raghead",
  "raging boner",
  "rape",
  "raped",
  "raper",
  "rapey",
  "raping",
  "rapist",
  "raunch",
  "rectal",
  "rectus",
  "reefer",
  "reetard",
  "reich",
  "renob",
  "retarded",
  "reverse cowgirl",
  "revue",
  "rimjob",
  "ritard",
  "rosy palm",
  "rosy palm and her 5 sisters",
  "rtard",
  "r-tard",
  "rubbish",
  "rum",
  "rump",
  "rumprammer",
  "ruski",
  "rusty trombone",
  "s&m",
  "s.h.i.t.",
  "s0b",
  "sadism",
  "sambo",
  "sand nigger",
  "sandbar",
  "sandler",
  "sandnigger",
  "sanger",
  "santorum",
  "sausage queen",
  "scag",
  "scantily",
  "scat",
  "schizo",
  "scissoring",
  "screw",
  "screwed",
  "scrog",
  "scrot",
  "scrud",
  "scum",
  "seaman",
  "seamen",
  "seduce",
  "seks",
  "sexo",
  "sexual",
  "sexy",
  "s-h-1-t",
  "shamedame",
  "shaved beaver",
  "shaved pussy",
  "shibari",
  "shirt lifter",
  "s-h-i-t",
  "shit ass",
  "shit fucker",
  "shitass",
  "shitbag",
  "shitbagger",
  "shitblimp",
  "shitbrains",
  "shitbreath",
  "shitcanned",
  "shitcunt",
  "shiteater",
  "shitface",
  "shitfaced",
  "shitheads",
  "shithole",
  "shithouse",
  "shitspitter",
  "shitstain",
  "shitt",
  "shittier",
  "shittiest",
  "shiz",
  "shiznit",
  "shota",
  "shrimping",
  "sissy",
  "skag",
  "skeet",
  "skullfuck",
  "slag",
  "slanteye",
  "slave",
  "sleaze",
  "sleazy",
  "slope",
  "slut bucket",
  "slutbag",
  "slutdumper",
  "slutkiss",
  "smartass",
  "smartasses",
  "smeg",
  "smutty",
  "sniper",
  "snowballing",
  "snuff",
  "s-o-b",
  "sod off",
  "sodom",
  "sodomize",
  "sodomy",
  "son of a bitch",
  "son of a motherless goat",
  "son of a whore",
  "souse",
  "soused",
  "spade",
  "sperm",
  "spic",
  "spick",
  "spik",
  "spiks",
  "splooge",
  "splooge moose",
  "spooge",
  "spook",
  "spread legs",
  "steamy",
  "stfu",
  "stiffy",
  "stoned",
  "strap on",
  "strapon",
  "strappado",
  "strip",
  "strip club",
  "stroke",
  "stupid",
  "style doggy",
  "suck",
  "suckass",
  "sucked",
  "sucking",
  "sucks",
  "suicide girls",
  "sultry women",
  "sumofabiatch",
  "swastika",
  "swinger",
  "t1t",
  "taff",
  "taig",
  "tainted love",
  "taking the piss",
  "tampon",
  "tard",
  "tart",
  "taste my",
  "tawdry",
  "tea bagging",
  "teabagging",
  "teat",
  "terd",
  "teste",
  "testee",
  "testes",
  "testis",
  "threesome",
  "throating",
  "thrust",
  "thug",
  "thundercunt",
  "tied up",
  "tight white",
  "tinkle",
  "tit wank",
  "titi",
  "tities",
  "titty",
  "tittyfucker",
  "toke",
  "tongue in a",
  "toots",
  "topless",
  "towelhead",
  "tramp",
  "tranny",
  "transsexual",
  "trashy",
  "tribadism",
  "trumped",
  "tub girl",
  "tubgirl",
  "tush",
  "tushy",
  "twatlips",
  "twats",
  "twatwaffle",
  "twink",
  "twinkie",
  "two fingers",
  "two fingers with tongue",
  "two girls one cup",
  "ugly",
  "unclefucker",
  "undies",
  "undressing",
  "unwed",
  "upskirt",
  "urethra play",
  "urinal",
  "urine",
  "urophilia",
  "uterus",
  "uzi",
  "vag",
  "vajayjay",
  "va-j-j",
  "valium",
  "venus mound",
  "veqtable",
  "vibrator",
  "violet wand",
  "virgin",
  "vixen",
  "vjayjay",
  "vodka",
  "vomit",
  "vorarephilia",
  "voyeur",
  "vulgar",
  "wad",
  "wankjob",
  "wazoo",
  "wedgie",
  "weed",
  "weenie",
  "weewee",
  "weiner",
  "weirdo",
  "wench",
  "wet dream",
  "wetback",
  "wh0re",
  "wh0reface",
  "white power",
  "whitey",
  "whiz",
  "whoralicious",
  "whorealicious",
  "whorebag",
  "whored",
  "whoreface",
  "whorehopper",
  "whorehouse",
  "whores",
  "whoring",
  "wigger",
  "window licker",
  "wiseass",
  "wiseasses",
  "wog",
  "womb",
  "woody",
  "wop",
  "wrapping men",
  "wrinkled starfish",
  "wtf",
  "xrated",
  "x-rated",
  "xx",
  "xxx",
  "yaoi",
  "yeasty",
  "yellow showers",
  "yid",
  "yiffy",
  "yobbo",
  "zoophile",
  "zoophilia",
  "zubb",
];
